import React,{useState} from 'react'
import {putProxy,delProxy} from '../../api/api.js'
import { ToastContainer, toast } from 'react-toastify';
import ModalAlertAdmin from '../Modal/ModalAlertAdmin/ModalAlertAdmin'

const Proxy = (props) => {
    const {proxy,rerenderAdmin,setRerenderAdmin,t,proxyInfo} = props
    const [step,setStep] = useState(0)
    const [value,setValue] = useState('http')
    const [host,setHost] = useState('')
    const [login,setLogin] = useState('')
    const [pass,setPass] = useState('')
    const [port,setPort] = useState('')
    const [currentPort,setCurrentPort] = useState('')
    const [endPort,setEndPort] = useState('')
    const [typeSendPort, setTypeSendPort] = useState('single');
    const [visible,setVisible] = useState(false)
    const userdata = JSON.parse(localStorage.getItem("userData")) 
    const notifyError = (data) => toast.error(data);
    const notifyInf = (data) => toast.info(data);
    const handleChange = (event) => {setTypeSendPort(event.target.value)}

    const sendNewProxy = () => {
        if(host.toLowerCase().includes('http') || host.toLowerCase().includes('https')){notifyError('Нельзя использовать протокол в хосте')} 
        if(pass.length === 0){notifyError('Введите пароль прокси')} 
        if(login.length === 0){notifyError('Введите логин прокси')}
        if(port.length === 0){notifyError('Введите порт прокси')}
        if(host.length === 0){notifyError('Введите айпи прокси')}
        if(typeSendPort === 'multi' && (port.length === 0 || endPort.length === 0)){
            notifyError('Укажите диапозон портов прокси')}
        if(
            pass.length !== 0 && 
            host.length !== 0 &&
            (
                (typeSendPort === 'single' && port.length !== 0) || 
                (typeSendPort === 'multi' && port.length !== 0 && endPort.length !== 0)
            ) &&
            !host.toLowerCase().includes('http') &&
            !host.toLowerCase().includes('https') &&
            login.length !== 0){
                let typePortUse = typeSendPort === 'multi'? endPort : port
                putProxy(userdata.email,userdata.session,host,port,typePortUse,login,pass,value,setStep,notifyInf,notifyError,rerenderAdmin,setRerenderAdmin)
                setPass('')
                setHost('')
                setLogin('')
                setPort('')
                setEndPort('')
        }
        
    }

    const chooseCurrentDelete = (host,port) => {
        setVisible(host)
        setCurrentPort(port)
    }
    
    const deleteProxyServer = (host,port) => {
        delProxy(host,port,userdata.email,userdata.session,rerenderAdmin,setRerenderAdmin)
        setVisible(false)
    }


    return(
        <div className="setting_container">
        <div className='admin_profile'>
            {step === 0? 
            <div>
            <h3>{t('proxy')}</h3>
            <div>
                <p>{t('countProxy')}: {proxyInfo.totalProxy} {t('conter')}</p>
                <p>{t('activeProxy')}: {proxyInfo.usedProxy} {t('conter')}</p>
            </div>
            <p className='plus_container' onClick={() => setStep(1)}>+</p>
            <div className='proxy_table'>
                <table>
                    <thead>
                      <tr>
                          <th>{t('host')}</th>
                          <th>{t('login')}</th>
                          <th>{t('pass')}</th>
                          <th>{t('port')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {proxy.map((el,key) => 
                            <tr key={key}>
                             <td>{el.host}</td>
                             <td>{el.login !== null ? el.login : '-'}</td>
                             <td>{el.password !== null ? el.password: '-'}</td>
                             <td>{el.port}</td>
                             <td><button className='delete_proxy_button' onClick={() => chooseCurrentDelete(el.host,el.port)}>{t('delete')}</button></td>
                           </tr>)}
                    </tbody>
                </table>
                <div className="proxy_container_mobile">
                {proxy.map((el,key) => 
                        <div key={key}>
                         <div className='proxy_item_mobile'>
                             <div><p>{t('host')}:</p></div>
                             <div><p>{el.host}</p></div>
                         </div>
                         <div className='proxy_item_mobile'>
                             <div><p>{t('login')}:</p></div>
                             <div><p>{el.login !== null ? el.login : '-'}</p></div>
                         </div>
                         <div className='proxy_item_mobile'>
                             <div><p>{t('pass')}:</p></div>
                             <div><p>{el.password !== null ? el.password: '-'}</p></div>
                         </div>
                         <div className='proxy_item_mobile'>
                             <div><p>{t('port')}:</p></div>
                             <div><p>{el.port}</p></div>
                         </div>
                         <button onClick={() => chooseCurrentDelete(el.host,el.port)}>{t('delete')}</button>
                        </div>
                )}
                </div>
    
            </div>
            </div>
             : 
            <div>
                <p className='back_proxy' onClick={() => setStep(0)}>{t('cancel')}</p>
               <h3>{t('createNew')}</h3>
               <div className='proxy_new_form'>
                   <p className='proxy_new_form_title'>{t('host')}</p>
                   <input value={host} onChange={(event) => setHost(event.target.value)} type="text" placeholder='Type ip host'/>
                   <p className='example_text'>{t('example')} 185.241.52.35</p>
                   <p className='proxy_new_form_title'>{t('login')}</p>
                   <input value={login} onChange={(event) => setLogin(event.target.value)} className='input_proxy' type="text" placeholder="Type login proxy server"/>
                    <br/>
                    <p className='proxy_new_form_title'>{t('pass')}</p>
                   <input onChange={(event) => setPass(event.target.value)} value={pass} className='input_proxy' type="text" placeholder="Type password proxy server"/>
                   <br/>
                   <p className='proxy_new_form_title'>{t('typeSendProxyHover')}</p>
                    <div className='proxy_type_send_container'>
                        <p>{t('typeSendProxySingle')}
                            <input 
                              type="radio"
                              value="single"
                              checked={typeSendPort === 'single'}
                              onChange={handleChange}/></p>
                        <p>{t('typeSendProxyMulti')}
                            <input 
                            type="radio"
                            value="multi"
                            checked={typeSendPort === 'multi'}
                            onChange={handleChange}/></p>
                    </div>
                   <br/>
                   {typeSendPort === 'single'? 
                   <div>
                   <p className='proxy_new_form_title'>{t('port')}</p>
                   <input value={port} onChange={(event) => setPort(event.target.value)} className='input_proxy' type="number" placeholder="Type port proxy server"/>
                   </div>
                   :
                   <div className='multi_panel_choose_port'>
                       <div>
                       <p className='proxy_new_form_title'>{t('typeSendProxyStart')}</p>
                           <input 
                           type="number"
                           value={port}
                           placeholder="Type start port"
                           onChange={(event) => setPort(event.target.value)}
                           />
                       </div>
                       <div>
                           <p className='proxy_new_form_title'>{t('typeSendProxyEnd')}</p>
                           <input 
                           type="number"
                           value={endPort}
                           placeholder="Type end port"
                           onChange={(event) => setEndPort(event.target.value)}
                           />
                       </div>
                   </div>}
                   <button onClick={() => sendNewProxy()}>{t('createNew')}</button>
               </div>
            </div>}
        </div>
        {visible ? 
        <ModalAlertAdmin
        vision={visible}
        t={t}
        currentPort={currentPort}
        text={`${t('delProxyDesc')} ${visible}?`}
        setVision={setVisible}
        deleteProxyServer={deleteProxyServer}
        /> : null}
        <ToastContainer/>
    </div> 
    )
}

export default Proxy