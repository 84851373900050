import React, { useState, useEffect, useRef } from 'react'
import { NavLink } from "react-router-dom";
import Logo from "../assets/imgredesign/logo.svg"
import LangSwitch from './LangSwitch/LangSwitch'
import Modal from '../components/Modal/Modal'
import { useTranslation } from 'react-i18next'
import storage from '../storage/storage.js'
import exit from '../assets/imgredesign/exit.svg'
import mobileLogo from '../assets/svg/mobileLogo.svg'
import { howMuchTimeLeft } from '../storage/storage.js'
//import { el } from 'date-fns/locale';

const SideBarA = (props) => {
  const { adminStatus, activePlan } = props
  let menuref = useRef();
  const { t } = useTranslation();
  const [stateOpen, setStateOpen] = useState(false);
  const userdata = JSON.parse(localStorage.getItem("userData"))
  // Effect for listen mousedown for mobile if user clicked over area sidebar
  useEffect(() => {
    document.addEventListener("mousedown", (event = "") => {
      try {
        if (!menuref.current.contains(event.target)) {
          setStateOpen(false);
        }
      } catch (e) { }
    })
  })



  return (
    <>

      {/*===================================================== DESKTOP VERSION============================================================== */}

      <div className="sidebar">
        <div className="sidebar-logo">
          {/*{<a href="https://bitok.pro"><img src={Logo} alt="" className="logo-img"/></a>}*/}
        </div>
        <div className="sidebar-menu">

          <div className="sidebar-menu-main">
            {/*<LangSwitch/>*/}
            <ul>
              {userdata.email !== "demo@bitok.pro" ?
                storage.link_main.main.map(el =>
                  el.active ?
                    <li key={el.title}>
                      <NavLink
                        to={el.link}
                        className="link"
                        activeClassName='active'>
                        <img src={el.imgs} />
                        <p style={{ fontSize: 24, marginLeft: 18 }}>{t(el.title)}</p>
                      </NavLink>
                    </li>
                    :
                    <li key={el.title}>
                      <a
                        activeClassName='active'
                        className="link"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: 'white' }}
                        href={el.link}>
                        <img src={el.imgs} />
                        <p style={{ fontSize: 24, marginLeft: 18 }}>{t(el.title)}</p></a>
                    </li>) :
                storage.link_main.demo.map(el =>
                  el.active ?
                    <li key={el.title}>
                      <NavLink
                        to={el.link}
                        className="link"
                        activeClassName='active'>
                        <img src={el.imgs} />
                        <p style={{ fontSize: 24, marginLeft: 18 }}>{t(el.title)}</p>
                      </NavLink>
                    </li>
                    :
                    <li key={el.title}>
                      <a
                        activeClassName='active'
                        className="link"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: 'white' }}
                        href={el.link}>
                        <img src={el.imgs} />
                        <p style={{ fontSize: 24, marginLeft: 18 }}>{t(el.title)}</p></a>
                    </li>
                )}
              {adminStatus ?
                <li>
                  <NavLink
                    to='/admin'
                    className="link"
                    activeClassName='active'>
                    <i className="fas fa-cog"></i>
                    <p>{t('admin')}</p>
                  </NavLink>
                </li>
                : null}
            </ul>
          </div>
          <div className="sidebar-menu-alt">


            <div style={{ visibility: activePlan !== null && howMuchTimeLeft(activePlan.dateEnd) <= 7 ? '' : 'hidden' }} className="free_trial_container">
              <div className="additional_container_trial">
                <p className="trial_title">{t('untilLeftSubscription')}</p>
                {activePlan !== null ?
                  <p className="trial_day_text">{`${t('left')} ${howMuchTimeLeft(activePlan.dateEnd)} `}
                    {howMuchTimeLeft(activePlan.dateEnd) <= 7 && howMuchTimeLeft(activePlan.dateEnd) > 5 ? t('dayss') : null}
                    {howMuchTimeLeft(activePlan.dateEnd) <= 4 && howMuchTimeLeft(activePlan.dateEnd) !== 1 ? t('days') : null}
                    {howMuchTimeLeft(activePlan.dateEnd) === 1 ? t('days') : null}
                  </p>
                  : null}
                <NavLink className="trial_link" to="/subscribes">{t('selectPlan').toUpperCase()}</NavLink>
              </div>
            </div>

            <a style={{ textDecoration: 'none' }} onClick={() => setStateOpen(false)} href="#quit">
              <img className="LeaveImg" src={exit} alt="Leave" />
            </a>

          </div>


          {/* <div style={{ padding: '15px 35px' }}>
            <p style={{ color: "#9FA2B4" }}>{t('supp')}:</p>
            <p style={{ color: "white" }}>support@bitok.pro </p>
          </div> */}
          <div
            style={{
              padding: 35,
              paddingBottom: 18,
              paddingTop: 18,
              marginTop: 15,
              background: "#424A61"
            }}>
            <a style={{ display: 'flex', alignItems: 'center', color: "rgba(163, 184, 224, 1)" }} className="buttonMobileQuit" href="#quit">{t('quit')}<img style={{ width: 22, marginLeft: 13 }} src={exit} alt='exit' /></a>
          </div>
        </div>
      </div>


      {/*===================================================== MOBILE VERSION============================================================== */}

      <div className="header_mobile">
        {/*<img src={Logo} alt="mobileLogo" style={{ width: "3em" }} />*/}
      </div>
      <label className={!stateOpen ? "menu-btn" : "checkedspan"} >
        <span onClick={() => setStateOpen(!stateOpen)}></span>
      </label>
      <div ref={menuref} className="sidebarMobile" style={!stateOpen ? { left: '-120%', transition: "0.5s" } : { left: '0', transition: "0.5s", width: '100%' }}>
        <div className="sidebar-logo">
          {/*<img src={Logo} alt="" style={{ width: 220 }} className="logo-img" />*/}
        </div>
        <div className="sidebar-menu">
          <div className="sidebar-menu-main">
            <ul>
              {userdata.email !== "demo@bitok.pro" ?
                storage.link_main.main.map(el =>
                  <li key={el.title}><NavLink to={el.link} style={{ alignItems: "center", display: "flex", fontSize: 20 }} className="link" onClick={() => setStateOpen(false)} activeClassName='active'><img style={{ marginRight: 15 }} src={el.imgs} /><p>{t(el.title)}</p></NavLink></li>)
                :
                storage.link_main.demo.map(el =>
                  <li key={el.title}><NavLink to={el.link} style={{ alignItems: "center", display: "flex", fontSize: 20 }} className="link" onClick={() => setStateOpen(false)} activeClassName='active'><img style={{ marginRight: 15 }} src={el.imgs} /><p>{t(el.title)}</p></NavLink></li>)
              }
              {adminStatus ?
                <li><NavLink to='/admin' className="link" onClick={() => setStateOpen(false)} activeClassName='active'><i className="fas fa-cog"></i><p>{t('admin')}</p></NavLink></li> : null}
            </ul>

          </div>



          <div style={{ padding: '15px 35px' }}>
            <p style={{ color: "#9FA2B4" }}>{t('supp')}:</p>
            <p style={{ color: "white" }}>support@bitok.pro</p>
          </div>

          <div style={{ position: 'relative', width: 95, marginLeft: 35, marginBottom: 25 }}>
            <LangSwitch type="sidebar" />
          </div>

          <div style={{ padding: 35, paddingTop: 10 }}>
            <a style={{ display: 'flex', alignItems: "center", color: "rgba(163, 184, 224, 1)" }} className="buttonMobileQuit" href="#quit">
              {t('quit')}<img style={{ width: 20, marginLeft: 13 }} src={exit} alt='exit' />
            </a>
          </div>
        </div>


      </div>
      <Modal content="leave" linkSignal={"quit"} />
    </>
  );
}

export default SideBarA
