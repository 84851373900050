import React,{useState} from 'react'
import ModalWithdraw from '../../Modal/ModalWithdraw/ModalWithdraw'
import ModalDepositBalance from '../../Modal/ModalDepositBalance/ModalDepositBalance'
import {postMerchant} from '../../../api/api.js'
import {toast, ToastContainer} from "react-toastify";


const BalanceItem = (props) => {
  const {title,stack,currency,additionalCurrency,t,currentPrice,amplitudeId,amplitudeConfig,visibleDeposit,setVisibleDeposit} = props
  const [visible,setVisible] = useState(false)
  const [orderUserId, setOrderUserId] = useState('')
  const userdata = JSON.parse(localStorage.getItem("userData"))
  const notifyInfo = (data) => toast.info(data);

  const createNewMerche = () => {
    setVisibleDeposit(true)
    //postMerchant(userdata.email,userdata.session,setVisibleDeposit,setOrderUserId,amplitudeId,amplitudeConfig)
  }

  const NotPermission = () => {
    const notifyError = (data) => toast.error(data);
    notifyError("Demo account. You don`t have permission")
  } 

  return(
    <div className="balance_container_item">
      <div className="balance_header_item">
        <img src={currency} alt="currency"/>
        <p className="title_balance_item">{title.toUpperCase()}</p>
        <p style={{alignItems: 'center',display: 'flex',marginLeft: 5}} className="subtitle_balance_item">{additionalCurrency ? additionalCurrency.toUpperCase(): null}</p>
      </div>
      <div className="balance_body_item">
        <div className="price_container">
          <div className="price_item">
            <p className="description_price">{t('currentBalance')}</p>
            <p className="price">{currentPrice} {title.toUpperCase()}</p>
          </div>
          {stack ?
          <div className="price_item">
          <p className="description_price">{t('inStack')}</p>
          <p className="price">1 000 {title.toUpperCase()}</p>
          </div>
          : null}
        </div>
        
         <div className="balance_panel_item">
           {userdata.email !== "demo@bitok.pro"?
             <button onClick={() => createNewMerche()}>{t('deposit')}</button>
           :
           <button onClick={() => NotPermission()}>{t('deposit')}</button>}
        
          {/**/}
          <button onClick={() => notifyInfo("In development...")}>{t('withdraw')}</button>
          {/*<button onClick={() => setVisible(true)}>{t('withdraw')}</button>*/}
        </div>
        
      </div>
      <ModalWithdraw
        t={t}
        vision={visible}
        setVision={setVisible}
        imgCurrency={currency}
        currency={title}
      />
      {/*<ModalDepositBalance
      t={t}
      imgCurrency={currency}
      notifyInfo={notifyInfo}
      orderUserId={orderUserId}
      vision={visibleDeposit}
          setVision={setVisibleDeposit}/>*/}
      <ToastContainer/>
    </div>
  )
}

export default BalanceItem
