import React, { useState } from 'react'
// import TextField from '@mui/material/TextField';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import MobileDatePicker from '@mui/lab/MobileDatePicker';
import DatePicker, { registerLocale } from 'react-datepicker'
import es from "date-fns/locale/uk";
import "react-datepicker/dist/react-datepicker.css";

const DatePickerContainer = (props) => {
  const { dateFrom, setDateFrom, dateTo, setDateTo, t } = props

  const [dateRange, setDateRange] = useState([null, null]);
  const [calendarIsOpen, setCalendarIsOpen] = useState(false)
  registerLocale("es", es);

  // const DateFromForUse = typeof dateFrom === "number" ? dateFrom : Date.parse(dateFrom)
  // const dateFromV = new Date(dateFrom).getDate() + '/' + new Date(dateFrom).getMonth() + '/' + new Date(dateFrom).getFullYear()
  // const dateToV = new Date(dateTo).getDate() + '/' + new Date(dateTo).getMonth() + '/' + new Date(dateTo).getFullYear()
  // const dateFromForInput = new Date(DateFromForUse).getDate() + '.' + new Date(DateFromForUse).getMonth() + '.' + new Date(DateFromForUse).getFullYear()
  // const dateToForInput = new Date(dateTo).getFullYear() + '-' + new Date(dateTo).getMonth() + '-' + new Date(dateTo).getDate()
  // console.log(DateFromForUse)
  // console.log(dateFromForInput)
  // console.log(dateTo)

  return (
    <div className="date_main_container">
      <p className="date_period_text">{t('periodFilter')}</p>
      <div className='date_main_container_for_click' onClick={() => setCalendarIsOpen(!calendarIsOpen)}></div>
      <div className="date_container">
        <DatePicker
          locale="es"
          monthsShown={1}
          disabledKeyboardNavigation
          selectsRange={true}
          startDate={dateFrom}
          endDate={dateTo}
          onChange={(update) => {
            setDateFrom(update[0])
            setDateTo(update[1])
          }}
          open={calendarIsOpen}
          closeOnScroll={() => {
            setCalendarIsOpen(false)
            if (dateTo === null) {
              setDateTo(Date.now())
            } 
          }}
          onClickOutside={() => {
            setCalendarIsOpen(false)
            if (dateTo === null) {
              setDateTo(Date.now())
            } 
          }}
          dateFormat='dd.MM.yyyy'
          
        />
      </div>
    </div>
  )
}

export default DatePickerContainer
