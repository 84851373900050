import React,{useState,useEffect} from 'react'
import Title from '../components/Title/Title'
import { useTranslation } from 'react-i18next'
import folder from '../assets/img/folder_grey.png'
import {
    postCheckPromo,
    currentUrl,
    getRefferalCurrentList,
    getRefferalHistory,
    getRefferalLink} from '../api/api.js'
import { ToastContainer, toast } from 'react-toastify';
import copy from '../assets/imgredesign/copy.svg'

const Partners = (props) => {
    const {rerender,setRerender} = props
    const [name,setName] = useState('')
    const [visible,setVisible] = useState(0)
    const [reffLink,setReffLink] = useState('')
    const [reffList,setReffList] = useState([])
    const [reffHistory,setReffHistory] = useState([])
    const { t } = useTranslation();
    const userdata = JSON.parse(localStorage.getItem("userData"))
    const notifyError = (data) => toast.error(data);
    const notifySuccess = (data) => toast.success(data);

    const allProfitReff = (arr) => {
        let res = 0;
        arr.map((el) => res += el.percent)
        return res
    }

    useEffect(() => {
        getRefferalLink(userdata.email,setReffLink)
        getRefferalCurrentList(userdata.email,setReffList)
        getRefferalHistory(userdata.email,setReffHistory)
    },[])

    const createPromo = () => {
        if(name.length !== 0){
            postCheckPromo(name,userdata.email,notifyError,notifySuccess,setRerender,rerender)
            setName('')
        }else{
            notifyError("Type promocode") 
        }
    }

    return(
        <div className="trade-data">
        <Title title={t("afilliate")}/>
            <div className="setting_container">
                <div className='admin_profile'>
                    <div className='promocode-container'>
                        <h3 className='promo_hover'>{t('promo')}</h3>
                        <div style={{textAlign:'center',maxWidth:415}}>
                        <input 
                        value={name}
                        placeholder="Type here promocode if you have"
                        onChange={(event) => setName(event.target.value)}/>
                        <br/>
                        <button onClick={() => createPromo()}>{t('promoCheck')}</button>
                        </div>
                    </div>
                    <div className='reffsystem-container'>
                        <h3 className='promo_hover' style={{marginBottom: 29}}>{t("reffSystem")}</h3>
                        <div className='link_container_reff'>
                            <p style={{color:'white',fontSize:18}}>{t('refflink')}: </p>
                            <p style={{color:"rgba(107, 140, 206, 1)",fontSize:18}}>{currentUrl}register/{reffLink}</p>
                            <img onClick={() =>  navigator.clipboard.writeText(`${currentUrl}register/${reffLink}`)} style={{width:30}} src={copy} alt="copy"/>
                        </div>
                        <div className='info_earn_ref_container'>
                            <p>{t('refTotalEarn')}: <b>{reffHistory.length !== 0 ? allProfitReff(reffHistory) : 0} USDT</b></p>
                        </div>
                        <div className='container-current-refferallist'>
                            {!visible?
                        <div>
                            <div className='refflist_hover_container'>
                                <div>
                                    <h3>{t('reffCurrReff')}</h3>
                                </div>
                                <div>
                                    <p onClick={() => setVisible(1)}>{t('refHistory')}</p>
                                </div>
                            </div>
                 
                            <table>
                                <thead>
                                    <tr>
                                        <td>№</td>
                                        <td>Email</td>
                                        <td>Date register</td>
                                        <td>Earn</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reffList.map((el,key) => 
                                        <tr key={key}>
                                            <td>{key+1}</td>
                                            <td>{el.email}</td>
                                            <td>{el.date}</td>
                                            <td style={{color:"rgba(156, 255, 109, 1)"}}>{reffHistory.length !== 0 ? allProfitReff(reffHistory.filter(res => res.email === el.email)) : 0} USDT</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            <div className='mobile-ref-current-user'>
                                {reffList.map((el,key) => 
                                    <div key={key} className='mobile-ref-current-user-item'>
                                    <div>
                                        <p>Email:</p>
                                        <p>{el.email}</p>
                                    </div>
                                    <div>
                                        <p>Date register:</p>
                                        <p>{el.date}</p>
                                    </div>
                                    <div>
                                        <p>Earn:</p>
                                        <p>{reffHistory.length !== 0 ? allProfitReff(reffHistory.filter(res => res.email === el.email)) : 0} USDT</p>
                                    </div>
                                </div>
                                )}
                            </div>
                        </div>
                            :
                        <div>
                            <div className='refflist_hover_container'>
                                <div>
                                    <h3>{t('reffHistoryReff')}</h3>
                                </div>
                                <div>
                                    <p onClick={() => setVisible(0)}>{t('refBackList')}</p>
                                </div>
                            </div>
                            <div className='history_refferal_container'>
                                {reffHistory.map((el,key) => 
                                   <div key={key} className='history_refferal_container_item'>
                                        <p>{key+1}. {t('historyUser')} {el.email} {t('historyBought')} {el.nameSubscribe}. {t("historyTake")} {el.percent} USDT.</p>
                                   </div>
                                )}
                            </div>
                        </div>}
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </div>
    )
}

export default Partners