import React,{useState,useEffect} from 'react';
import {connect} from 'react-redux'
import { useTranslation } from 'react-i18next'
import Title from '../components/Title/Title'
//import {amplitudeConfig} from '../storage/storage'
//import arrow from '../assets/svg/instruction_arrow.svg'
import {instruction} from '../storage/instruction.js'
//import {Link} from 'react-scroll'
import YouTube from 'react-youtube';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Slider from "react-slick";


const Instructions = (props) => {
  const {amplitudeId} = props
  const [value, setValue] = useState('1')
  const [oldSlide,setOldSlide] =  useState(0)
  const [activeSlide,setActiveSlide] = useState(0)
  const [activeSlide2,setActiveSlide2] = useState(0)
  const { t,i18n } = useTranslation();
  const handleChange = (event, newValue) => {setValue(newValue)};
  const [vision,setVision] = useState(true)

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style}}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style}}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
        setActiveSlide(next)
        setOldSlide(current)
    },
    afterChange: current => setActiveSlide2(current),
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
  };
  //useEffect(() => {setTimeout(amplitudeConfig('INSTRUCTIONS_VIEW',{user_id: amplitudeId.id}), 1000)},[])

    return (
        <div className="instructions-wrap">
        <Title title={t('instruction')} description={t('instructionDescription')}/>
            <div className="instructions-body">
                <div style={{color: 'white'}} className="instructions-content">
                    <TabContext value={value}>
                    <TabList
                        TabIndicatorProps={{
                        style:{display: 'none'}
                        }}
                    onChange={handleChange} aria-label="setting">
                        <Tab label={t('instructionText')} value="1" />
                        <Tab label={t('instructionVideo')} value="2" />
                    </TabList>
                        <TabPanel value="1">
                        <div style={{padding:25,background: "rgba(50, 56, 73, 1)"}}>
                            <div className='layout_instructions_container' style={{fontSize:30,position:'relative'}}>
                            <p style={{
                                position:'absolute',
                                right:0,
                                top: "-40px",
                                color:"rgba(107, 140, 206, 1)"
                            }}>{activeSlide+1}</p>
                            <Slider {...settings}>
                                {instruction[i18n.language][0].content.map((el,key) =>
                                    <p key={key} dangerouslySetInnerHTML={{ __html: el }}/>                                 
                                )}
                            </Slider>
                            </div>
                        </div>
                        </TabPanel>
                        <TabPanel value="2">
                        <div style={{padding:25,background: "rgba(50, 56, 73, 1)"}}>
                            <h3 style={{marginLeft:15}}>In development</h3>
                        </div>
                        </TabPanel>
                    </TabContext>
                    {/*
                     <div className='consist_container'>
                        <h5 onClick={() => setVision(!vision)}>{t('consists')} <img style={{
                            transform: vision ?'rotate(0deg)': 'rotate(180deg)',
                            transition: '0.5s'
                            }} src={arrow} alt="arrow"/></h5>
                        <ul style={{display: vision?'block':'none'}}>
                            {instruction[i18n.language][0].consist.map((el,key) => 
                            <Link 
                            key={key}
                            to={instruction['link'][key]}
                            spy={true}
                            smooth={true}
                            offset={0}
                            duration={500}>
                                <li dangerouslySetInnerHTML={{ __html: el }}/>
                            </Link>
                            )}
                        </ul>
                    </div>
                    */}
                </div>
            </div>
        </div>
    );
}
const mapDispatchToProps = {
    //createPost, showAlert
}

const mapStateToProps = state => ({
    //balance: state.binary.balance,
})

export default connect(mapStateToProps, mapDispatchToProps)(Instructions)
