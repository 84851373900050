import React,{useState} from 'react'
import close from '../../../assets/svg/close.svg'
import info from '../../../assets/svg/info.svg'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ModalSuccess from '../ModalSuccess/ModalSuccess'
import WithdrawBalance from '../../Balance/WithdrawBalance/WithdrawBalance'

const ModalWithdraw = (props) => {
  const {vision,setVision,imgCurrency,currency,t} = props
  const [step, setStep] = useState(0)
  const [currencyType, setCurrencyType] = useState('trc');
  const [infoOpen,setInfo] = useState(false);
  const handleChangeDeposit = (event) => {setCurrencyType(event.target.value)};

  const closeAll = () => {
    setVision(false)
    setStep(0)
  }

  return(
    <div style={{display: vision ? 'flex' :'none'}} className="Modal">
    <div className="Modal_Body modal_mobile_withdraw">
      <img style={{position: 'absolute', top: 10, right: 10,cursor: 'pointer'}} onClick={() => closeAll()} src={close} alt="close"/>
      {!step?
      <div className="modal_withdraw_container">
          <WithdrawBalance t={t} imgCurrency={imgCurrency} currency={currency}/>
          <div>
          <p className="alert_modal_description">{t('chooseSet')}</p>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio"
              value={currencyType}
              onChange={handleChangeDeposit}>
            <FormControlLabel value="trc" control={<Radio style={{color: 'rgba(47, 128, 237, 1)'}}/>} label={<p style={{color: 'white'}}>TRC20</p>}/>
         </RadioGroup>
          </div>

          <div>
            <p className="alert_modal_description">{t("address")}</p>
            <div>
              <input style={{border: '1px solid #EB5757'}} type="text" placeholder={t('typeAddress')}/>
            </div>
            <p className="withdraw_alert_danger">{t('invalidAddress')} TRC20</p>
          </div>

          <div>
            <p className="alert_modal_description">{t('sum')}</p>
            <div style={{position: 'relative'}}>
            <input type="number" placeholder={t('typeSum')}/>
            <p className="currency_main_withdraw">USDT</p>
            </div>
            <p className="withdraw_alert_danger">{t('subNoBalance')}</p>

          </div>
            <div style={{marginTop:24}}>
              <div className="additional_info_withdraw">
                <div>
                  <p>Transaction fee: </p>
                </div>
                <div>
                  <p>0.0005 BTC</p>
                </div>
              </div>
              <div className="additional_info_withdraw">
                <div>
                  <p>AML Commission:
                    <img
                    onMouseOut={() => setInfo(false)}
                    onMouseEnter={() => setInfo(true)}
                    src={info} alt="aml info"/>
                  <div
                  style={{display: infoOpen? 'block' : 'none'}}
                  className="container_info"><p>Some interesting text ...</p></div>
                  </p>
                </div>
                <div>
                  <p>0.00003 BTC</p>
                </div>
              </div>
          </div>
          <button onClick={() => setStep(1)} className="withdraw_button_modal">{t('withdraw')}</button>
        </div>
        :
      <ModalSuccess
      t={t}
      title={t('withdrawComplete')}
      closeAll={closeAll}/>
    }
    </div>
</div>
  )
}

export default ModalWithdraw
