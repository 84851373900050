import React, { useEffect, useState } from 'react'
import { postSubscribe, createNewVAlidateTrial, getSubscribe } from '../../../api/api.js'
import { toast, ToastContainer } from "react-toastify";
import PhoneInput from 'react-phone-number-input'
import { useTranslation } from 'react-i18next'
import close from '../../../assets/imgredesign/close.svg'


const ModalTrial = (props) => {
    const { amplitudeConfig, rerender, vision, setRerender, amplitudeId, setVision } = props
    const { t } = useTranslation();
    const [code, setCode] = useState("")
    const [step, setStep] = useState(false)
    const [phone, setPhone] = useState('')
    const userdata = JSON.parse(localStorage.getItem("userData"))
    const notifyError = (data) => toast.error(data);
    const [subscribe, setSubscribe] = useState([])


    const sendValidateSubscription = () => {
        if (phone.length !== 0) {
            createNewVAlidateTrial(userdata.session, userdata.email, setStep, phone, notifyError)
        } else {
            notifyError("You must enter your phone number")
        }
    }

    const createTrial = () => {
        postSubscribe(userdata.email, userdata.session, "Trial", 7, '', notifyError, rerender, setRerender, subscribe, true, amplitudeConfig, amplitudeId.id, code, setVision, phone)
    }

    useEffect(() => {
        getSubscribe(setSubscribe)
    }, [])

    return (
        <div style={{ alignItems: 'center', display: vision ? 'flex' : 'none', justifyContent: 'center' }} className='template_close_container'>
            <div className='container_template_close_modal'>
                <div className='subcontainer_attention_needed'>
                    <div style={{ padding: 20 }} className='container_template_close_modal_item'>
                        <div style={{ textAlign: 'right', width: '100%', marginBottom: 12 }}>
                            <img onClick={() => setVision(false)} style={{ cursor: "pointer" }} src={close} alt="-" />
                        </div>
                        {!step ? <div className="body_modal_relative">
                            <p style={{ maxWidth: 390, lineHeight: '33px', color: 'white', fontSize: 20 }}>Для получения бесплатной подписки необходимо
                                подтвердить ваш номер телефона, который вы указали при регистрации</p>
                            <div style={{ width: '100%', justifyContent: "center", display: "flex" }}>
                                <div style={{ width: 240 }}>
                                    <PhoneInput
                                        placeholder={t('phoneRegPlace')}
                                        value={phone}
                                        onChange={setPhone} />
                                </div>
                            </div>
                            <button className='button_check_modal_trial' onClick={() => sendValidateSubscription()}>Send code</button>
                        </div>
                            :
                            <div className="body_modal_relative">
                                <p>Укажите код из телефона</p>
                                <input className='input_check_modal_trial' type="text" value={code} onChange={(event) => setCode(event.target.value)} />
                                <br />
                                <button className='button_check_modal_trial' onClick={() => createTrial()}>Validate</button>
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalTrial