import React, {useState, useEffect} from 'react'

import {Box, Button, MenuItem, TextField, Typography} from "@mui/material";
import {useFormik} from "formik";
import * as yup from 'yup';
import {getSubscribe, postSubscribeRow} from "../../api/api";

const SubConfig = (props) => {

    const [subscribesAvailable, setSubscribesAvailable] = useState([{name: "Advanced"}]);

    useEffect(() => {
        getSubscribe(setSubscribesAvailable);
    }, [setSubscribesAvailable])

    const renderOptions = () => subscribesAvailable.map(val => (
            <MenuItem key={val.name} name={fieldsId[2]} value={val.name}>
                {val.name}
            </MenuItem>
        )
    )

    const initialFields = {
        strategy: subscribesAvailable[0].name,
        row: "",
        stopMarks: []
    }

    const fieldsId = Object.keys(initialFields);
    const fieldsLabels = fieldsId.map(val => val.toUpperCase());


    const validationSchemaFields = yup.object({
        row: yup.string().required("Can`t be empty"),
        stopMarks: yup.array().required().of(yup.object().shape({
            loss: yup.number().min(0.001).max(1000),
            prof: yup.number().min(0.001).max(1000)
        })),
        strategy: yup.string().required().oneOf(subscribesAvailable.map(val => val.name))
    })

    const formikHook = useFormik({
            initialValues: initialFields,
            validationSchema: validationSchemaFields,
            onSubmit: (values) => {
                const requestBody = {
                    ...values, stopMarks: JSON.stringify(values.stopMarks.map(({loss, prof}) => ({
                        Loss: loss, Prof: prof
                    })))
                }
                postSubscribeRow(requestBody)

            }
        }
    )


    const currentSubscription = subscribesAvailable.find((sub) => sub.name === formikHook.values.strategy)

    const handleSubscriptionChange = () => {
        formikHook.setFieldValue(fieldsId[1], currentSubscription.gidraSettings?.row || "")
        formikHook.setFieldValue(fieldsId[2], currentSubscription.gidraSettings?.stopMarks || [])
    }

    useEffect(() => {
        handleSubscriptionChange()
    }, [currentSubscription])

    const handleRowUpdate = () => {
        if (formikHook.values.row === currentSubscription.gidraSettings?.row) {
            formikHook.setFieldValue(fieldsId[2], currentSubscription.gidraSettings?.stopMarks || [])
        } else {
            const newStopMarks = formikHook.values.row.split(",").map(() => ({loss: 1, prof: 1}));
            formikHook.setFieldValue(fieldsId[2], newStopMarks)
        }
    }

    useEffect(() => {
        handleRowUpdate()
    }, [formikHook.values.row])

    const handleStopMarksChange = (event, index, fieldKey) => {
        const newStopMarks = JSON.parse(JSON.stringify(formikHook.values.stopMarks))
        newStopMarks[index][fieldKey] = event.target.value

        formikHook.setFieldValue(fieldsId[2], newStopMarks)
    }
    const manageMarksForCoefs = () => {
        return formikHook.values.stopMarks?.map((stopMark, index) => {
                return (<Box key={index} sx={{display: "flex", flexDirection: "row", gap: "8px"}}>
                    <TextField
                        id={index + "loss"}
                        label={"Loss"}
                        required
                        fullWidth variant="filled"
                        value={formikHook.values.stopMarks?.[index]?.loss}
                        error={formikHook.touched.stopMarks?.[index]?.loss && !!formikHook.errors.stopMarks?.[index]?.loss}
                        helperText={formikHook.touched.stopMarks?.[index]?.loss && formikHook.errors.stopMarks?.[index]?.loss}
                        onChange={(event) => handleStopMarksChange(event, index, "loss")}

                    />
                    <TextField
                        id={index + "prof"}
                        label={"Prof"}
                        required
                        fullWidth variant="filled"
                        value={formikHook.values.stopMarks?.[index]?.prof}
                        error={formikHook.touched.stopMarks?.[index]?.prof && !!formikHook.errors.stopMarks?.[index]?.prof}
                        helperText={formikHook.touched.stopMarks?.[index]?.prof && formikHook.errors.stopMarks?.[index]?.prof}
                        onChange={(event) => handleStopMarksChange(event, index, "prof")}
                    />
                </Box>)
            }
        )
    }


    return (
        <Box
            component="form" onSubmit={formikHook.handleSubmit}
            className="creationBox"
            sx={{display: "flex", flexDirection: "column", gap: "8px"}}
        >
            <Box>
                <TextField required
                           fullWidth
                           select
                           variant="filled"
                           id={fieldsId[0]}
                           name={fieldsId[0]}
                           label={fieldsLabels[0]}
                           error={formikHook.touched.strategy && !!formikHook.errors.strategy}
                           onChange={formikHook.handleChange}
                           defaultValue={subscribesAvailable[0].name}
                           value={formikHook.values.strategy}
                >
                    {renderOptions()}
                </TextField>
            </Box>
            <Box>
                <TextField required
                           fullWidth
                           variant="filled"
                           id={fieldsId[1]}
                           label={fieldsLabels[1]}
                           value={formikHook.values.row}
                           error={formikHook.touched.row && !!formikHook.errors.row}
                           onChange={formikHook.handleChange}
                />
            </Box>
            <Box sx={{display: "flex", flexDirection: "column", gap: "8px"}}>
                {manageMarksForCoefs()}
            </Box>

            <Button
                variant="filled"
                type="submit"
            >
                <Typography>
                    Update row settings for this strategy
                </Typography>
            </Button>
        </Box>
    );
}

export default SubConfig;