import { connect } from 'react-redux'
import HistoryTrades from "../components/HistoryTrades";
import OpenTrades from "../components/OpenTrades";
import ChartMain from "../components/Chart/ChartMain";
import Footer from '../components/Footer/Footer'
import Title from '../components/Title/Title'
import Stepper from '../components/Stepper/Stepper'
import CloseUITrade from '../components/CloseUITrade/CloseUITrade'
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ProfitChart from '../components/Chart/ProfitChart/ProfitChart'
import { ParserDateExpiried, howMuchTimeLeft, amplitudeConfig, timestampConvert } from '../storage/storage.js'
import { NavLink } from "react-router-dom";
import ModalTrial from "../components/Modal/ModalTrial/ModalTrial"
import CheckExistNeeded from '../components/CheckExistNeeded/CheckExistNeeded';
import ModalClearTrade from '../components/Modal/ModalClearTrade/ModalClearTrade';
// import {updateTransaction,createSwap} from "../api/api"
import { updateTransaction } from "../api/api"
import { toast, ToastContainer } from "react-toastify";


const TradeApp = (props) => {
  const { tradeType, filterTrade, trade, openTrade, loader, keys, balance, rerender, setRerender, activePlan, amplitudeId } = props
  const { t } = useTranslation();
  const ref = useRef(null)
  const [width, setWidth] = useState(0);
  const [vision, setVision] = useState(false)
  const [visionClear, setVisionClear] = useState(false)
  const userdata = JSON.parse(localStorage.getItem("userData"))
  const notifySuccess = (data) => toast.info(data);
  const notifyError = (data) => toast.error(data);
  const valide = [keys.length !== 0 && (keys.filter(el => el.name === 'HUOBI')[0].value === null || keys.filter(el => el.name === 'HUOBI')[0].value.length === 0), 'noneUser']
  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
  }, [ref.current]);

  // createSwap()

  const debug_arr = [0]
  const dateArr = [0]
  if (trade.length > 0) {
    for (var i = 0; i < trade.filter(el => el.type !== null).length; i++) {
      debug_arr.push(debug_arr[i] + trade[i].commision + trade[i].pnL);
      dateArr.push(`${new Date(trade[i].time).getDate().toString().length === 1 ? `0${new Date(trade[i].time).getDate()}` : new Date(trade[i].time).getDate()}.${(new Date(trade[i].time).getMonth() + 1).toString().length === 1 ? `0${new Date(trade[i].time).getMonth() + 1}` : new Date(trade[i].time).getMonth() + 1}.${new Date(trade[i].time).getFullYear().toString().slice(-2)}`)
    }
  }

  const createClear = () => {
    if (userdata.email !== "demo@bitok.pro") {
      updateTransaction(userdata.session, userdata.email, notifyError, notifySuccess, rerender, setRerender)
      setVisionClear(false)
    } else {
      notifyError("Demo account. You don`t have permission")
    }
  }

  const convertProfit = (currentMoney, currentMultiplier) => {
    return String(currentMoney).slice(0, 8)
  }

  // const EveryHourHandler = (arr = [],currentMultiplier,typeInPercent=false) => {
  //     const currentDate = new Date();
  //     var resultEveryDay = 0
  //     //console.log(currentDateStart > new Date(arr[0].time).getTime())
  //     const arrTradeThisDay = arr.filter(el => new Date(el.time).getDate() == currentDate.getDate())
  //     //console.log(arrTradeThisDay)
  //     if(arrTradeThisDay.length !== 0){
  //       for (var a = 0; a < arrTradeThisDay.length; a++) {
  //         resultEveryDay =+  (resultEveryDay + arrTradeThisDay[a].commision + arrTradeThisDay[a].pnL)
  //       }
  //     }
  //     if(typeInPercent){
  //       return ((resultEveryDay/100)*currentMultiplier).toFixed(2)
  //     }else{
  //       return String(resultEveryDay).slice(0,8)
  //     }
  // }

  const EveryHourHandler = () => {
    let dataDay = Date.now()
    let arr = filterTrade === undefined || filterTrade === null || filterTrade.length === 0 ? [] : 
    filterTrade.filter(el => Date.parse(el.time) > (dataDay - 86400000))
    let sum = 0
    for (let i = 0; arr.length > i; i++) {
      sum = sum + arr[i].pnL + arr[i].commision
    }
    return JSON.stringify(sum).slice(0,8)
  }

  return (
    <div>
      <ModalClearTrade
        t={t}
        ClearTradeView={createClear}
        title={t('clearModalHover')}
        vision={visionClear}
        setVision={setVisionClear}
      />
      {valide[0] || activePlan === null ?
        <CheckExistNeeded
          ref={ref}
          t={t}
          widthVal={width}
          setVision={setVision}
          amplitudeId={amplitudeId}
          activePlan={activePlan}
          rerender={rerender}
          keyExistBool={valide[0]}
          setRerender={setRerender}
          balance={balance}
        /> : null}
      <div style={{ width: '100%' }} ref={ref}></div>
      <div className="trade-data">
        <Title title={t('dash')} description={t('dashWelcome')} />
        {/*valide[0] || activePlan === null?      
           <Stepper 
           t={t}
           setVision={setVision} 
           amplitudeId={amplitudeId}
           activePlan={activePlan}
           rerender={rerender} 
           keyExistBool={valide[0]} 
           setRerender={setRerender} 
           balance={balance}/> : null */}

        <div className="close-ui-trade">
          {/*valide[0] || activePlan === null  ?
            <CloseUITrade 
            t={t} valide={valide}
            balance={balance}
            activePlan={activePlan}/> : null */}

          <div className="statistics_wrapper">
            <div className="statistics_wrapper_item">
              <p className="statistics_title">{t('signalOne')}</p>
              <div className="statistics_container">
                <p>{activePlan !== null ? convertProfit(debug_arr[debug_arr.length - 1], activePlan.multiplier) : 0}$</p>
                <p></p>
                {/*<p>{activePlan !== null && debug_arr.length !== 1 ? ((( convertProfit(debug_arr[debug_arr.length-1],activePlan.multiplier) / activePlan.multiplier)*100) - 100).toFixed(1): 0} %</p>*/}
              </div>
              <ProfitChart dateArr={dateArr} current_arr={trade} />
            </div>
            {/* <div className="statistics_wrapper_item">
              <p className="statistics_title">{t('signalTwo')}</p>
              <div className="statistics_container">
                <p>{activePlan !== null ? EveryHourHandler(trade) : 0}$</p>
                <p></p>
              </div>
              <ProfitChart dateArr={dateArr} current_arr={trade} type={1} />
            </div> */}
            <div className="statistics_wrapper_item">
              <p className="statistics_title">{t('signalThree')}</p>
              <div className="statistics_container">
                <p>{balance ? balance.toFixed(2) : 0} USDT</p>
                <p></p>
              </div>
              <p className="alm-value"></p>
            </div>
            {/* <div style={{ zIndex: 0 }} className="statistics_wrapper_item">

              {activePlan !== null ?
                activePlan.type !== 'Trial' ?
                  <div>
                    <p style={{ color: 'white' }} className="statistics_title">{t('signalFour')}</p>
                    <div style={{ justifyContent: 'center' }} className="statistics_container">
                      <p>{activePlan.type}</p>
                      <p></p>
                    </div>
                    <div>
                      <div className="plan_stat_item">
                        <div><p>{t('signalFourEnd')}</p></div>
                      </div>
                      <div className="plan_stat_item">
                        <div><p>{ParserDateExpiried(activePlan.dateEnd)}</p></div>
                      </div>
                    </div>
                  </div>
                  :
                  <div className='free_active_container'>
                    <p className='free_active_hover'>Free trial</p>
                    <p className='free_active_description_days_left'>{howMuchTimeLeft(activePlan.dateEnd)} {t('freeContainer')}</p>
                    <div className='container_button_free'><NavLink to="/subscribes"><button>{t('selectPlan')}</button></NavLink></div>
                  </div>
                :
                <div>
                  <p className="statistics_title">{t('signalFour')}</p>
                  <div className="statistics_container">
                    <p>{activePlan !== null ? activePlan.type : 'Subscription expired'}</p>
                    <p></p>
                  </div>
                </div>
              }

            </div> */}
          </div>
          <div className="wrapper-trade">
            <ChartMain
              dateArr={dateArr}
              tradeType={tradeType}
              visionClear={visionClear}
              setVisionClear={setVisionClear}
              history={trade}
              rerender={rerender}
              setRerender={setRerender}
              t={t} />
            <OpenTrades

              openTrade={openTrade}
              loader={loader}
              tradeType={tradeType}
              t={t} />
          </div>
          <HistoryTrades
            loader={loader}
            visionClear={visionClear}
            setVisionClear={setVisionClear}
            rerender={rerender}
            setRerender={setRerender}
            filterTrade={filterTrade}
            tradeType={tradeType} />
        </div>
        {vision ?
          <ModalTrial
            setVision={setVision}
            amplitudeId={amplitudeId}
            amplitudeConfig={amplitudeConfig}
            rerender={rerender}
            vision={vision}
            setRerender={setRerender} />
          : null}
      </div>
      <ToastContainer />
    </div>
  );
}



const mapDispatchToProps = {
  //createPost, showAlert
}

const mapStateToProps = state => ({
  //alert: state.app.alert
})

export default connect(mapStateToProps, mapDispatchToProps)(TradeApp)
