import React from 'react'
import alert from '../../../assets/svg/Alert.svg'

const ModalAlertAdmin = (props) => {
    const {vision,text,t,setVision,deleteProxyServer,currentPort} = props
    return(
    <div style={{display: vision ? 'flex' :'none'}} className="Modal">
    <div className="Modal_Body body_mobile_container">
      <div className="body_modal_relative">
        <img src={alert}/>
        <p className="alert_modal_title">{text}</p>
         
        <div className="panel_alert_modal">
          <button onClick={() => deleteProxyServer(vision,currentPort)}>{t('delete')}</button>
          <button onClick={() => setVision(false)}>{t('cancel')}</button>
        </div>
        </div>
    </div>
</div>
    )
}

export default ModalAlertAdmin