import React,{useState,useEffect} from 'react'
import ModalAlertUser from '../Modal/ModalAlertUser/ModalAlertUser'
import { useTranslation } from 'react-i18next'
import {getAllUserInfo,deleteUser,getAdminTradeUser,getUserBalanceForAdmin,createNewNews} from '../../api/api'
import {timestampConvert} from '../../storage/storage'
import {toast, ToastContainer} from "react-toastify";

const UserStatistics = () => {
    const { t } = useTranslation();
    const [currentData, setCurrentData] = useState('')
    const [filterEmail,setFilterEmail] = useState('up')
    const [sendMail,setSendMail] = useState(false)
    const [arrSendMail,setArraySendMail] = useState([])
    const [filterEnter,setFilterEnter] = useState('up')
    const [filterDate,setFilterDate] = useState('up')
    const [filterTransaction,setFilterTransaction] = useState('up')
    const [filterKey,setFilterKey] = useState('up')
    const [search,setSearch] = useState('')
    const [messsage,setMessage] = useState('')
    const [currentTrades, setCurrentTrades] = useState([])
    const [step,setStep] = useState(false)
    const [vision,setVision] = useState(false)
    const [rerender,setRerender] = useState(false)
    const [balanceCurrentUser,setBalanceCurrentUser] = useState(0)
    const [userList,setUserList] = useState([])
    const [filterUserSearch,setFilterUserSearch] = useState([])
    const userdata = JSON.parse(localStorage.getItem("userData")) 
    const notifySuccess = (data) => toast.success(data);
    const notifyError = (data) => toast.error(data);

    const handleChooseUser = (email) => {
        const res = arrSendMail.filter(el => el === email)
        if(res.length === 0){
            setArraySendMail([...arrSendMail, email])
        }else{
            setArraySendMail(arrSendMail.filter(el => el !== email))
        }
    }

    const createSendmail = () => {
        if(messsage.length !== 0){
            let str = ''
            arrSendMail.map((el) => { str += "&email="+el })
            console.log(str)
            createNewNews(messsage,str,userdata.email,setSendMail,notifySuccess)
        }else{
            notifyError("Type some message")
        }
    }

    const filterUser = (searchText,listUser) => {
        if(!searchText){
            return listUser;
        }
        return listUser.filter(el => el.email.toLowerCase().includes(searchText.toLowerCase()))
    }

    const filterEmailUserList = () => {
        if(filterEmail === 'up'){
            setFilterUserSearch(userList.sort(function(a, b){
                if(a.email < b.email) { return -1; }
                if(a.email > b.email) { return 1; }
                return 0;
            }))
            setFilterEmail('down')
        }else if(filterEmail === 'down'){
            setFilterUserSearch(userList.sort(function(a, b){
                if(a.email < b.email) { return 1; }
                if(a.email > b.email) { return -1; }
                return 0;
            }))
            setFilterEmail('up')
        }
    }

    const filterDateUserList = () => {
        if(filterDate === 'up'){
            setFilterUserSearch(userList.sort(function(a, b){
                if(new Date(a.date).getTime() < new Date(b.date).getTime()) { return 1; }
                if(new Date(a.date).getTime() > new Date(b.date).getTime()) { return -1; }
                return 0;
            }))
            setFilterDate('down')
        }else if(filterDate === 'down'){
            setFilterUserSearch(userList.sort(function(a, b){
                if(new Date(a.date).getTime() > new Date(b.date).getTime()) { return 1; }
                if(new Date(a.date).getTime() < new Date(b.date).getTime()) { return -1; }
                return 0;
            }))
            setFilterDate('up')
        }
    }

    const filterTransactionUserList = () => {
        if(filterTransaction === 'up'){
            setFilterUserSearch(userList.sort(function(a, b){
                if(a.purchases.length < b.purchases.length) { return 1; }
                if(a.purchases.length > b.purchases.length) { return -1; }
                return 0;
            }))
            setFilterTransaction('down')
        }else if(filterTransaction === 'down'){
            setFilterUserSearch(userList.sort(function(a, b){
                if(a.purchases.length > b.purchases.length) { return 1; }
                if(a.purchases.length < b.purchases.length) { return -1; }
                return 0;
            }))
            setFilterTransaction('up')
        }
    }

    const filterEnterUserList = () => {
        if(filterEnter === 'up'){
            setFilterUserSearch(userList.sort(function(a, b){
                if(a.reffHistory.length < b.reffHistory.length) { return -1; }
                if(a.reffHistory.length > b.reffHistory.length) { return 1; }
                return 0;
            }))
            setFilterEnter('down')
        }else if(filterEnter === 'down'){
            setFilterUserSearch(userList.sort(function(a, b){
                if(a.reffHistory.length < b.reffHistory.length) { return 1; }
                if(a.reffHistory.length > b.reffHistory.length) { return -1; }
                return 0;
            }))
            setFilterEnter('up')
        }
    }

    const filterKeyUserList = () => {
        if(filterKey === 'up'){
            setFilterUserSearch(userList.sort(function(a, b){
                return a.userKeys.HUOBI !== null ? -1 : 1;
            }))
            setFilterKey('down')
        }else if(filterKey === 'down'){
            setFilterUserSearch(userList.sort(function(a, b){
                return a.userKeys.HUOBI === null ? -1 : 1;
            }))
            setFilterKey('up')
        }
    }

    useEffect(() => {
        const filteredUser = filterUser(search,userList)
        setFilterUserSearch(filteredUser)
    },[search])

    useEffect(() => {
        getAllUserInfo(userdata.session,userdata.email,setUserList)
    },[rerender])

    const findUserProfit = (email) => {
        getAdminTradeUser(email, setCurrentTrades)
        getUserBalanceForAdmin(email,setBalanceCurrentUser)
    }

    const findProfitinArr = (arr) => {
        const arrRes = [0]
        for(let i = 0; i < arr.length; i++){
            arrRes.push(arrRes[i] + parseFloat(arr[i]['Commision']) + parseFloat(arr[i]['PnL']));   
        }
        return arrRes[arrRes.length-1].toFixed(3)
    }

    const createDeleteUser = (el) => {
        deleteUser(el,userdata.session,userdata.email,rerender,setRerender,setCurrentData,setVision)
    }

    const createCurrentData = (el) => {
        findUserProfit(el.email)
        setCurrentData(el)
    }

    const openModal = () => {
        if(currentData.email !== 'support@bitok.pro'){
            setVision(true)   
        }
    } 

    const arrProfit = (arr) => {
        let bal = 0
        arr.map((el) => { bal += el.percent })
        return bal
    }

    return(
        <div className="setting_container">
            <div className='admin_profile'>
                <h3>Пользователи</h3>
            </div>
            {!currentData ? 
            <div>
                <input className='search_user_statistics_input' value={search} onChange={(event) => setSearch(event.target.value)} placeholder="Search by name ..."/>
                <div style={{
                    textAlign:'end',
                    marginTop:20,
                    marginBottom:20,
                    display: arrSendMail.length !== 0 ? "block" : "none"
                }}><button onClick={() => setSendMail(!sendMail)}>{sendMail ? "Отменить" :"Отправить сообщение"}</button></div>
            </div>
            : null}
            {currentData === '' ?
                !sendMail ?
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th style={{cursor:'pointer'}} onClick={filterEmailUserList}>Email</th>
                            <th style={{cursor:'pointer'}} onClick={() => filterEnterUserList()}>Реффералов</th>
                            <th style={{cursor:'pointer'}} onClick={() => filterDateUserList()}>Дата регистрации</th>
                            <th style={{cursor:'pointer'}} onClick={() => filterKeyUserList()}>Статус API</th>
                            <th style={{cursor:'pointer'}} onClick={() => filterTransactionUserList()}>Количество покупок</th>
                            <th>Подробнее</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(filterUserSearch.length === 0 ? userList : filterUserSearch).map((el,key) => 
                            <tr key={key}>
                                <td><input type="checkbox" checked={arrSendMail.filter(res => res == el.email).length !== 0} onChange={() => handleChooseUser(el.email)}/></td>
                                <td>{el.email}</td>
                                <td>{el.reffHistory.length} шт</td>
                                <td>{timestampConvert(el.date,'main')}</td>
                                <td>{el.userKeys.HUOBI === null ? 
                        <p style={{color: 'red',margin:0}}>Отсутствует</p>: 
                        <p style={{color:'green',margin:0}}>Подключён</p>}</td>
                                <td>{el.purchases.length} шт</td>
                                <td><button className='delete_proxy_button' onClick={() => createCurrentData(el)}>Перейти</button></td>
                            </tr>
                        )}
                    </tbody>
                </table>
                : 
                <div className='panel_send_mess'>
                    <textarea 
                    value={messsage}
                    placeholder="Type your message (accept simple text)"
                    onChange={(event) => setMessage(event.target.value)}></textarea>
                    <br/>
                    <button onClick={() => createSendmail()}>Отправить сообщение</button>
                </div>
            : !step ?
            <div className='page_admin_current_user'>
                <p className='cancel_page_current_user' onClick={() => setCurrentData('')}>Назад</p>
                <div className='page_admin_current_user_header'>
                    <div>
                        <h4>{currentData.email}</h4>
                    </div>
                    <div>
                        {currentData.status}
                    </div>
                </div>
                <div className='page_admin_current_user_info'>
                    <div className='page_admin_current_user_info_item'>
                        <div>Дата регистрации</div>
                        <div>{timestampConvert(currentData.date,'main')}</div>
                    </div>
                    <div className='page_admin_current_user_info_item'>
                        <div>Статус API</div>
                        <div>{currentData.userKeys.HUOBI === null ? 
                        <p style={{color: 'red',margin:0}}>Отсутствует</p>: 
                        <p style={{color:'green',margin:0}}>Подключён</p>}
                        </div>
                    </div>
                    <div className='page_admin_current_user_info_item'>
                        <div>Количество покупок</div>
                        <div>{currentData.purchases.length} шт</div>
                    </div>
                    <div className='page_admin_current_user_info_item'>
                        <div>Баланс HUOBI</div>
                        <div>{balanceCurrentUser.toFixed(3)}$</div>
                    </div>
                    <div className='page_admin_current_user_info_item'>
                        <div>Баланс Bitok</div>
                        <div>{currentData.usdtBalance}$</div>
                    </div>
                    <div className='page_admin_current_user_info_item'>
                        <div>Заработал</div>
                        <div>{findProfitinArr(currentTrades)} $</div>
                    </div>
                    <div className='page_admin_current_user_info_item'>
                        <div>Количество рефералов</div>
                        <div>{currentData.arrReff.length} шт</div>
                    </div>
                    <div className='page_admin_current_user_info_item'>
                        <div>Доход от рефералов</div>
                        <div>{arrProfit(currentData.reffHistory)}$</div>
                    </div>
                </div>
                <div className='page_admin_current_user_panel'>
                    <button className='success' onClick={() => setStep(true)}>Показать список реффералов</button>
                    <button style={{background : currentData.email === 'support@bitok.pro'? 'grey' : ''}} className='danger' onClick={() => openModal()}>Удалить пользователя</button>
                </div>
            </div>: 
            <div>
                <p className='cancel_page_current_user' onClick={() => setStep(false)}>Назад</p>
                
                <table>
                    <thead>
                        <tr>
                            <th>№</th>
                            <th>Email</th>
                            <th>Date register</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentData.arrReff.map((el,key) => 
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td>{el.email}</td>
                                <td>{el.date}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>}
            <ModalAlertUser 
            t={t}
            email={currentData.email}
            createDeleteUser={createDeleteUser}
            vision={vision}
            setVision={setVision}
            text={"Вы действительно хотите удалить пользователя?"}/>
        </div>
    )
}

export default UserStatistics