import React,{useState} from 'react'
import huobiLogo from '../../../assets/svg/huobi_additional.svg'
import storage from '../../../storage/storage.js'
import pass from '../../../assets/svg/Password.svg'
import huobiItem from '../../../assets/svg/huobi_main.svg'
import {NavLink} from "react-router-dom";
import {sendKey,delKeys} from "../../../api/api.js"
import { ToastContainer, toast } from 'react-toastify';
import {amplitudeConfig} from '../../../storage/storage'
import { useTranslation } from 'react-i18next'
import huobi from '../../../assets/imgredesign/huobi.svg'


const ApiSett = (props) => {
  const {t,setRerender,rerender,keys,amplitudeId} = props
  const { i18n } = useTranslation();
  const [statusButt, setStatusButt] = useState(false)
  const [apikey,setApiKey] = useState('')
  const [secretkey,setSecretKey] = useState('')
  const [password,setPass] = useState('')
  const [check, setCheck] = useState(false)
  const notifyError = (data) => toast.error(data);
  const termsHandler = (event) => {setCheck(!check)}
  const userdata = JSON.parse(localStorage.getItem("userData"))

  const createNewKey = () => {
    setStatusButt(true)
    if(apikey.length === 0){notifyError(t("huobiErrorKey"))}
    if(secretkey.length === 0){notifyError(t("huobiErrorSecret"))}
    if(password.length === 0){notifyError(t("incorrectPass"))}
    if(!check){notifyError(t("noTerms"))}
    if(apikey.length !== 0 && secretkey.length !== 0 && check && password.length !== 0){
      sendKey(userdata.email,apikey,secretkey,"HUOBI",password,userdata.session).then(data => {
       // console.log(data.data.data)
        if (data.data.data !== null) {
          setRerender(!rerender)
          amplitudeConfig("CONNECT_HUOBI_API_SUCCESS",{id: amplitudeId.id})
          setApiKey('')
          setSecretKey('')
          setStatusButt(false)
        }else{
          setStatusButt(false)
          notifyError(data.data.error.description)
      }
    })
    setPass('')
    }else{setStatusButt(false)}
  }

  const deleteKey = (key) => {
    delKeys(userdata.email,userdata.session,"111",key,rerender,setRerender)
  }


  return(
    <>
    {/*
      <div className="setting_container">
         <img style={{marginBottom:32}} src={huobiLogo} alt="huobi logo"/>
         <div style={{maxWidth: 400}}>
         {storage.huobiKey.map((el,key) =>
           <div style={{marginBottom:32}} key={key}>
             <p className="description_settings">{el.name}</p>
             <div className="input">
             <img src={pass} alt="-"/>
             <input type={el.type} name={el.nameInput} autoComplete="new-password" value={eval(el.value)} onChange={eval(el.setValue)}/>
             </div>
           </div>)}
           <p style={{margin: 0, marginBottom: 24,display: 'flex',alignItems:'center',color: 'white'}}><input style={{marginRight:7}} value={check} onChange={() => termsHandler()} type="checkbox" />I agree with
           <a className="terms_link" style={{marginLeft: 5}} target="_blank" href={`https://cryppush.com/user_agreements_${i18n.language}.pdf`}>Terms of Service</a></p>
           <button style={{background: statusButt? '#A6A9B1': ''}} className="connect_button" onClick={() => createNewKey()}>{t('connectApi')}</button>
           <br/>
           <NavLink className="link_take_api" to="/instructions">{t('howApi')}</NavLink>
         </div>
      </div>
*/}
      <div style={{ marginTop: 10,paddingTop: 25 }} className="setting_container">
        <img style={{marginBottom:25}} src={huobi}/>
         <p className="active_sett_title">{t('listApi')}</p>
         <table>
             <thead>
               <tr>
                 <th>{t('whatMarket')}</th>
                 <th>API Key</th>
                 <th>{t('dateConn')}</th>
                 <th>{t('status')}</th>
               </tr>
             </thead>
             <tbody>
             {keys.filter(el => el.name === 'HUOBI')[0].value !== null?
             keys.filter(el => el.name === 'HUOBI')[0].value.map((el,key) =>
               <tr key={key}>
               <td>Huobi</td>
               <td>{el.apiKey}</td>
               <td>-</td>
               <td  style={{ color: el.active? "rgba(156, 255, 109, 1)" : "rgba(255, 109, 109, 1)"}}>{el.active? t('connect') : t('unconnect')}</td>
               {<td style={{textAlign: 'center'}}><button className='del_item_api_sett' style={{fontSize:18}} onClick={() => deleteKey(el.apiKey)}>Delete</button></td>}
               </tr>
             ): null}
             </tbody>
         </table>

         <div className="mobile_api_list">

          {keys.filter(el => el.name === 'HUOBI')[0].value !== null?
          keys.filter(el => el.name === 'HUOBI')[0].value.map((el,key) =>
            <div key={key} className="mobile_api_list_item">
              <img src={huobiItem} alt="Huobi"/>
              {/* <div className="date_mobile_list"><p>-</p></div> */}
              <div className="mobile_api_list_container">
                <div><p>API Key</p></div>
                <div><p>{el.apiKey}</p></div>
              </div>
              <div className="mobile_api_list_container">
                <div><p>{t('status')}</p></div>
                <div><p style={{ color: el.active? "rgba(156, 255, 109, 1)" : "rgba(255, 109, 109, 1)"}}>{el.active? t('connect') : t('unconnect')}</p></div>
              </div>
            </div>)
            : null }
         </div>

        <ToastContainer/>
      </div>
      </>
  )
}

export default ApiSett
