import React,{useState} from 'react'
import logo from '../../assets/img/logo-png.png'
import BINANCE from '../../assets/img/binance.png'
import HUOBI from '../../assets/img/huobi.png'
import exit from '../../assets/img/exit.png'
import MarketItem from './MarketItem/MarketItem'
import { useTranslation } from 'react-i18next'
import {sendKey} from '../../api/api.js'
import HuobiForms from '../BinanceLabel/ChangeForms/HuobiForms'
import BinanceSettingsCurrent from '../BinanceLabel/BinanceApiCurrent'

const Modal = (props) => {
  const {content,linkSignal,keys,setRerender, rerender} = props
    const { t } = useTranslation();
    const [close,setClose] = useState(false)
    const [step, setStep] = useState(keys ? keys.filter(el => el.value !== null).length > 0 ? "result" : 'none': 'none')
    const logOut = () => {
        localStorage.removeItem("userData")
        window.location.href = "/"
    }
    const markets = [
      {value: BINANCE,name: 'binance'},
      {value: HUOBI,name: 'huobi'}
    ]

    const cancelSession = () => {
      setClose(true)
      setStep('none')
    }

  return(
    <>
    <div id={linkSignal} className="modal">
    <div style={{maxWidth: content === 'leave' ? 400 : 800}} className="modal-dialog">
    <div className="modal-content">
    {content === 'market' ?
          <>
            <div className="modal-header">
              <img src={logo} alt="model-Logo"/>
              <h3 className="modal-title">{t('menuButt')}</h3>
              <a href="#" className="close" onClick={() => cancelSession()}>×</a>
            </div>
            <div style={{display:'flex', padding:0}} className="modal-body">
              {!keys?
                keys.filter(el => el.value !== null).map((el,key) =>
                <MarketItem
                t={t}
                key={key}
                el={el}
                type={keys.filter(el => el.value !== null).length === 1 ? 'single': 'multiple'}
                setClose={setClose}
                close={close}
                markets={markets}/>)
              :
              <div style={{width: '100%'}}>
              {step === 'none' && keys.filter(el => el.value !== null).length === 0 ?
                <div className="binance-connect">
                  <div className="binance-connect-text">{t("binanceDescription")}</div>
                  <div className="binance-connect-button">
                    <button onClick={() => setStep('register')}><i className="fas fa-cog"></i>{t("settingsButton")}</button>
                  </div>
                </div>
              : null}

                {step === 'register'?
                <div>
                  <HuobiForms
                  t={t}
                  sendKey={sendKey}
                  setStep={setStep}
                  setRerender={setRerender}
                  rerender={rerender}/>
                </div>
                : null}

                  {step === 'result' || keys.filter(el => el.value !== null).length > 0?
                    <div>
                      <BinanceSettingsCurrent
                      apiCurrentdata={keys}
                      setStep={setStep}
                      step={step}
                      t={t}/>
                    </div>
                  :null}
                {keys.filter(el => el.name === 'HUOBI').map((el,key) =>
                  <MarketItem
                  key={key}
                  t={t}
                  type={'single'}
                  setClose={setClose}
                  close={close}
                  el={el}
                  markets={markets}/>)}
              </div>}
            </div>
          </>
    :
    <>
    <div style={{justifyContent: 'flex-start'}} className="modal-header">
      <div style={{width: '100%', textAlign: 'center', justifyContent: 'center'}}>
      <h3 className="modal-title">{t('quitDescription')}</h3>
      </div>
    </div>
    <div style={{justifyContent:'center',width:'100%',display:'flex'}}>
    <img className="sign_leave" style={{width:200}} src={exit} alt="leave"/>
    </div>
    <div className="modal-body-quit">
      <a href="#" className="quitButtonModal">{t('no')}</a>
      <a className="cancelButton" onClick={()=>logOut()}>{t('yes')}</a>
    </div>
    </>}
      </div>
    </div>
  </div>
  </>
  )
}

export default Modal
