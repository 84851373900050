import React from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import Admin from './pages/Admin'
import TradeApp from './pages/TradeApp'
import Instructions from './pages/Instructions'
import Recovery from './pages/Recovery'
import TermsOfUse from './pages/TermsOfUse'
import Auth from './pages/Auth'
import History from "./pages/History";
import Description from "./pages/Description"
import Balance from "./pages/Balance"
import Subscribe from "./pages/Subscribe"
import Settings from "./pages/Settings"
import Terms from "./pages/Documents/Terms"
import Faq from './pages/Faq'
import Partners from './pages/Partners'

export const useRoutes = (infoProps) => {
    if (infoProps.isAuthenticated) {
        return (
            <Switch>
                <Route path="/trade-app" exact>
                    <TradeApp
                    activePlan={infoProps.activePlan}
                    amplitudeId={infoProps.amplitudeId}
                    keys={infoProps.keys}
                    trade={infoProps.trade}
                    loader={infoProps.loader}
                    balance={infoProps.balance}
                    openTrade={infoProps.openTrade}
                    filterTrade={infoProps.filterTrade}
                    tradeType={infoProps.marketCurrent}
                    rerender={infoProps.rerender}
                    setRerender={infoProps.setRerender}/>
                </Route>
                <Route path="/history" exact>
                    <History
                    filterTrade={infoProps.filterTrade}
                    tradeType={infoProps.marketCurrent}/>
                </Route>
                <Route path="/instructions" exact>
                    <Instructions
                     amplitudeId={infoProps.amplitudeId}
                    />
                </Route>
                <Route path="/description" exact>
                    <Description />
                </Route>
                <Route path="/terms-of-use" exact>
                    <TermsOfUse />
                </Route>
                <Route path="/balance" exact>
                    <Balance
                    amplitudeId={infoProps.amplitudeId}
                    balance={infoProps.balance}
                    virtualBalance={infoProps.virtualBalance}/>
                </Route>
                <Route path="/balance/*">
                    <Balance
                    balance={infoProps.balance}
                    virtualBalance={infoProps.virtualBalance}/>
                </Route>
                {/*{infoProps.adminStatus ?*/}
                {/*    <Route path="/subscribes" exact>*/}
                {/*        <Subscribe*/}
                {/*            promoExist={infoProps.promoExist}*/}
                {/*            amplitudeId={infoProps.amplitudeId}*/}
                {/*            activePlan={infoProps.activePlan}*/}
                {/*            rerender={infoProps.rerender}*/}
                {/*            setRerender={infoProps.setRerender}*/}
                {/*            balance={infoProps.virtualBalance}*/}
                {/*            subscribeItems={infoProps.subscribeItems}/>*/}
                {/*    </Route> : null*/}
                {/*}*/}
                {/*<Route path="/subscribes/*">*/}
                {/*    <Subscribe*/}
                {/*    promoExist={infoProps.promoExist}*/}
                {/*    balance={infoProps.virtualBalance}*/}
                {/*    subscribeItems={infoProps.subscribeItems}/>*/}
                {/*</Route>*/}
                <Route path="/settings" exact>
                    <Settings
                    amplitudeId={infoProps.amplitudeId}
                    rerender={infoProps.rerender}
                    setRerender={infoProps.setRerender}
                    balance={infoProps.balance}
                    subscribeItems={infoProps.subscribeItems}
                    keys={infoProps.keys}
                    activePlan={infoProps.activePlan}/>
                </Route>
                <Route path="/faq" exact>
                    <Faq/>
                </Route>
                <Route path="/partners" exact>
                    <Partners
                    rerender={infoProps.rerender}
                    setRerender={infoProps.setRerender}/>
                </Route>
                {infoProps.adminStatus?
                 <Route path="/admin" exact>
                 <Admin
                 proxyInfo={infoProps.proxyInfo}
                 rerenderAdmin={infoProps.rerenderAdmin}
                 setRerenderAdmin={infoProps.setRerenderAdmin}
                 channelCode={infoProps.channelCode}
                 proxy={infoProps.proxy}
                 userCount={infoProps.userCount}/>
                 </Route>: null}
                <Redirect to="/trade-app" />
            </Switch>
        )
    }
    return (
        <Switch>
            <Route path="/" exact>
                <Auth amplitudeId={infoProps.amplitudeId}/>
            </Route>
            <Route path="/register/*" exact>
                <Auth amplitudeId={infoProps.amplitudeId}/>
            </Route>
            <Route path="/demo" exact>
                <Auth amplitudeId={infoProps.amplitudeId}/>
            </Route>
            <Route path="/recovery" exact>
                <Recovery />
            </Route>
            <Route path="/terms" exact>
              <Terms />
            </Route>
            <Redirect to="/" />
        </Switch>
    )
}
