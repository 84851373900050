import {BrowserRouter as Router} from 'react-router-dom';
import {useRoutes} from "./routes";
import SideBarA from "./components/SideBarA";
import {useEffect, useState} from "react";
import './i18n';

import {
    checkKey,
    filterTransaction,
    postTransaction,
    postPositions,
    postBalance,
    postUserCount,
    postProxy,
    getChannel,
    getSubscribe,
    postSubscribeActive,
    getCheckPromo, getUserActiveSubscribe
} from "./api/api.js"
import {makeidForAmplitude} from './storage/storage'
import {connextSocket} from './socket/socket.js'
import LangSwitch from './components/LangSwitch/LangSwitch'
import {toast} from "react-toastify"


const App = () => {

    const notifyError = (data) => toast.error(data);
    const notifySuccess = (data) => toast.success(data);

    let isAuthenticated = false
    const [adminStatus, setAdminStatus] = useState(false)
    const [userCount, setUserCount] = useState(false)
    const [channelCode, setChannelCode] = useState([])
    const [proxy, setProxy] = useState([])
    const [tradeType, setTradeType] = useState('binance')
    const [proxyInfo, setProxyInfo] = useState({usedProxy: 0, totalProxy: 0})
    const [keys, setKeys] = useState([]);
    const [rerender, setRerender] = useState(false)
    const [rerenderAdmin, setRerenderAdmin] = useState(false)
    const [trade, setTrade] = useState([])
    const [filterTrade, setFilterTrade] = useState([])
    const [balance, setBalance] = useState('');
    const [virtualBalance, setVirtualBalance] = useState(0);
    const [activePlan, setActivePlan] = useState([])
    const [openTrade, setOpenTrade] = useState([])
    const [promoExist, setPromoExist] = useState(0)
    const [subscribeItems, setSubscribeItems] = useState([])
    const [loader, setLoader] = useState(true)
    const userdata = JSON.parse(localStorage.getItem("userData"))
    const amplitudeId = JSON.parse(localStorage.getItem("userDataBitquant"))

    if (amplitudeId === null) {
        localStorage.setItem("userDataBitquant", JSON.stringify({
            "id": makeidForAmplitude(36),
        }))
    }

    if (userdata !== null && userdata.email && userdata.session) {
        isAuthenticated = true
        connextSocket(
            userdata.email,
            userdata.session,
            setTrade,
            trade,
            setOpenTrade,
            openTrade,
            setFilterTrade,
            filterTrade,
            setBalance)
    } else {
        isAuthenticated = false
    }


    if (userdata !== null && userdata.email && userdata.session) {
        const getUserSubState = () => JSON.parse(localStorage.getItem("userSubState"))?.value;
        const getUserSubNotified = () => JSON.parse(localStorage.getItem("userSubNotified"))?.value;

        const updateUserSub = (val) => {
            const value = !!val ? val.toString() : null;
            localStorage.setItem("userSubState",
                JSON.stringify(
                    {
                        "value": value
                    }
                )
            )
            return getUserSubState();
        }

        const updateUserSubNotified = (val) => {
            const value = !!val ? val.toString() : null;
            localStorage.setItem("userSubNotified",
                JSON.stringify(
                    {
                        "value": value
                    }
                )
            )
            return getUserSubNotified();
        }

        let userSubState = "";
        try{
            userSubState = getUserSubState()
        }
        catch (e){
            userSubState = updateUserSub();
        }

        let userSubNotified = "";
        try{
            userSubNotified = getUserSubNotified();
        }
        catch (e){
            userSubNotified = updateUserSubNotified();
        }

        if(!userSubState){
            userSubNotified = updateUserSubNotified();
        }

        if(!userSubState){
            userSubNotified = updateUserSubNotified();
        }

        const handlerUpdatedSubInfo = (data) => {
            const sub = data.data.active.type;

            const SUB_EXPIRED = "none";
            const SUB_NEW = "new";

            if (userSubState !== sub) {
                if(!userSubState && (userSubNotified === SUB_EXPIRED || !userSubNotified)){
                    notifySuccess(`You've got ${sub} subscription!`);
                    updateUserSubNotified(SUB_NEW)
                }
                if(userSubState && userSubNotified === SUB_NEW){
                    notifyError(`You have reached the projected profit level. To continue operations, please get in touch with your manager.`);
                    updateUserSubNotified(SUB_EXPIRED)
                }
                updateUserSub(sub)
            }
        };

        getUserActiveSubscribe(userdata.session, userdata.email, handlerUpdatedSubInfo);

    }

    useEffect(() => {
        if (isAuthenticated) {
            checkKey(userdata.email, userdata.session).then(data => {
                const arr = []
                if (data.status === 200 && data.data.data !== null) {
                    for (const [key, value] of Object.entries(data.data.data.keys)) {
                        arr.push({name: key, value: value})
                    }
                } else {
                    arr.push({name: 'HUOBI', value: null})
                }
                setKeys(arr);
            });
        }
    }, [rerender])


    useEffect(() => {

        if (isAuthenticated) {
            filterTransaction(userdata.email, "HUOBI", userdata.session, setFilterTrade)
            postTransaction(userdata.email, "HUOBI", userdata.session, setTrade, setLoader)
            postPositions(userdata.email, "HUOBI", userdata.session, setOpenTrade, setLoader)
            postBalance(userdata.email, "HUOBI", setBalance, userdata.session, userdata.email)
            postBalance(userdata.email, "BITQUANT", setVirtualBalance, userdata.session, userdata.email)
            postUserCount(userdata.email, userdata.session, "HUOBI", setAdminStatus, setUserCount)
            postSubscribeActive(userdata.email, userdata.session, setActivePlan)
            getSubscribe(setSubscribeItems)
            getCheckPromo(userdata.email, setPromoExist)
        }
    }, [rerender])

    useEffect(() => {
        if (isAuthenticated) {
            getChannel(userdata.email, userdata.session, setChannelCode, setAdminStatus)
            postProxy(userdata.email, userdata.session, setProxy, setProxyInfo)
        }
    }, [rerenderAdmin])

    const infoProps = {
        isAuthenticated: isAuthenticated,
        tradeType: tradeType,
        keys: keys,
        rerender: rerender,
        setRerender: setRerender,
        filterTrade: filterTrade,
        trade: trade,
        openTrade: openTrade,
        loader: loader,
        adminStatus: adminStatus,
        userCount: userCount,
        proxy: proxy,
        channelCode: channelCode,
        rerenderAdmin: rerenderAdmin,
        setRerenderAdmin: setRerenderAdmin,
        balance: balance,
        subscribeItems: subscribeItems,
        activePlan: activePlan,
        virtualBalance: virtualBalance,
        amplitudeId: amplitudeId,
        proxyInfo: proxyInfo,
        promoExist: promoExist
    }

    const routes = useRoutes(infoProps)
    return (
        <Router>
            <div className="app-wrapper">
                {infoProps.isAuthenticated && <div className='lang_profile_container'><LangSwitch/></div>}
                {infoProps.isAuthenticated &&
                    <SideBarA
                        activePlan={infoProps.activePlan}
                        tradeType={infoProps.tradeType}
                        balance={infoProps.balance}
                        adminStatus={infoProps.adminStatus}
                        setTradeType={setTradeType}/>}
                <div className="workspace">{routes}</div>
            </div>
        </Router>
    );
}


export default App
