import React,{useState,useEffect} from 'react'
import { postTrading,getTrading } from '../../../api/api.js'
import {toast, ToastContainer} from "react-toastify";

const MarketItem = (props) => {
  const {el,markets,type,close,setClose,t} = props
  const [pass,setPass] = useState('')
  const [status, setStatus] = useState('')
  const [setError] = useState('')
  const userdata = JSON.parse(localStorage.getItem("userData"))
  const notifySuccess = (data) => toast.success(data);
  const notifyError = (data) => toast.error(data)
  const [visible,setVisible] = useState(false)

  //const [] = useState('')
  useEffect(() => {
    getTrading(userdata.email, el.name, userdata.session).then((el) => {
      //console.log(el.data.data);
      setStatus(el.data.data.status);

    })
  }, [visible,status])


  const setTrading = (el) => {
    if (pass.length !== 0) {
      postTrading(userdata.email,el.name, pass, setStatus, setError, userdata.session,notifyError,notifySuccess,t,setPass,setVisible)
    }else{ notifyError("Введите пароль аккаунта") }
  }

  useEffect(() => {
    setVisible(false)
    setClose(false)
  }, [close])

  return(
    <div className={type === 'single'? "MARKET_SINGLE" :"MARKET"} style={{
      justifyContent: 'center',
      textAlign: 'center',
      color: 'white',
      width: type === 'single' ? '' : '50%',
      height: type === 'single' ? 200 : null
    }}>
      {visible?
        <div className="password_confirmation" style={{ marginTop: 20}}>
          <input type="password" name="binance" value={pass} onChange={(event) => setPass(event.target.value)} placeholder={t('typePass')} />
          <button style={{width:174}} onClick={() => setTrading(el)}>{t('acceptButt')}</button>
        </div>
        :
        <div>
        <img style={{width: 100}} src={markets.filter(market => market.name.toUpperCase() === el.name)[0]['value']} alt="Main Logo HUOBI"/>
        <p style={{fontSize: 20}}>{el.exchange}</p>
        <p style={{
          color: status ? "green" : "red",
          fontWeight: 'bold'
        }}>{status ? t("active"): t("unactive")}</p>
        <button
        className="chooseMarket"
        style={{
          pointerEvents: el.value === null ? 'none': 'auto',
          background:  el.value === null ? 'gray': 'white'
        }}
        onClick={() => setVisible(!visible)}>{status ? t('stopTrade') : t('startTrade')} {el.name}
        </button>
        </div>}
      <ToastContainer />
    </div>
  )
}

export default MarketItem
