import React from 'react';
import {connect} from 'react-redux'
import { useTranslation } from 'react-i18next'

const Descriptions = (props) => {
  const { t } = useTranslation();
    return (
        <div className="description-wrap">
            <div className="description-body">
                <div className="description-title">
                    <i className="fas fa-project-diagram"></i>
                    <p>{t('description')}</p>
                </div>
                <div className="description-content">
                    <p style={{margin: 0}}>Если вы осознаете, что в вашей торговле не хватает внимательного помощника,
                     или хотите делегировать часть рутинной работы - обратите внимание на торговых ботов,
                      которые при правильной настройке отлично справляются со своей работой.</p>
                    <br/>

                    <p style={{margin: 0}}>Криптовалютные биржи отличаются повышенной волатильностью.
                     Курсы криптовалют на них меняются быстро и достаточно непредсказуемо.</p>
                     <br/>

                     <p style={{margin: 0}}>Поэтому среди трейдеров большой популярностью пользуются такие технические инструменты, как торговые боты.</p>
                     <br/>

                     <p style={{margin: 0}}>Боты помогают 24/7 следить за котировками, избегать «сюрпризов» в виде маржин-коллов,
                      закрывать сделки быстрее и эффективнее из-за подключения через API.</p>
                      <br/>

                      <h3 style={{margin: 0}}>Что такое торговые боты?</h3>
                      <br/>

                      <p style={{margin: 0}}>Торговый бот – программное обеспечение со специальными алгоритмами, которое анализирует данные
                       криптовалютных бирж и предоставляет дополнительные инструменты для более эффективной торговли.</p>

                       <p style={{margin: 0}}>Боты были созданы, чтобы автоматизировать и облегчить работу трейдеров: не нужно постоянно сидеть
                        перед монитором, отслеживая изменения котировок – можно просто задать параметры боту, который купит или продаст за вас валюту.</p>
                        <br/>

                        <p style={{margin: 0}}>К тому же боты позволяют торговать на нескольких биржах и несколькими валютными парами сразу.
                        Что говорить о превосходстве бота в скорости реакции – программа быстро и с высокой точностью находит
                         сигналы и выставляет ордера, считает PNL и многое другое.</p>
                         <br/>

                         <p style={{margin: 0}}><b>BitQuant</b> — автоматизированный крипто-трейдинговый робот, в основу которого легла система улучшенных
                          алгоритмов пересчёта мат.разницы между положительными сделками, отрицательными, а так же самим балансом депозита!</p>
                          <br/>

                          <p style={{margin: 0}}>Регистрация на платформе проходит быстро, уже через 5 минут можно начать работу.</p>

                          <p style={{margin: 0}}>Благодаря высокочастотному трейдингу,
                            бот не только позволяет хорошо зарабатывать на дистанции, но и диверсифицировать риски в случае высокой волатильности.</p>

                            <p style={{margin: 0}}>BitQuant также имеет пробный период использования торговых стратегий,
                             чтобы пользователи могли сначала попрактиковаться в подключении бесплатной версии.
                              Это отличный способ понять, как работает платформа без каких-либо предоплат.</p>
                              <br/>
                              <p style={{margin: 0}}>У BitQuant достаточно простой и приятный интерфейс, не займет много времени, чтобы с ним разобраться.</p>
                              <br/>
                              <p style={{margin: 0}}>Все операции происходят через API биржи, поэтому у платформы нет никакого доступа к вашим деньгам.
                               Новые пользователи могут попробовать платформу бесплатно в течение 14 дней (кредитная карточка не нужна).</p>
                               <br/>
                               <p style={{margin: 0}}>Торговля происходит через веб-версию нашего сервиса.</p>

                </div>
            </div>
        </div>
    );
}
const mapDispatchToProps = {
    //createPost, showAlert
}

const mapStateToProps = state => ({
    //balance: state.binary.balance,
})

export default connect(mapStateToProps, mapDispatchToProps)(Descriptions)
