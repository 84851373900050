import React, { useEffect, useState } from 'react'
import Title from '../components/Title/Title'
import BalanceItem from '../components/Balance/BalanceItem/BalanceItem'
//import kkrx from '../assets/svg/currency/krrx.svg'
import usdt from '../assets/svg/currency/USDT.svg'
import { useTranslation } from 'react-i18next'
import ModalSuccess from '../components/Modal/ModalSuccess/ModalSuccess'
import ModalAlert from '../components/Modal/ModalAlert/ModalAlert'
import { useHistory } from "react-router-dom";
import { postNewMerchant } from '../api/api.js'
import { amplitudeConfig } from '../storage/storage'
import close from '../assets/imgredesign/close.svg'
import { sendOrderByCrypto } from '../api/api.js'
import QRCode from 'qrcode.react';

const Balance = (props) => {
  const { balance, virtualBalance, amplitudeId } = props
  const history = useHistory();
  const { t } = useTranslation();
  const [vision, setVision] = useState(false)
  const [visionCancel, setVisionCancel] = useState(false)
  const [visibleDeposit, setVisibleDeposit] = useState(false)
  const userdata = JSON.parse(localStorage.getItem("userData"))
  const [amount, setAmount] = useState("")
  const [payInfo, setPayInfo] = useState(0)
  const [stepPay, setStepPay] = useState(0)
  const [loading, setLoading] = useState(false)
  //useEffect(() => {setTimeout(amplitudeConfig('WALLET_VIEW',{user_id: amplitudeId.id}), 1000)},[])

  const createOrder = () => {
    if (parseInt(amount) >= 10) {
      setLoading(true)
      setPayInfo(1)
      sendOrderByCrypto(parseInt(amount), userdata.email, setPayInfo, setStepPay, setLoading)
    }
  }

  const preventMinus = (e) => {
    if (e.code === 'Minus' || e.key === 'e') {
      e.preventDefault();
    }
  };

  const closeModal = () => {
    setVision(false)
    history.push("/balance");
  }

  const closePaymentModal = () => {
    setVision(false)
    setStepPay(0)
    setVisibleDeposit(false)
    setPayInfo({ pay_address: "", pay_amount: "", payment_status: "", order_id: "" })
  }

  return (
    <div>
      {visibleDeposit ?
        <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }} className='template_close_container'>
          <div style={{ padding: 18, maxWidth: 415 }} className='container_template_close_modal'>
            <p className='hover_container_template_close_modal'>{t('depHover')}</p>
            <div className='subcontainer_attention_needed'>
              <div className='container_template_close_modal_item'>
                <div style={{ display: "block" }} className='container_template_close_modal_subitem'>
                  <div style={{ textAlign: 'right', width: '100%' }}>
                    <img onClick={() => setVisibleDeposit(false)} style={{ cursor: "pointer" }} src={close} alt="-" />
                  </div>
                  {!stepPay ?
                    <div>
                      <p className='modal_api_description_deposit'>{t("depDescription")}<br /><b>USDT</b> TRC20</p>
                      <input
                        min="0"
                        style={{ margin: 0 }}
                        onChange={(event) => setAmount(event.target.value)}
                        onKeyPress={preventMinus}
                        className="modal_api_input_deposit"
                        type="number" />
                      {parseInt(amount) < 10 ?
                        <p style={{ margin: 0 }} className='warning'>{t('minMerchamoung')}</p>
                        : null}
                      <div style={{ textAlign: 'center', marginTop: 20 }}>
                        <button
                          onClick={() => createOrder()}
                          className='modal_api_button_deposit'
                          disabled={loading}
                        >
                          {t('depButt')}
                          {loading ? <div className="fa-1x"><i className="fas fa-spinner fa-spin"></i></div> : ""}
                        </button>
                      </div>
                    </div>
                    :
                    <div style={
                      {
                        display: "flex",
                        flexDirection: "column"
                      }
                    }>
                      <p style={{ color: "white" }} className='deposit-modal-hover'>{t("merchHover")}</p>
                      <p style={{ color: "white" }} className='cont-addres-send'>{payInfo.pay_address}</p>
                      <QRCode className='qrCode' value={payInfo.pay_address} />
                      <p style={{ color: "white" }}>{t("needDep")} {amount} USDT</p>
                      <p style={{ color: 'white' }} className='status-text-deposit'>{t("statusOrderMerch")} <p className='warning-deposit'>{t('waitOrder')}</p></p>
                      <p style={{ color: "white", fontSize: 19 }} className='text-id_deposit-saving'>{t("mainMerchStart")} {payInfo.order_id} {t('mainMerchEnd')}</p>
                      <p className='attention-mess-deposit'>{t('merchImportant')}</p>
                      <div style={{ textAlign: 'center', marginTop: 20 }}>
                        <button
                          onClick={() => closePaymentModal()}
                          className='modal_api_button_deposit'
                        >
                          {t('merchsuccesclose')}
                        </button>
                      </div>
                    </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
        : null}
      <div className="trade-data">
        <Title title={t('balance')} description={t('balanceDescription')} balance={true} />
        <ModalSuccess title={"Status success"} t={t} vision={vision} setVision={setVision} closeAll={closeModal} />
        <ModalAlert title={"Status failed"} t={t} vision={visionCancel} setVision={setVisionCancel} alertPayBool={true} alertPayUrl='/balance' />
        <div className='balance_information_container'>
          <div className='balance_information_container_item'>
            <p>{t("attention_balance_first")}</p>
          </div>
          <div className='balance_information_container_item'>
            <p>{t("attention_balance_second")}</p>
          </div>
          <div className='balance_information_container_item'>
            <p>{t("attention_balance_third")}</p>
          </div>
        </div>
        <div className="balance_container">
          <BalanceItem
            amplitudeId={amplitudeId}
            visibleDeposit={visibleDeposit}
            setVisibleDeposit={setVisibleDeposit}
            amplitudeConfig={amplitudeConfig}
            title="usdt"
            currentPrice={virtualBalance && virtualBalance > 0 ? virtualBalance.toFixed(3) : 0}
            t={t}
            additionalCurrency="trc20"
            currency={usdt} />
          { /* <BalanceItem
      t={t}
      title="krrx"
      stack='1000'
      currency={kkrx}/>*/}
        </div>
      </div>
    </div >
  )
}

export default Balance
