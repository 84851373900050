import React,{useState,useEffect} from 'react';
import {connect} from 'react-redux'
import Login from "../components/Login";
import Registration from "../components/Registration";
import LangSwitch from "../components/LangSwitch/LangSwitch"
import amplitude from 'amplitude-js';
import backgroundCry from '../assets/crypush/gif/CRYPUSH_BG_1920x1080.mp4'
import backgroundCryTablet from '../assets/crypush/gif/CRYPUSH_BG_768x1024.mp4'
import backgroundCryMobile from '../assets/crypush/gif/CRYPUSH_BG_320x524.mp4'

const Auth = (props) => {
    const {amplitudeId} = props
    const [currentLinkReff,serCurrentLinkReff] = useState(false)
    const userRefCheck = JSON.parse(localStorage.getItem("userReffExist"))
    useEffect(() => {
        if(userRefCheck){serCurrentLinkReff(userRefCheck.reff)}
    },[])
    return (
    <div className="auth-wrap">
        <div className='video_container'>
        <video 
            className="video-1920 background-video" 
            playsInline muted loop autoPlay src={backgroundCry}/>
         <video 
            className="video-1024 background-video" 
            playsInline muted loop autoPlay src={backgroundCryTablet}/>
         <video 
            className="video-320 background-video" 
            playsInline muted loop autoPlay src={backgroundCryMobile}/>
        </div>
        <LangSwitch type="register"/>
    {props.isLogin?<Login serCurrentLinkReff={serCurrentLinkReff}/>:<Registration currentLinkReff={currentLinkReff} amplitude={amplitude} amplitudeId={amplitudeId}/>}</div>);
}
const mapDispatchToProps = {
    //createPost, showAlert
}

const mapStateToProps = state => ({
    //balance: state.binary.balance,
    isLogin: state.uiApi.isLogin
})

export default connect(mapStateToProps, mapDispatchToProps)(Auth)
