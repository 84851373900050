
import {Box, Button, Divider, Grid, MenuItem, TextField, Typography} from "@mui/material";
import {useFormik} from "formik";
import {toast} from "react-toastify";
import {getSubscribe, sendRegister, getAllUserInfo} from "../../api/api";
import React, {useEffect, useState} from "react";
import * as yup from 'yup';


const BatchCreator = (props) => {
    const {amplitude, amplitudeId, currentLinkReff} = props

    const [subscribesAvailable, setSubscribesAvailable] = useState([{name: "Advanced"}]);
    useEffect(() => {
        getSubscribe(setSubscribesAvailable);
    }, [setSubscribesAvailable])

    const renderOptions = () => subscribesAvailable.map(val => (
            <MenuItem key={val.name} name={fieldsId[2]} value={val.name}>
                {val.name}
            </MenuItem>
        )
    )


    //+-?!._=
    const alphabet = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

    const urlParams = new URLSearchParams(window.location.search);
    const cid = urlParams.get('cid');

    const generatePassword = (length = 0) =>{
        if(length < 8){
            length = 8;
        }
        if(length > 20){
            length = 20;
        }
        let res = "";
        while(length >= 0){
            res += Array.from(alphabet)
                .map(value => ({value, sort:Math.random()}))
                .sort((a, b) => a.sort - b.sort)
                .map(({value}) => value)[Math.floor(Math.random() * alphabet.length)];
            length--;
        }
        return res;
    }

    const notifySuccess = (data) => toast.success(data);
    const notifyError = (data) => toast.error(data);

    const clientsInitial = {
        data:[
            {
                email: "x",
                password: generatePassword(10),
                strategy: subscribesAvailable[0].name
            }
        ]

    };

    const fieldsId = Object.keys(clientsInitial);

    const clientsValidationSchema = yup.object({
        data: yup.array().of(
            yup.object({
                    email: yup.string(),
                    password: yup.string().required().min(8, "The password is too short")
                }
            )
        )
    });

    const formikHook = useFormik(
        {
            initialValues: clientsInitial,
            validationSchema: clientsValidationSchema,
            onSubmit: (values) => {
                const { data } = values;
                for(let i = 0; i < data.length; i++){
                    const client = data[i]
                    if(client.email !== clientsInitial.data[0].email){
                        const {email, password, strategy} = client;
                        sendRegister(
                            cid,
                            {name: strategy, email: email, password: password},
                            '',
                            notifySuccess,
                            notifyError,
                            (val => {console.log(val)}),
                            "batch",
                            currentLinkReff);
                    }
                }
            }

        }
    );

    useEffect(()=>{
        const {data} = formikHook.values;
        if(data?.[data?.length - 1]?.email?.length > 1){
            formikHook.setFieldValue(fieldsId[0], [...data, clientsInitial.data[0]]);
        }
    }, [formikHook.values.data?.[formikHook.values.data?.length - 1]])

    const handleClientsUpdate = (event, index, fieldKey) => {
        const newClients = JSON.parse(JSON.stringify(formikHook.values.data))
        newClients[index][fieldKey] = event.target.value
        formikHook.setFieldValue(fieldsId[0], newClients);
    }

    const manageClients = () => {
        return formikHook.values.data.map((obj, index) => {
            return(
                <Box key={index} sx={{display: "flex", flexDirection: "row", gap: "8px"}}>
                    <TextField
                        id={index + "client"}
                        label="E-mail"
                        required
                        fullWidth variant="filled"
                        value={formikHook.values.data?.[index]?.email}
                        error={formikHook.touched.data?.[index].email && !!formikHook.errors.data?.[index]?.email}
                        helperText={formikHook.touched.data?.[index]?.email && formikHook.errors.data?.[index]?.email}
                        onChange={(event) => handleClientsUpdate(event, index, "email")}
                    />
                    <TextField
                        id={index + "password"}
                        label="Password"
                        required
                        fullWidth variant="filled"
                        value={formikHook.values.data?.[index]?.password}
                        error={formikHook.touched.data?.[index].password && !!formikHook.errors.data?.[index]?.password}
                        helperText={formikHook.touched.data?.[index]?.password && formikHook.errors.data?.[index]?.password}
                        onChange={(event) => handleClientsUpdate(event, index, "password")}
                    />
                    <TextField required
                               fullWidth
                               select
                               variant="filled"
                               id={index + "strategy"}
                               name="strategy"
                               label="Strategy"
                               error={formikHook.touched.data?.[index].strategy && !!formikHook.errors.data?.[index].strategy}
                               onChange={(event) => handleClientsUpdate(event, index, "strategy")}
                               value={formikHook.values.data?.[index]?.strategy}
                    >
                        {renderOptions()}
                    </TextField>
                </Box>
            )
        });
    }

    return (
        <Box className="creationBox"
             component="form"
             onSubmit={formikHook.handleSubmit}>
            <Box>
                {manageClients()}
            </Box>
            <Button
                type="submit"
                sx={{margin: "10px 0 0 0"}} >
                <Typography sx={{padding:"5px 10px 0 10px"}}>
                    Create users
                </Typography>
            </Button>
        </Box>
    )
}

export default BatchCreator;