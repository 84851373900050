import React,{useState} from 'react'
import accept from '../../../assets/svg/accept.svg'

const ModalDeposit = (props) => {
  const {title,closeAll,t,vision,setVision} = props
  return(
    <div style={{display: vision ? 'flex' :'none', zIndex: 2}} className="Modal">
    <div className="Modal_Body body_mobile_container">
    <div className="body_modal_relative">
    <img src={accept} alt="accept"/>
    <p className="alert_modal_title">{title}</p>
    <div className="panel_alert_modal">
    <button style={{margin:0}} onClick={() => closeAll()}>{t('continue')}</button>
    </div>
    </div>
    </div>
    </div>
  )
}

export default ModalDeposit
