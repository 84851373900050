import React from 'react'

const Footer = () => {
  return(
    <footer>
      <p> © bitok.pro</p>
    </footer>
  )
}

export default Footer
