import amplitude from 'amplitude-js';
import tradeImg from "../assets/imgredesign/menu/trade.svg"
import balanceImg from "../assets/imgredesign/menu/balance.svg"
import instructionImg from "../assets/imgredesign/menu/instructions.svg"
import settingImg from "../assets/imgredesign/menu/settings.svg"
import subscribeImg from "../assets/imgredesign/menu/subscribe.svg"
import faqImg from "../assets/imgredesign/menu/faq.svg"
import affilateImg from "../assets/imgredesign/menu/key.svg"

const storage = {
  link_main:{
  main:[
  {
    title: "trade",
    class: "fas fa-chart-pie",
    link: '/trade-app',
    imgs: tradeImg,
    active: true
  },
  // {
  //   title: 'subscribe',
  //   class: 'fas fa-ticket-alt',
  //   link: '/subscribes',
  //   imgs: subscribeImg,
  //   active: true
  // },
  // {
  //   title: 'balance',
  //   class: 'fas fa-wallet',
  //   link: '/balance',
  //   imgs: balanceImg,
  //   active: true
  // },
  // {
  //   title: 'instruction',
  //   class: 'fas fa-book',
  //   link: '/instructions',
  //   imgs: instructionImg,
  //   active: true
  // },
  {
    title: 'setting',
    class: 'fas fa-cog',
    link: '/settings',
    imgs: settingImg,
    active: true
  }
  // {
  //   title: 'FAQ',
  //   class: 'fas fa-graduation-cap',
  //   link: '/faq',
  //   imgs: instructionImg,
  //   active: false
  // },
  // {
  //   title: 'afilliate',
  //   class: 'fas fa-money-check',
  //   link: '/partners',
  //   imgs: affilateImg,
  //   active: false
  // },
],
demo:[
  {
    title: "trade",
    class: "fas fa-chart-pie",
    link: '/trade-app',
    imgs: tradeImg,
    active: true
  },
  {
    title: 'subscribe',
    class: 'fas fa-ticket-alt',
    link: '/subscribes',
    imgs: subscribeImg,
    active: true
  },
  {
    title: 'balance',
    class: 'fas fa-wallet',
    link: '/balance',
    imgs: balanceImg,
    active: true
  },
  {
    title: 'instruction',
    class: 'fas fa-book',
    link: '/instructions',
    imgs: instructionImg,
    active: true
  },
  {
    title: 'FAQ',
    class: 'fas fa-graduation-cap',
    link: '/faq',
    imgs: faqImg,
    active: true
  },
],
alt: [
  // {
  //   title: 'description',
  //   class: 'fas fa-cog',
  //   link: '/description',
  //   active: true
  // },
//  {
//    title: 'terms',
//    class: 'fas fa-award',
//    link: '/terms-of-use'
//  }
]
},
  form_recovery_start: [
      {
        name: 'email_recover',
        holder: 'typeEmail',
        type: 'email',
        ico: 'far fa-envelope',
        value: 'form.email_recover'
      },
      /*
      {
        name: 'phone',
        holder: 'phone',
        type: 'phone',
        ico: 'far fa-user',
        value: 'form.phone'
      },
      */
      {
        name: 'email',
        holder: 'typePass',
        type: 'text',
        ico: 'far fa-envelope',
        value: 'form.email'
      }
  ],
  form_recovery_end: [
    {
      name: 'password',
      holder: 'typeNew',
      type: 'password',
      ico: 'fa fa-lock',
      value: 'form.password'
    },
    {
      name: 'tempPassword',
      holder: 'typeRepNew',
      type: 'password',
      ico: 'fa fa-lock',
      value: 'form.tempPassword'
    }
  ],
  form_register:[
      {
      name: 'name',
      holder: 'name',
      type: 'text',
      ico: 'far fa-user',
      value: 'form.name'
    },
    /*
    {
      name: 'phone',
      holder: 'phone',
      type: 'phone',
      ico: 'far fa-user',
      value: 'form.phone'
    },
    */
    {
      name: 'email',
      holder: 'email',
      type: 'email',
      ico: 'far fa-envelope',
      value: 'form.email'
    },
    {
      name: 'code',
      holder: 'typeCode',
      type: 'text',
      ico: ' ',
      value: 'form.code'
  }
  ],
  form_register_end: [
        {
          name: 'password',
          holder: 'pass',
          type: 'password',
          ico: 'fa fa-lock',
          value: 'form.password'
        },
        {
          name: 'repassword',
          holder: 'repeat',
          type: 'password',
          ico: 'fa fa-lock',
          value: 'form.repassword'
      },
  ],
  form_login: [
    {
      name: 'email',
      holder: 'email',
      type: 'email',
      ico: 'far fa-envelope',
      value: 'form.email'
    },
    {
      name:'password',
      holder: 'pass',
      type: 'password',
      ico: 'fa fa-lock',
      value: 'form.password'
    }
  ],
  open_trades: ['symbol','count'],
  history:['symbol','count','typeSdelki','leverage','time','res'],
   //'type',
  info_chart: [
        {
          title: "dealCount",
          value: "test_arr.length-1",
          status: ''
        },
  //      {
  //        title: "profit",
  //        value: "profit.length",
  //        status: 'positive'
  //      },
  //      {
  //        title: "unprofit",
  //        value: "closed_arr.filter(el => el < 0).length",
  //        status: 'negative'
  //      },
  //      {
  //        title: "average",
  //        value: "sum",
  //        //value: "0",
  //        status: 'positive'
  //      },
  //      {
  //        title: "unaverage",
  //        value: "minus",
          //value: "0",
  //        status: 'negative'
  //      },
  //      {
  //        title: "win",
  //        value: "0",
  //        status: "positive"
    //    },
  //      {
    //      title: "up",
    //      value: "0",
    //      status: ''
  //  }
  ],
  signals: [
    {
      name: 'Demo',
      price: 'free',
      status: 'active'
    },
    {
    name: 'Название сигнала',
    price: 19,
    status: 'unactive',
    },
    {
    name: 'Название сигнала',
    price: 50,
    status: 'unactive',
    }
  ],
  password_change_sett: [
    {
      name:'oldPass',
      value: 'form.old',
      nameInput:'old',
      vision: "oldVis",
      setVision: "() => setOldVis(!oldVis)"
    },
    {
      name:'newPass',
      value: 'form.new',
      nameInput:'new',
      vision: "newVis",
      setVision: "() => setNewVis(!newVis)"
    },
    {
      name:'repeatpass',
      value: 'form.repeatNew',
      nameInput:'repeatNew',
      vision: "repeatNewVis",
      setVision: "() => setRepeatNewVis(!repeatNewVis)"
    }
  ],
  huobiKey: [
    {
      name:'Huobi API Key',
      value: 'apikey',
      setValue: "(event)=>setApiKey(event.target.value)",
      nameInput:'huobiApi',
      type: 'text'
    },
    {
      name:'Huobi Secret Key',
      value: 'secretkey',
      setValue: "(event)=>setSecretKey(event.target.value)",
      nameInput:'secretApi',
      type: 'text'
    },
    {
      name:'Your password',
      value: 'password',
      setValue: "(event)=>setPass(event.target.value)",
      nameInput:'secretApi',
      type: 'password'
    }
  ]
}

export const BalancePow = (balance) => {
  if (parseInt(balance) - balance === 0) {
    return balance
  }else {
    try {
    let split = balance.split(".")
    return split[0] + "." + split[1].slice(0,2)
  }catch (e) {
    return 0
   }
  }
}

  export const timestampConvert = (time,type) => {
//    let dateTime = new Date(time * 1000);
    let date = new Date(time);
    let hours = date.getHours().toString().length === 1 ? `0${date.getHours().toString()}` : date.getHours();
    let minutes = "0" + date.getMinutes();
    let seconds = "0" + date.getSeconds();
    let year = date.getFullYear();
    let month = date.getMonth().toString().length === 1 ?  `0${(date.getMonth()+1).toString()}` : date.getMonth()+1;
    let day = date.getDate().toString().length === 1 ?  `0${date.getDate().toString()}` : date.getDate();
    //day + '.' + month + "." + year + ' ' +
    let allFormatedTime = day + '.' + month + "." + year + ' ' + hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
    let formattedTime =  hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
    if (type === 'main') {
      return allFormatedTime
    }else {
      return formattedTime
    }
  }

export const ParserDateExpiried = (date, symbol=".") => {
    const dateParse = new Date(date)
      return `${dateParse.getDate()}${symbol}${dateParse.getMonth()+1}${symbol}${dateParse.getFullYear()}`
}

export const palitreArr = [
  {
    colorHeadP:"rgba(252, 100, 175, 1)",
    colorHead:"linear-gradient(190deg, #252D47 -8.64%, #FC64AF 108.78%)",
    period: 3,
    name:"Explorer"
  },
  {
    colorHeadP:"rgba(109, 185, 255, 1)",
    colorHead:"rgba(109, 185, 255, 0.3)",
    period: 6,
    name:"Adventurer"
  },
  {
    colorHeadP:"rgba(234, 106, 55, 1)",
    colorHead:"rgba(255, 162, 109, 0.3)",
    period: 12,
    name:"Hero"
  },
  {
    colorHeadP:"rgba(255, 162, 109, 1)",
    colorHead:"rgba(255, 162, 109, 0.3)",
    period: 12,
    name:"Trial"
  },
  {
    colorHeadP:"rgba(130, 109, 255, 1)",
    colorHead:"rgba(130, 109, 255, 0.3)",
    period: 1,
    name:"Pioner"
  },
  {
    colorHeadP:"rgba(255, 223, 109, 1)",
    colorHead:"rgba(255, 223, 109, 0.3)",
    period: 1,
    name:"Pioner"
  },
]

export const howMuchTimeLeft = (dateCurrentActive) => {
  const oneDay = 24 * 60 * 60 * 1000;
  const parseDate = new Date(dateCurrentActive);
  const firstDate = new Date(new Date().getFullYear(), new Date().getMonth()+1,  new Date().getDate());
  const secondDate = new Date(parseDate.getFullYear(), parseDate.getMonth()+1, parseDate.getDate());
  const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
  return diffDays
  }

export const amplitudeConfig = (eventName,eventProperty) => {
//  amplitude.getInstance().init("fb8dad7c50576d0856c64865dff1c647") 
// amplitude.getInstance().logEvent(eventName, eventProperty) 
}

export const makeidForAmplitude = (length) => {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
 charactersLength));
   }
   return result;
}

export default storage
