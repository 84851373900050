import React, {useState} from 'react'
import Footer from '../components/Footer/Footer'
import Title from '../components/Title/Title'
import {useTranslation} from 'react-i18next'
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import {ToastContainer, toast} from 'react-toastify';
import Statistics from '../components/Admin/Statistics';
import Proxy from '../components/Admin/Proxy';
import Channels from '../components/Admin/Channels';
import Promo from '../components/Admin/Promo'
import UserStatistics from '../components/Admin/UserStatistics'
import Bots from '../components/Admin/Bots';
import BatchCreator from '../components/Admin/BatchCreator';
import SubConfig from "../components/Admin/SubConfig";

const Admin = (props) => {
    const {userCount, proxy, channelCode, rerenderAdmin, setRerenderAdmin, proxyInfo} = props
    const {t} = useTranslation();
    const [value, setValue] = useState('1')
    const notifyError = (data) => toast.error(data);
    const notifyInf = (data) => toast.info(data);
    const handleChange = (event, newValue) => {
        setValue(newValue)
    };

    return (
        <div className="trade-data">
            <Title title={t('admin')} description={t('adminDesc')}/>
            <TabContext value={value}>
                <TabList
                    TabIndicatorProps={{
                        style: {display: 'none'}
                    }}
                    onChange={handleChange} aria-label="setting">
                    <Tab label={t('statistics')} value="1"/>
                    <Tab label={t('proxy')} value="2"/>
                    {/* <Tab label={t('channel')} value="3"/> */}
                    <Tab label={t('promo')} value="4"/>
                    <Tab label={t('users')} value="5"/>
                    <Tab label={t('bots')} value="6"/>
                    <Tab label={t('Strategy Config')} value="7"/>
                    <Tab label={t('Register user batch')} value="8"/>
                </TabList>
                <TabPanel value="1">
                    <Statistics t={t} userCount={userCount}/>
                </TabPanel>
                <TabPanel value="2">
                    <Proxy
                        t={t}
                        proxyInfo={proxyInfo}
                        rerenderAdmin={rerenderAdmin}
                        setRerenderAdmin={setRerenderAdmin}
                        proxy={proxy}/>
                </TabPanel>
                <TabPanel value="3">
                    <Channels
                        t={t}
                        channelCode={channelCode}
                        rerenderAdmin={rerenderAdmin}
                        setRerenderAdmin={setRerenderAdmin}
                        notifyInf={notifyInf}
                        notifyError={notifyError}/>
                </TabPanel>
                <TabPanel value="4">
                    <Promo/>
                </TabPanel>
                <TabPanel value="5">
                    <UserStatistics/>
                </TabPanel>
                <TabPanel value='6'>
                    <Bots/>
                </TabPanel>
                <TabPanel value='7'>
                    <SubConfig/>
                </TabPanel>
                <TabPanel value='8'>
                    <BatchCreator/>
                </TabPanel>
            </TabContext>
            <ToastContainer/>
            <Footer/>
        </div>)
}

export default Admin