import {createTheme} from "@mui/material";


export const muiTheme = createTheme(
    {
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        width: "unset",
                        backgroundColor: '#2F4A79',
                        color:'white',
                        fontStyle: 'large'
                    }

                }
            },
            /*MuiTextField:{
                styleOverrides: {
                    root: {
                        width: "unset",
                        color:'white',
                    }
                }
            }*/

        }
    }
);