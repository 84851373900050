import React from 'react'
import logo from '../../assets/img/logo-png2.png'
import LangSwitch from '../../components/LangSwitch/LangSwitch'
import { useTranslation } from 'react-i18next'
import {NavLink} from "react-router-dom";

const Terms = () => {
  const { i18n } = useTranslation();

  return(
    <div style={{color:'white'}} className="termsContainer">
    <LangSwitch type="register"/>
    <div className="termsHeaderContainer">
    <h2 style={{fontSize: 40,width:  '85%',color:'white',lineHeight:'45px'}}>Terms & Conditions (20.02.2023)</h2>
      <NavLink style={{textDecoration: 'none'}} to='/'>
        <button className="buttonBackToBit">
          <p>Вернутся на главную</p>
        </button>
      </NavLink>
    </div>
    {i18n.language === 'en' ?
    <div style={{color:'white'}}>
<p>Welcome to Bitok (hereafter also “Bitok” or “we/our”).
We offer online software as a service (SaaS) through our website https://bitok.pro/ (the “Website”).
Our software (“Software”) enables you to trade and invest in cryptocurrencies by means of an automatic crypto trader bot – of which (solely) you control and configure the settings.
These terms and conditions (the “Terms”) apply to the relationship between Bitok and you for any use of the Website and the Software that Bitok offers.
You cannot use the Software without accepting these Terms.</p>
<p>
1. Definitions
Bitok a limited liability company incorporated under the laws of the. Bitok is also referred to as “we”.
User(s): individual private person(s) or legal entity using the Website or Software. Users are also referred to as “you”.
Software: the software Bitok was developed to enable Users to trade crypto currencies on the crypto market, in the form of a crypto trader bot. The Software is available through the Website https://bitok.pro/
Strategies: information provided by internal advisors that scan the crypto market and provide the Users with trading tactics. Users have the option to configure the Software as such that it will automatically buy and sell currencies on the basis of information of Strategies.
</p>
<p>
2. Registration and personal account
If you want to use our Website and Software, go to https://bitok.pro/. You will need to register and create a personal account in order to use the Website and Software.
You must protect the login details of your account and keep your password strictly secret. We will assume that all actions taken from your account are done by you or under your supervision.
You are entirely responsible for maintaining the confidentiality of your password and account. Furthermore, you are entirely responsible for any and all activities that occur under your account. You agree to notify Bitok immediately of any unauthorized use of your account or any other breach of security. YOU WILL NOT HOLD BITQUANT LIABLE FOR ANY LOSS THAT YOU MAY INCUR AS A RESULT OF ANY MISUSE OF YOUR LOGIN INFORMATION, WHETHER WITH OR WITHOUT YOUR KNOWLEDGE. You acknowledge that you may be held liable for losses incurred by Bitok and/or another party due to someone else using your account or password. You may not use anyone else’s account at any time, without the permission of the account holder.
You agree to provide up-to-date, complete and accurate information on your account. You agree to promptly update your personal account when necessary, so that we can contact you if needed.
</p>

<p>
3. Disclaimer
BITQUANT PROVIDES THE SOFTWARE. BITQUANT DOES NOT PROVIDE FINANCIAL, INVESTMENT, LEGAL, TAX OR ANY OTHER PROFESSIONAL ADVICE. BITQUANT IS NOT A BROKER, FINANCIAL ADVISOR, INVESTMENT ADVISOR, PORTFOLIO MANAGER OR TAX ADVISOR. YOU ACKNOWLEDGE AND AGREE THAT BITQUANT IS NOT RESPONSIBLE FOR YOUR USE OF ANY INFORMATION THAT YOU OBTAIN ON THE SOFTWARE. YOUR DECISIONS MADE IN RELIANCE ON THE PRODUCTS OR SERVICES OR YOUR INTERPRETATIONS OF THE DATA ARE YOUR OWN FOR WHICH YOU HAVE FULL RESPONSIBILITY. YOU EXPRESSLY AGREE THAT YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK.
YOU ACKNOWLEDGE AND AGREE THAT YOU MAY LOSE SOME OR ALL OF YOUR FUNDS AND THE FUNDS OF YOUR CLIENTS IF APPLICABLE. CRYPTOCURRENCIES ARE A NEW AND INSUFFICIENTLY TESTED TECHNOLOGY. IN ADDITION TO THE RISKS INCLUDED HEREIN, THERE ARE OTHER RISKS ASSOCIATED WITH YOUR USE OF THE SOFTWARE, AND THE PURCHASE, HOLDING AND USE OF CRYPTOCURRENCIES, INCLUDING THOSE THAT BITQUANT CANNOT ANTICIPATE. SUCH RISKS MAY FURTHER MATERIALIZE AS UNANTICIPATED VARIATIONS OR COMBINATIONS OF THE RISKS DISCUSSED HEREIN.
</p>

<p>
4. Offers and prices
All offers and free trials on the Website are without obligations. However, all offers and free trials are always subject to these Terms.
The prices on the BitokWebsite include taxes and expenses.
We can always adjust our prices. The price change will take effect immediately.
</p>

<p>
5. Subscription and payment
You need a subscription to use the Website and Software. Subscriptions are offered for a fixed amount per month and/or per year. You can get a subscription at https://bitquant.online/.
Bitok offers several sorts of packages as a subscription. Each package differs in the number of usable bots, the number of available paper bots, and the amount of volume. You can find all packages at https://bitquant.online/.
Bitok will give Users access to the Software immediately after the subscription has been completed.
The price of the subscription will be invoiced automatically every month. Possible methods of payment are displayed on the Website.
A monthly subscription will continue for a month-to-month period, unless the User cancels the subscription before the end of the term already paid for.
A yearly subscription will automatically be extended after expiration, unless the User cancels the subscription before the end of the term already paid for.
The User can cancel the subscription by the end of the term already paid for. The account of the User will remain active for the period that User has already paid for.
</p>
<p>
6. Withdrawal
Users have the right to withdraw their subscription up to 14 days after purchasing it on the Website.
If the User withdraws their subscription, the User will pay pro rata for the period the User has already been able to use the Software. Users will get the rest of the paid amount back as a refund.
In the case of a free trial, no refund is granted if the User withdraws the subscription.
</p>
<p>
7. Fair use of our Website and Software
By using our Website and Software, you declare that you are at least 18 years old.
You may not use the Website and Software in such way that you violate any other applicable laws and regulations.
As a condition for using the Website and Software, you agree not to provide any information, data or content to us or the Website and Software that is incorrect, inaccurate, incomplete or that violates any law or regulation. In addition, you agree that you will not, nor allow third parties to:
  <ul>
<li>a) enter any non-public / secure areas of the Website or Software;</li>
<li>b) send viruses, worms, junk mail, spam, chain letters, unsolicited offers or ads of any kind and for any purpose</li>
<li>c) investigate, scan or test the Website of Software or any other related system or network, or violate any security or authentication;</li>
<li>d) use any automated systems of software to withdraw data from the Website (“screen-scraping”);</li>
<li>e) make and distribute copies of the Website or Software;</li>
<li>f) attempt to sell, distribute, copy, rent, sub-license, loan, merge, reproduce, alter, modify, reverse engineer, disassemble, decompile, transfer, exchange, translate, hack, distribute, harm or misuse the Website or Software;</li>
<li>g) or create derivative works of any kind whatsoever.</li>
</ul>
You may not create an account under someone else’s name or act like someone else in any way.
In case the account concerns a corporate account, only an authorized person is allowed to trade with the corporate account. It is the responsibility of the user of the corporate account that only authorized persons have access to the account.
If you receive personal data or other sensitive information from other users, you will keep this information secret.
Bitok is entitled to (temporarily or permanently) block your account and deny you access to the Website if we suspect abuse of the account or the Website. We can also block your account or deny you access to the platform if you do not comply with these Terms, including conditions and policies referenced herein.
Bitok shall have the right to immediately terminate your account in the event of any conduct by you that Bitok, in its sole discretion, considers to be unacceptable, or in the event of any breach by you of these Terms of Use, including conditions and policies referenced herein.
</p>
<p>
8. Privacy
Bitok respects your privacy and anticipates the EU General Data Protection Regulation (GDPR). When you use our Website and Software, we will collect certain personal data from you. In our Privacy Policy you can read which personal data we collect and for what purposes.
</p>
<p>
9. Intellectual property
Bitok is the exclusive licensee of all intellectual property rights vesting in and relating to (all content made available through) the Website and the Software, such as – but not limited to – patents, patent applications, trademarks, trademark applications, database rights, service marks, trade names, copyrights, trade secrets, licenses, domain names, know-how, property rights and processes (“Intellectual Property Rights”).
Bitok grants its Users a non-transferrable, non-exclusive, non-sublicensable and revocable license intended for fair use of the Website and Software on the subscription basis as offered by us at https://bitok.pro/. You are not allowed to access the content of the Website and Software for any other purpose, such as selling or distributing the content of the Website and Software. Also see clause 6.
</p>
<p>
10. Availability of the Website and Software and disclaimer of warranties
The Website and Software are available on computers. Bitok will use reasonable efforts to make the Website and Software available at all times. However, the User acknowledges that the Website and Software are provided over the internet and mobile networks, and thus the quality and availability of the Website and Software may be affected by factors outside Bitok reasonable control.
Bitok does not accept any responsibility whatsoever for unavailability of the Website and Software, or any difficulty or inability to download or access content, or any other communication system failure which may result in the Website or Software being unavailable.
Bitok is not responsible for any support or maintenance regarding the Website or Software. Bitok may – at its own discretion – update, modify, or adapt the Website or Software and their functionalities from time to time to enhance the user experience. Bitok is not responsible for any downtime resulting from these actions.
Bitok is not responsible for any miss functionality by third parties or connected exchanges.
To the maximum extent permitted by applicable law, Bitok hereby disclaims all implied warranties regarding the availability of the Website and Software. The Website and Software are provided “as is” and “as available” without warranty of any kind.
</p>

<p>Bitok
11. Helpdesk & Advice
Bitok has a helpdesk where the User can ask questions about the Website and Software. Bitok will only give advice about the functioning of the Website and Software.
Bitok explicitly does not:
a) Give Users any personal advice on recommended settings for the Strategies;
b) Give Users any personal financial advice.
Bitok may upload general tutorials and academy videos on the Website, about the functioning of the Website and Software.
All tutorials and videos uploaded by Bitok are general and contain in no way personal and/or financial advice. All use of these advices is at the sole risk of the User.
All information provided by Bitok based on its services, is purely informative. All use of this information is at the sole risk of the User. Also see clause 3 (disclaimer).
</p>
<p>
12. Risks
The User is aware of the accompanying risks of possessing, trading and using crypto currencies, and takes full responsibility for theses risks. Also see clause 3 (disclaimer).
</p>
<p>
13. Liability
Nothing in these Terms shall exclude or limit Bitok liability when it cannot be excluded or limited under applicable law.
Bitok is not liable to you for any (direct or indirect) damage you suffer as a result of the use of the Website or Software or the content provided thereon. For example, Bitok is not liable for:
a) the proper functioning of (hyper)links provided by the Website or Software;
b) the quality of any strategy created and provided by the Users on the Website;
c) the (lack of) financial benefit for the Users through the use of the Website or Software;
d) any situation where the Users mobile device, login details and/or password is stolen and any third party subsequently makes use of the Website or Software without the User’s consent;
e) any damage or alteration to the User’s equipment, including but not limited to computer equipment or a handheld device as a result of the installation or use of the Website or Software;
f) a failure to meet any of Bitok obligations under these Terms where such failure is due to events beyond Bitok reasonable control.
</p>
<p>
14. Indemnification
Users will indemnify, defend, and hold Bitok harmless from and against all liabilities, damages and costs (including settlement costs and reasonable attorneys’ fees) arising out of third party claims regarding:
a) any injury or damages resulting from the behaviour of Users related to the use of our Website and Software; and
b) breach by Users of these Terms or violation of any applicable law, regulation or order.
</p>
<p>
15. Miscellaneous
Bitok reserves the right to change these Terms. When we change these Terms in a significant way, we will notify Users by newsletter (if the User has provided us with their e-mail address to this end) and post a notification on our Website along with the updated Terms. By continuing to use the Website, you acknowledge the most recent version of these Terms.
If we do not enforce (parts of) these Terms, this cannot be construed as consent or waiver of the right to enforce them at a later moment in time or against another User.
Users cannot transfer the rights and obligations from these Terms to third parties.
</p>
<p>
16. Severability
The invalidity or unenforceability of any provision of these Terms shall not affect the validity or enforceability of any other provision of these Terms. Any such invalid or unenforceable provision shall be replaced or be deemed to be replaced by a provision that is considered to be valid and enforceable and which interpretation shall be as close as possible to the intent of the invalid provision.
</p>
<p>
17. Applicable law and jurisdiction
These Terms shall exclusively be governed by and construed in accordance with the laws of the Uruguay.
Unless mandatory law compels differently, all disputes resulting from or arising in connection with these Terms shall be exclusively submitted to the competent court of Uruguay, unless the dispute can be settled in an amicable fashion.
</p>
<p>
18. Complaints, comments and suggestions
Bitok strives to give you an optimal service. If you have a complaint, comment or suggestion, you can contact our support. Please provide us with your contact details, and a clear description and reason for your complaint. Complaints are usually processed within 7 working days.
</p>
</div>
:
<div style={{color:'white'}}>
<p>
Добро пожаловать в сервис Bitok (далее «Bitok» или «мы / наш сервис»).
Мы предлагаем онлайн программное обеспечение как услугу (SaaS) через наш веб-сайт https://bitok.pro/ («Веб-сайт»).
Наше программное обеспечение («Программное обеспечение») позволяет вам торговать и инвестировать в криптовалюты с помощью автоматического бота для криптовалют, параметры которого (исключительно) вы контролируете и настраиваете.
Эти Условия использования («Условия») применяются к отношениям между Bitok и вами в отношении любого варианта использования Веб-сайта и Программного обеспечения, предлагаемого Bitok. Вы не имеете права использовать Программное обеспечение, не приняв настоящие Условия.
</p>
<p>
1. Определения
Bitok  компания с ограниченной ответственностью, зарегистрированная в соответствии с законодательством. Для понятия Bitok также может использоваться местоимение «мы».
Пользователь: физическое или юридическое лицо, использующее Веб-сайт или Программное обеспечение. Для понятия Пользователь также Bitok также может использоваться местоимение «вы».
Программное обеспечение: программное обеспечение Bitok, разработанное для обеспечения возможности торговли криптовалютами на криптовалютном рынке посредством бота. Программное обеспечение доступно через веб-сайт https://bitok.pro/
Стратегии: предустановки которые обеспечивают выполнение торговой тактики. Пользователи могут настроить Программное обеспечение таким образом, чтобы оно автоматически покупало и продавало валюты на основе Стратегий.
</p>
<p>
2. Регистрация и личный кабинет
Для использования веб-сайта и программного обеспечения Вам необходимо перейти по адресу https://bitquant.online/, зарегистрироваться и создать личную учетную запись.
Вы должны хранить в строжайшей тайне и защищать данные для входа в свою учетную запись и свой пароль. Мы полагаем, что все действия с вашей учетной записью, выполняются вами или под вашим контролем.
Вы несете полную ответственность за сохранение конфиденциальности своего пароля и учетной записи. Кроме того, вы несете полную ответственность за все действия, которые происходят под вашей учетной записью. Вы соглашаетесь немедленно уведомлять Bitok о любом несанкционированном использовании вашей учетной записи или любом другом нарушении безопасности. BITQUANT НЕ НЕСЕТ ОТВЕТСТВЕННОСТИ ЗА ЛЮБЫЕ УБЫТКИ, КОТОРЫЕ МОГУТ ВОЗНИКНУТЬ У ВАС В РЕЗУЛЬТАТЕ ЛЮБОГО НЕПРАВИЛЬНОГО ИСПОЛЬЗОВАНИЯ ВАШЕЙ УЧЕТНОЙ ЗАПИСИ, С ВАШЕГО ЛИБО БЕЗ ВАШЕГО ВЕДОМА. Вы соглашаетесь, что Вы можете нести ответственность за убытки, понесенные Bitok и / или другой стороной из-за того, что кто-то другой использовал вашу учетную запись или пароль. Вы не можете использовать чужую учетную запись без разрешения владельца учетной записи.
Вы соглашаетесь предоставлять актуальную, полную и точную информацию о своей учетной записи. Вы соглашаетесь при необходимости оперативно обновлять свою личную учетную запись, чтобы мы могли связаться с вами в случае необходимости.
</p>
<p>
3. Заявление об ограничении ответственности
BITQUANT ПРЕДОСТАВЛЯЕТ ПРОГРАММНОЕ ОБЕСПЕЧЕНИЕ. BITQUANT НЕ ПРЕДОСТАВЛЯЕТ ФИНАНСОВЫЕ, ИНВЕСТИЦИОННЫЕ, ЮРИДИЧЕСКИЕ, НАЛОГОВЫЕ ИЛИ ДРУГИЕ ПРОФЕССИОНАЛЬНЫЕ КОНСУЛЬТАЦИИ. BITQUANT НЕ ЯВЛЯЕТСЯ БРОКЕРОМ, ФИНАНСОВЫМ СОВЕТНИКОМ, ИНВЕСТИЦИОННЫМ СОВЕТНИКОМ, МЕНЕДЖЕРОМ ПО УПРАВЛЕНИЮ АКТИВАМИ ИЛИ НАЛОГОВЫМ СОВЕТНИКОМ. ВЫ ПРИЗНАЕТЕ И СОГЛАШАЕТЕСЬ, ЧТО BITQUANT НЕ НЕСЕТ ОТВЕТСТВЕННОСТИ ЗА ИСПОЛЬЗОВАНИЕ ВАМИ ЛЮБОЙ ИНФОРМАЦИИ, ПОЛУЧЕННОЙ ВАМИ В ПРОГРАММНОМ ОБЕСПЕЧЕНИИ. ВАШИ РЕШЕНИЯ, В ОТНОШЕНИИ ПРОДУКТОВ ИЛИ УСЛУГ, ИЛИ ВАША ИНТЕРПРЕТАЦИИ ДАННЫХ ПРОИСХОДИТ НА УСЛОВИЯХ ВАШЕЙ ПОЛНОЙ ОТВЕТСТВЕННОСТИ. ВЫ ПРЯМО СОГЛАШАЕТЕСЬ, ЧТО ВЫ ИСПОЛЬЗУЕТЕ УСЛУГУ ПОД СВОЮ ОТВЕТСТВЕННОСТЬ СОЗНАВАЯ СВОИ РИСКИ.
ВЫ ПРИЗНАЕТЕ И СОГЛАШАЕТЕСЬ, ЧТО МОЖЕТЕ ПОТЕРЯТЬ НЕКОТОРЫЕ ИЛИ ВСЕ СВОИ АКТИВЫ И АКТИВЫ СВОИХ КЛИЕНТОВ, ЕСЛИ ОНИ ИСПОЛЬЗУЮТСЯ. КРИПТОВАЛЮТЫ - НОВАЯ ТЕХНОЛОГИЯ, В СТАДИИ ПРОВЕРКИ. В ДОПОЛНЕНИЕ К РИСКАМ, УКАЗАННЫМ ЗДЕСЬ, СУЩЕСТВУЮТ ДРУГИЕ РИСКИ, СВЯЗАННЫЕ С ИСПОЛЬЗОВАНИЕМ ВАМИ ПРОГРАММНОГО ОБЕСПЕЧЕНИЯ, С ПОКУПКОЙ, ХРАНЕНИЕМ И ИСПОЛЬЗОВАНИЕМ КРИПТОВАЛЮТ. ЭТИ РИСКИ ВКЛЮЧЮТ ТАКЖЕ РИСКИ BITQUANT НЕ МОЖЕТ ПРЕДВИДЕТЬ. ЭТИ РИСКИ МОГУТ ПРИВЕСТИ К НЕПРЕДВИДЕННЫМ ВАРИАНТАМ РАЗВИТИЯ СОБЫТИЙ ОПИСАННЫХ ЗДЕСЬ.
</p>
<p>
4. Условия оплаты
Все специальные предложения и бесплатные пробные версии не несут никаких обязательств Bitok, но всегда подпадают под действие настоящих Условий.
Условия оплаты на Сайте включают налоги и другие дополнительные платежи.
Bitok оставляет за собой право в любой момент изменить условия оплаты с немедленным вступлением в силу обновленных условий.
</p>
<p>
5. Подписка и оплата
Для использования Веб-сайта и Программного обеспечения вам необходимо оформить подписку. Подписки могут предлагаются за фиксированную сумму в месяц и / или в год. Вы можете оформить подписку на https://bitquant.online/.
Bitok может предлагать несколько типов пакетов подписки. Пакеты могут отличаться количеством используемых ботов, доступных подключений и объемом. Подробная информация о пакетов подписок доступна на https://bitquant.online/.
Bitok предоставляет Пользователям доступ к Программному обеспечению сразу после оформления подписки.
Счет за подписку выставляется автоматически каждый месяц. Возможные способы оплаты указаны на Сайте.
Подписка будет автоматически продлеваться на каждый следующий период, если Пользователь не отменит подписку до окончания уже оплаченного срока.
Пользователь может отменить оплату подписки на следующий период во время оплаченного срока. Учетная запись Пользователя будет оставаться активной до завершения оплаченного периода.
</p>
<p>
6. Возврат средств
Пользователи имеют право отменить отплату подписки в течение 14 дней после даты оплаты ее на Сайте.
Если Пользователь отменяет оплату подписки за фактическое время использования подписки будет списана плата за использование Программное обеспечение. Остаток от суммы оплаты будет возвращен пользователю.
Для бесплатных пробных версий возврат денежных средств не предусмотрен.
</p>
<p>
7. Добросовестное использование нашего веб-сайта и программного обеспечения
Используя наш Веб-сайт и Программное обеспечение, вы подтверждаете, что вам исполнилось 18 лет.
Вы не имеете права использовать Веб-сайт и Программное обеспечение, если такое использование нарушает или может нарушить любые требования законодательства.
В качестве условия использования Веб-сайта и Программного обеспечения вы соглашаетесь не предоставлять нам или Веб-сайту и Программному обеспечению какую-либо информацию, данные или контент, которые являются неправильными, неточными, неполными либо их предоставление нарушает какие-либо требования законодательства.
Кроме того, вы подтверждаете то, что не будете сами и не разрешаете третьим лицам:
<ul>
<li>а) входить в любые закрытые / безопасные области Веб-сайта или Программного обеспечения;</li>
<li>б) рассылать вирусы, черви, нежелательную почту, спам, письма счастья, нежелательные предложения или рекламу любого рода и для любых целей.</li>
<li>c) исследовать, сканировать или тестировать Веб-сайт и Программное обеспечение или любую другую связанную систему или сеть, или нарушать какие-либо меры безопасности или аутентификации;</li>
<li>г) использовать любые автоматизированные системы программного обеспечения для захвата данных Веб-сайта («screen-scraping»);</li>
<li>д) создавать и распространять копии Веб-сайта или Программного обеспечения;</li>
<li>f) пытаться продавать, распространять, копировать, сдавать в аренду, сублицензировать, брать взаймы, объединять, воспроизводить, изменять, модифицировать, реконструировать, дизассемблировать, декомпилировать, передавать, обменивать, переводить, взламывать, распространять, повреждать или неправильно использовать Веб-сайт или Программное обеспечение;</li>
<li>g) или создавать производные продукты любого рода.</li>
</ul>
Вы не можете создавать учетную запись на чужое имя или каким-либо образом действовать под чужим именем.
В случае, если учетная запись является корпоративной, только уполномоченное лицо может использовать корпоративный счет. Пользователь корпоративной учетной записи несет ответственность за то, чтобы только уполномоченные лица имели доступ к учетной записи.
Если вы получаете личные данные или другую конфиденциальную информацию от других пользователей, вы обязаны хранить эту информацию в секрете.
BitokBitok имеет право (временно или постоянно) заблокировать вашу учетную запись и отказать вам в доступе к Веб-сайту, в случае подозрений в злоупотреблении учетной записью или Веб-сайтом. Мы также можем заблокировать вашу учетную запись или отказать вам в доступе к платформе, если вы не соблюдаете настоящие Условия, включая условия и политики, упомянутые в настоящем документе.
BitokBitok имеет право немедленно прекратить действие вашей учетной записи в случае любого поведения с вашей стороны, которое BitokBitok, по своему собственному усмотрению, считает неприемлемым, или в случае любого нарушения вами настоящих Условий использования, включая условия и политики упомянутые в настоящем документе.
</p>
<p>
8. Конфиденциальность
BitokBitok защищает вашу конфиденциальность в соответствии с Общим регламентом ЕС по защите данных (GDPR). При использовании нашего веб-сайта и программного обеспечения, мы собираем определенные личные данные о вас. В нашей Политике конфиденциальности вы можете прочитать, какие личные данные мы собираем и для каких целей.
</p>
<p>
9. Интеллектуальная собственность
BitokBitok является эксклюзивным владельцем всех прав интеллектуальной собственности, связанных и относящихся к Веб-сайту и Программному обеспечению (всему контенту, доступному через), а именно, помимо прочего, патентов, заявок на патенты, товарных знаков, заявок на товарные знаки, прав на базы данных, прав на торговые наименования, авторских прав, коммерческой тайны, лицензий, доменных имен, ноу-хау, прав собственности («Права интеллектуальной собственности»).
BitokBitok предоставляет своим Пользователям непередаваемую, неисключительную, не подлежащую сублицензированию и отзывную лицензию, предназначенную для добросовестного использования Веб-сайта и Программного обеспечения на основе подписки, предлагаемой нами по адресу https://bitquant.online/. Вам не разрешается доступ к содержимому Веб-сайта и Программного обеспечения для любых других целей, таких как продажа или распространение содержимого Веб-сайта и Программного обеспечения. Также см. Раздел 6.
</p>
<p>
10. Доступность веб-сайта и программного обеспечения и отказ от гарантий.
Веб-сайт и Программное обеспечение доступны на компьютерах платформах. BitokBitok прилагает все разумные усилия, чтобы Веб-сайт и Программное обеспечение были доступны в любое время. Однако Пользователь признает, что Веб-сайт и Программное обеспечение предоставляются через Интернет и мобильные сети, и поэтому на качество и доступность Веб-сайта и Программного обеспечения могут влиять факторы, находящиеся вне разумного контроля Bitok.
BitokBitok не несет никакой ответственности за недоступность Веб-сайта и Программного обеспечения, а также за любые трудности или невозможность загрузки или доступа к контенту, а также за любой другой сбой системы связи, который может привести к недоступности Веб-сайта или Программного обеспечения.
BitokBitok не несет ответственности за любую поддержку или обслуживание в отношении Веб-сайта или Программного обеспечения. BitokBitok может - по своему усмотрению - время от времени обновлять, изменять или адаптировать Веб-сайт или Программное обеспечение и их функции для улучшения взаимодействия с пользователем. Bitok не несет ответственности за простои, вызванные этими действиями.
BitokBitok не несет ответственности за какие-либо неполадки со стороны третьих лиц или подключенных бирж.
В максимальной степени, разрешенной действующим законодательством, BitokBitok настоящим отказывается от всех подразумеваемых гарантий относительно доступности Веб-сайта и Программного обеспечения. Веб-сайт и Программное обеспечение предоставляются «как есть» и без каких-либо гарантий.
</p>
<p>
11. Служба поддержки и консультации
BitokBitok имеет службу поддержки, где Пользователь может задать вопросы о Веб-сайте и Программном обеспечении. BitokBitok отвечает на вопросы только относительно функционирования Веб-сайта и Программного обеспечения.
BitokBitok прямо заявляет, что не будет:
a) Давать пользователям какие-либо советы по рекомендуемым настройкам для Стратегий;
б) Давать пользователям какие-либо финансовые советы.
BitokBitok может загружать на Веб-сайт общие учебные пособия и академические видео о функционировании Веб-сайта и Программного обеспечения.
Все руководства и видео, загружаемые BitokBitok, носят общий характер и не содержат никаких торговых и / или финансовых советов. Пользователь использует эти советы исключительно на свой страх и риск.
Вся информация, предоставляемая BitokBitok на в рамках предоставления сервиса, носит исключительно информативный характер. Пользователь использует эту информацию исключительно на свой страх и риск. Также см. Пункт 3 (отказ от ответственности).
</p>
<p>
12. Риски
Пользователь осознает сопутствующие риски владения, торговли и использования криптовалют и принимает на себя полную ответственность за эти риски. Также см. Пункт 3 (отказ от ответственности).
</p>
<p>
13. Ответственность
Ничто в настоящих Условиях не исключает и не ограничивает ответственность Bitok, если она не может быть исключена или ограничена в соответствии с действующим законодательством.
Bitok не несет ответственности перед вами за любой (прямой или косвенный) ущерб, который вы понесли в результате использования Веб-сайта или Программного обеспечения или предоставленного на них контента. Например, Bitok не несет ответственности за:
а) правильное функционирование (гипер) ссылок, предоставляемых Веб-сайтом или Программным обеспечением;
б) качество любой стратегии, созданной и предоставленной Пользователями на Сайте;
c) (отсутствие) финансовой выгоды для Пользователей от использования Веб-сайта или Программного обеспечения;
d) любой ситуации, в которой мобильное устройство Пользователя, данные для входа и / или пароль украдены, а третье лицо впоследствии использует Веб-сайт или Программное обеспечение без согласия Пользователя;
e) любое повреждение или изменение оборудования Пользователя, включая, помимо прочего, компьютерное оборудование или портативное устройство, в результате установки или использования Веб-сайта или Программного обеспечения;
f) невыполнение каких-либо обязательств Bitok в соответствии с настоящими Условиями, если такое невыполнение вызвано событиями, находящимися вне разумного контроля Bitok.
</p>
<p>
14. Возмещение
Пользователи гарантирует защиту и освобождение Bitok от всех обязательств, убытков и затрат (включая расходы на урегулирование и гонорары адвокатов), возникших в связи с претензиями третьих сторон в отношении:
а) любых травм или убытков, возникших в результате действий пользователей, связанных с использованием нашего веб-сайта и программного обеспечения; а также
б) нарушений Пользователями настоящих Условий или нарушений любых требований законодательства.
</p>
<p>
15. Прочие условия
Bitok оставляет за собой право изменять эти Условия. При существенном изменении настоящих Условий, Пользователи будут уведомлены с помощью информационного бюллетеня (если Пользователь предоставил свой адрес электронной почты) и уведомления на нашем Веб-сайте вместе с обновленными Условиями. Продолжая использовать Веб-сайт, вы соглашаетесь с самой последней версией настоящих Условий.
Отказ от применения части или всех Условий не может быть истолкован как согласие или отказ от права применить их в более поздний момент времени или против другого Пользователя.
Пользователи не могут передавать права и обязанности, вытекающие из настоящих Условий, третьим лицам.
</p>
<p>
16. Отдельные условия
Недействительность или не возможность исполнить любое положение настоящих Условий не влияет на действительность или исковую силу любого другого положения настоящих Условий. Любое такое недействительное или неисполнимое положение должно быть заменено или считаться замененным положением, которое считается действительным и имеющим исковую силу, и толкование которого должно быть максимально приближено к смыслу недействительного положения.
</p>

<p>
17. Применимое право и юрисдикция
Настоящие Условия регулируются и толкуются исключительно в соответствии с законодательством Уругвай.
Если иное не предусмотрено законодательством, все споры, возникающие в результате или возникающие в связи с настоящими Условиями, должны рассматриваться исключительно в компетентном суде Уругвая, кроме случаев когда спор не может быть урегулирован мирным путем.
</p>
<p>
18. Жалобы, комментарии и предложения.
Bitok стремится предоставить оптимальный уровень сервиса. Жалобы, комментарии или предложение могут быть направлены в службу поддержки. Обращение должно содержать контактные данные и также четкое описание причин обращения. Обращения обрабатываются в течение 7 рабочих дней либо более.
</p>
</div>}
    </div>
  )
}

export default Terms;
