export function toggleBinanceSettings() {
    return (dispatch, getState) => {
        const { isBinanceSettings } = getState().uiApi;
        dispatch({
            type: "TOGGLE_BINANCE_SETTINGS",
            isBinanceSettings: !isBinanceSettings
        })
    };
}

export function toggleLogin() {
    return (dispatch, getState) => {
        const { isLogin } = getState().uiApi;
        dispatch({
            type: "TOGGLE_LOGIN",
            isLogin: !isLogin
        })
    };
}
export function toggleBinanceTradeStarted() {
    return (dispatch, getState) => {
        const { isBinanceTradeStarted } = getState().binanceApi;
        dispatch({
            type: "TOGGLE_BINANCE_TRADE_STARTED",
            isBinanceTradeStarted: !isBinanceTradeStarted
        })
    };
}
