import React,{useState,useEffect,useRef} from 'react'
import { useTranslation } from 'react-i18next'
import arrow from "../../assets/svg/arrow.svg"
import arrow_black from "../../assets/img/svg/arrom-black.svg"
import world from "../../assets/svg/world.svg"
import en from "../../assets/img/language/en.png"
import ru from "../../assets/img/language/ru.png"

const LangSwitch = (props) => {
  const {type} = props
  const { i18n } = useTranslation();
  const [open,setOpen] = useState(false);
  let menuref = useRef();

  useEffect(() => {
 document.addEventListener("mousedown", (event="") => {
     try {
       if (!menuref.current.contains(event.target)) {
         setOpen(false);
       }
     } catch (e) {}
 })
})

const Change = (lang) => {
  i18n.changeLanguage(lang);
  setOpen(false);
}
  const langs = [
    {
      name: 'ru',
      value: 'Рус',
      ico: ru
    },
    {
      name: 'en',
      value: 'Eng',
      ico: en
    },
    {
      name: 'ua',
      value: 'Укр',
      ico: en
    },
  ]
  return(
    <div style={type === 'register' || type === 'sidebar'?{}:{position: 'absolute', right: 35,top: 26}}>
    {type === 'register'?
    <div style={{position: 'absolute',top: 20,right: 20,width:150,textAlign:'end'}} className="lang-block" ref={menuref}>
    <p style={{color: 'white'}} onClick={() => open ? setOpen(false) : setOpen(true)}>
      {langs.filter(el => el.name === i18n.language)[0].value}
    <img style={{
      width: 15,
      marginLeft:10,
      transform: open ? "rotate(180deg)" : "rotate(0)",
      transition: '0.5s',
    }} src={arrow} alt="arrow"/></p>
  <div style={{display: open ? 'block' :'none'}}>
  {langs.map((el,key) =>
    el.name === i18n.language ? null :
    <p key={key} style={{marginLeft: 10,marginRight:10,color: 'white'}}
     onClick={() => Change(el.name)}>{el.value}</p>)}
    </div>
    </div>
    :
    <div>
      <div 
      onClick={() => setOpen(!open)}  
      style={{
          display:'flex',
          border: '2px solid rgba(46, 76, 138, 1)',
          padding: "10px",
          borderRadius: 5,
          background:"rgba(50, 56, 73, 1)",
          borderBottomLeftRadius:open ? 0 : 5,
          borderBottomRightRadius:open ? 0 : 5,
          paddingLeft: 10,
          paddingRight:10,
          cursor:'pointer'
        }}>
        <p style={{color:'white',textTransform:'uppercase',margin:0,marginLeft: 5,fontSize:18}}>{langs.filter(lan => lan.name === i18n.language)[0].value}</p>
        <img src={arrow}
        style={{
          marginLeft:10,
          width:11,
          transition: '0.5s',
          transform: open ? "rotate(0deg)" : "rotate(180deg)",
        }} alt="arrow"/>
      </div>
      <div 
      style={{
        display: open ? 'block' :'none',
        position: 'absolute', 
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        background: "rgba(33, 37, 48, 1)",
        border: '1px solid rgba(46, 76, 138, 1)',
        textAlign: 'center',
        width:"82.32px",
        zIndex:2
        }}>
        {langs.filter(el => el.name !== i18n.language).map((res,key) =>
          <p key={key} style={{
            color:'white',
            margin:0,
            height:42,
            borderBottom: "1px solid rgba(46, 76, 138, 1)",
            fontSize:18,
            textTransform:'uppercase',
            display:'flex',
            alignItems: 'center',
            paddingLeft:10,
            cursor:'pointer'
          }}
          onClick={() => Change(res.name)}>{res.value}</p>)}
      </div>
    </div>}
    </div>
  )
}

export default LangSwitch
