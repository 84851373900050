import storage from '../storage/storage.js'
import Loader from './HistoryTrades/Loader.jsx'

const OpenTrades = (props) => {
  const {t,openTrade,loader} = props
      const NoDeal = () => {
        return(
        <table>
          <tbody>
            <tr>
              <td>{t("NoHistoryDeals")}</td>
            </tr>
          </tbody>
        </table>
      )}

    return (
        <div className="open-trades">
            <div className="open-trades-header">
                <p>{t("openDeals")}</p>
            </div>
            <div className="open-trades-table">
            {loader ? <Loader/> :
              openTrade.length === 0 ? <NoDeal/> :
                    <table>
                        <thead>
                          <tr>{storage.open_trades.map((el,index) => <th key={index}>{t(el)}</th>)}</tr>
                        </thead>
                        <tbody>
                            {openTrade.filter(res => res.position !== 0).map((el,index) =>
                            <tr key={index}>
                              <td>{el.instrument.toUpperCase()}</td>
                              <td>{el.position}</td>
                              <td style={{ color: el.side === 'BUY'? 'green':'red'}}>{el.side}</td>
                            </tr> )}
                        </tbody>
                    </table>
                  }
            </div>
        </div>
    );
}


export default OpenTrades
