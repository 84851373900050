import React from 'react'
import {postMerchant} from '../../../api/api.js'
import {toast, ToastContainer} from "react-toastify";


const WithdrawBalance = (props) => {
  const {
    imgCurrency,
    type,
    currency,
    enoughValue,
    setStep,
    t,balance,
    amplitudeConfig,
    amplitudeId,
    setOrderUserId} = props
  const userdata = JSON.parse(localStorage.getItem("userData"))
  const createNewMerche = () => {
    postMerchant(userdata.email,userdata.session,setStep,setOrderUserId,amplitudeId,amplitudeConfig)
  }
  const notifyInfo = (data) => toast.info(data);
  return(
    <div style={{marginBottom:24}}>
      <p  style={!type?{marginTop: 64}: {marginTop:24}} className="alert_modal_description">{t('subModalTitle')}</p>
      <div style={type? {
        border: '1px solid #EB5757',
        marginBottom: 0
      }:{}
      } className="modal_statistic_balance">
        <img src={imgCurrency} alt="currency"/>
        <p className="modal_balance">{balance && balance > 0? balance.toFixed(3) : 0}</p>
        <p className="modal_currency">{currency? currency.toUpperCase() : null}</p>
        
        {type?<p
        onClick={() => notifyInfo("In development...")}
        style={{textDecoration:'none'}}
        className="withraw_balance_button">{t('deposit')}</p>:null}
      </div>
      {type? <p className="withdraw_alert_danger">{t('subNoBalance')}</p>:null}
      <ToastContainer/>
      </div>
  )
}

export default WithdrawBalance
