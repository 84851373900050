import React, {useState, useEffect} from 'react'
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import {useTranslation} from 'react-i18next'
import {Box, Button, Divider, Grid, MenuItem, TextField, Typography, useTheme} from "@mui/material";
import {DataGrid} from '@mui/x-data-grid';
import {useFormik} from "formik";
import * as yup from 'yup';
import {getSubscribe, sendRegister, getAllUserInfo} from "../../api/api";
import {toast} from "react-toastify";


const Bots = (props) => {
    const {amplitude, amplitudeId, currentLinkReff} = props

    const theme = useTheme();

    const urlParams = new URLSearchParams(window.location.search);
    const cid = urlParams.get('cid');

    const adminData = JSON.parse(localStorage.getItem("userData"));

    // preparation

    const notifySuccess = (data) => toast.success(data);
    const notifyError = (data) => toast.error(data);

    //[{ _id: '54as54zx', Name: "test"}];//getSubscribe();

    const [subscribesAvailable, setSubscribesAvailable] = useState([{name: "Advanced"}]);

    //getSubscribe(setSubscribesAvailable);

    // const subscriptions = subscribesAvailable.map(val => (
    //         {
    //             name: val.name
    //         }
    //     )
    // );


    const {t} = useTranslation();
    const [tab, setTab] = useState("2")

    // management


    const initRows = [
        {
            id: 0,
            email: 'test@testmail.com',
            group: 'testGroup',
            strategy: subscribesAvailable[0].Name
        }
    ];


    const initSearchFields = {
        searchField: ""
    }

    const searchSchemaValidation = yup.object({
        searchField: yup.string()
    });

    const formikSearch = useFormik(
        {
            initialValues: initSearchFields,
            validationSchema: searchSchemaValidation,
            onSubmit: (values) => {
                //console.log(values)

            }
        }
    );


    const [rows, setRows] = useState(initRows);


    //console.log(userdata)

    const managing = () => {

        const columns = [
            {
                field: 'id',
                headerName: 'ID',
                width: 90,
            },
            {
                field: 'email',
                headerName: 'Name',
                width: 300,
            },
            {
                field: 'group',
                headerName: 'Group',
                width: 150
            },
            {
                field: 'strategy',
                headerName: 'Strategy',
                width: 150
            }
        ]


        const handleUpdateAllRows = () => {
            getAllUserInfo(adminData.session, adminData.email, (value) => {
                const mapped = value.map(val => ({
                    id: val.id,
                    email: val.email,
                    group: val.group,
                    strategy: val.subscription.type
                })).filter(val => val.group != "user");
                //console.log(mapped);
                setRows(mapped)
            });
        };

        return (
            <Box className="boxManaging">
                <Box
                    component="form"
                    onSubmit={formikSearch.handleSubmit}
                    className="boxUpperLine">
                    <TextField required
                               fullWidth
                               id="search"
                               label="Search"
                               value=''
                               variant="filled"
                               onChange={formikSearch.handleChange}
                               disabled
                    />

                    <Button
                        variant="filled"
                        type="submit"
                        onClick={handleUpdateAllRows}
                    >
                        <Typography>
                            Update
                        </Typography>
                    </Button>
                </Box>

                <Box className="boxResult" sx={{height: 600, mt: 1}}>
                    <DataGrid
                        rows={rows}
                        columns={columns}

                    />
                </Box>
            </Box>
        );
    }


    // creation
    const botsMax = 1000;

    const initialFields = {
        prefix: "bot",
        group: "botGroup",
        strategy: subscribesAvailable[0].name,
        amount: 1
    }

    const validationSchemaFields = yup.object({
        prefix: yup.string().required().min(1, 'You should provide a prefix'),
        group: yup.string().required().min(3, 'Minimal group name length should be at list 3 chars length'),
        strategy: yup.string().required().oneOf(subscribesAvailable.map(val => val.name)),
        amount: yup.number().required().min(1, "You should create at least one account").max(botsMax, "Don't overload database")
    })

    const formikHook = useFormik(
        {
            initialValues: initialFields,
            validationSchema: validationSchemaFields,
            onSubmit: (values) => {
                for (let i = 0; i < values.amount; i++) {
                    let name = values.prefix + (i).toString();
                    let email = name + '@' + values.group + '.' + values.strategy;
                    sendRegister(cid, {name: name, email: email}, '', notifySuccess, notifyError, (val => {console.log(val)}), values.group, currentLinkReff);
                }
            }
        }
    );

    useEffect(() => {
        getSubscribe(setSubscribesAvailable);
    }, [setSubscribesAvailable])

    const creation = () => {

        const fieldsId = Object.keys(initialFields);
        const fieldsLabels = fieldsId.map(val => val.toUpperCase());

        const renderOptions = () => subscribesAvailable.map(val => (
                <MenuItem key={val.name} name={fieldsId[2]} value={val.name}>
                    {val.name}
                </MenuItem>
            )
        )

        return (
            <Box
                className="creationBox"
                component="form" onSubmit={formikHook.handleSubmit}
                sx={{display: "flex", flexDirection: "column", gap: "8px"}}
            >
                <TextField required
                           fullWidth
                           id={fieldsId[0]}
                           label={fieldsLabels[0]}
                           value={formikHook.values.prefix}
                           error={formikHook.touched.prefix && !!formikHook.errors.prefix}
                           helperText={formikHook.touched.prefix && formikHook.errors.prefix}
                           onChange={formikHook.handleChange}
                           variant="filled"
                />

                <TextField required
                           fullWidth
                           id={fieldsId[1]}
                           label={fieldsLabels[1]}
                           value={formikHook.values.group}
                           error={formikHook.touched.group && !!formikHook.errors.group}
                           helperText={formikHook.touched.group && formikHook.errors.group}
                           onChange={formikHook.handleChange}
                           variant="filled"
                />

                <TextField required
                           fullWidth
                           select
                           id={fieldsId[2]}
                           name={fieldsId[2]}
                           label={fieldsLabels[2]}
                           error={formikHook.touched.strategy && !!formikHook.errors.strategy}
                           helperText={formikHook.touched.strategy && formikHook.errors.strategy}
                           onChange={formikHook.handleChange}
                           defaultValue={subscribesAvailable[0].name}
                           value={formikHook.values.strategy}
                           variant="filled"
                >
                    {renderOptions()}
                </TextField>

                <TextField required
                           fullWidth
                           id={fieldsId[3]}
                           label={fieldsLabels[3]}
                           value={formikHook.values.amount}
                           error={formikHook.touched.amount && !!formikHook.errors.amount}
                           helperText={formikHook.touched.amount && formikHook.errors.amount}
                           onChange={formikHook.handleChange}
                           variant="filled"
                />
                <Button
                    variant="filled"
                    type="submit"
                >
                    <Typography>
                        Assert bots to database
                    </Typography>
                </Button>
            </Box>
        );
    }

    const handleChange = (event, newTab) => {
        setTab(newTab)
    };


    return (
        <div>
            <div>
                <TabContext value={tab}>
                    <TabList
                        onChange={handleChange}
                    >
                        <Tab label={t("managing")} value="1"/>
                        <Tab label={t("creation")} value="2"/>
                    </TabList>
                    <TabPanel value="1">
                        {managing()}
                    </TabPanel>
                    <TabPanel value="2">
                        {creation()}
                    </TabPanel>
                </TabContext>
            </div>
        </div>
    );
}


export default Bots;