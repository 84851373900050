import Chart from "./Chart";
import React, { useState } from 'react'
import DatePickerContainer from '../../components/DatePickerContainer/DatePickerContainer'
import { updateTransaction } from "../../api/api"
import { toast, ToastContainer } from "react-toastify";
import trash from "../../assets/imgredesign/trash.svg"

const ChartMain = (props) => {
  const [session, setSession] = useState('current');
  const { t, history, dateArr, rerender, setRerender, visionClear, setVisionClear } = props
  const currentDate = new Date(), inWeek = new Date()
  const [value, setValue] = useState(currentDate);
  const [oldDate, setOldDate] = useState(inWeek.setDate(currentDate.getDate() - 7))
  const [filter, setFilter] = useState(false)
  const [arrFilter, setArrFilter] = useState({ data: [], date: [] })
  const userdata = JSON.parse(localStorage.getItem("userData"))
  const notifySuccess = (data) => toast.info(data);
  const notifyError = (data) => toast.error(data);

  const state_arr = [
    //  {name:'current',lang: 'currentSession'},
    //  {name: 'all',lang: 'allTime'}
  ]

  const openModalVisClear = () => {
    if (userdata.email !== "demo@bitok.pro") {
      setVisionClear(true)
    } else { notifyError("Demo account. You don`t have permission") }
  }

  const createOldDate = (newValue) => {
    setValue(newValue)
    setFilter(true)

  }

  const createNewDate = (newValue) => {
    setOldDate(newValue)
    setFilter(true)

  }

  const debug_arr = [0]
  if (history.length > 0) {
    for (var i = 0; i < history.filter(el => el.type !== null).length; i++) {
      debug_arr.push(debug_arr[i] + history[i].commision + history[i].pnL);
    }
  }
  if (filter) {
    const new_arr = [0]
    const filterArr = [0]
    const filterArrDate = [0]
    // console.log(history.filter(el => new Date(el.time) > oldDate && new Date(el.time) < value))
    for (var i = 0; i < history.length; i++) {
      new_arr.push(new_arr[i] + history[i].commision + history[i].pnL);

      if (new Date(history[i].time) > oldDate && new Date(history[i].time) < value) {
        filterArr.push(new_arr[i] + history[i].commision + history[i].pnL)
        filterArrDate.push(history[i].time)
      }
    }
    setArrFilter({ data: filterArr, date: filterArrDate })
    setFilter(false)
  }
  return (
    <div className="chart">
      <div className="chart-data">
        <div className="chart-data-title">
          <div style={{ width: '100%' }} className="chart-data-title-text">
            <p style={{ color: 'rgba(163, 184, 224, 1)', width: '100%' }}>{t("dealsHover")}</p>
            <div style={{ marginRight: '-35px', display: 'flex' }}>
              <DatePickerContainer
                t={t}
                dateTo={value}
                setDateTo={createOldDate}
                dateFrom={oldDate}
                setDateFrom={createNewDate}
              />
            </div>
            <img
              style={{
                cursor: 'pointer',
                marginLeft: 19
              }}
              src={trash}
              onClick={() => openModalVisClear()}
              alt="Trash" />
          </div>
          <div className="chart-data-title-link">
            {state_arr.map((el, i) =>
              <p key={i} className={el.name === session ? "active" : ""} onClick={() => setSession(el.name)}>{t(el.lang)}</p>)}
          </div>
        </div>
        <div className="chart-data-body">
          <Chart
            session={session}
            dateArr={dateArr}
            current_arr={debug_arr}
            arrFilterDate={arrFilter.date}
            arrFilter={arrFilter.data} />
        </div>
      </div>
      <div className="chart-analyze">
        {/* {storage.info_chart.map(el =>
              <div key={el.title} className="chart-analyze-card">
              <div className="border-chart-data">
                  <p>{t(el.title)}</p>
                  <h1 className={el.status === 'positive' || el.status === 'negative' ? el.status : ''}>{eval(el.value)}</h1>
                  </div>
              </div>)} */}
      </div>
      <ToastContainer />
    </div>
  );
}


export default ChartMain
