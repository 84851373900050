import { useTranslation } from 'react-i18next'
import storage, { timestampConvert } from '../storage/storage.js'
import Loader from './HistoryTrades/Loader.jsx'
import React, { useState } from 'react'
import DatePickerContainer from './DatePickerContainer/DatePickerContainer'
import trash from '../assets/imgredesign/trash.svg'
import { updateTransaction } from "../api/api"
import { toast, ToastContainer } from "react-toastify";


const HistoryTrades = (props) => {
  const { limit, loader, type, filterTrade, rerender, setRerender, setVisionClear, visionClear } = props
  const currentDate = new Date(),
    inWeek = new Date()
  const [value, setValue] = useState(currentDate);
  const [filter, setFilter] = useState(false)
  const [oldDate, setOldDate] = useState(inWeek.setDate(currentDate.getDate() - 7))
  const { t } = useTranslation();
  const userdata = JSON.parse(localStorage.getItem("userData"))
  const notifySuccess = (data) => toast.info(data);
  const notifyError = (data) => toast.error(data);
  const DateNow = Date.now()

  const NoDeal = () => {
    return (
      <table>
        <tbody>
          <tr>
            <td>{t("NoHistoryDeals")}</td>
          </tr>
        </tbody>
      </table>
    )
  }

  const createOldDate = (newValue) => {
    setValue(newValue)
    setFilter(true)
  }

  const createNewDate = (newValue) => {
    setOldDate(newValue)
    setFilter(true)
  }


  const openModalVisClear = () => {
    if (userdata.email !== "demo@bitok.pro") {
      setVisionClear(true)
    } else {
      notifyError("Demo account. You don`t have permission")
    }
  }

  return (
    <div className="history-trades" style={type === "main" ? { width: 'auto' } : {}}>
      <div className="history-trades-header">
        <p style={{ width: '100%' }} className="">{t('HistoryDeals')}</p>
        <DatePickerContainer
          t={t}
          dateTo={value}
          setDateTo={createOldDate}
          dateFrom={oldDate}
          setDateFrom={createNewDate} />
        {/* <img
          style={{
            cursor: 'pointer',
            marginRight: 20,
            marginLeft: '-20px'
          }}
          src={trash}
          onClick={() => openModalVisClear()}
          alt="Trash" /> */}
      </div>
      <div style={{ maxHeight: 300, overflow: 'auto' }} className="history-trades-table">

        {loader ? <Loader /> :
          filterTrade.length === 0 ? <NoDeal /> :
            <table>
              <thead>
                <tr>{storage.history.map((el, index) => <th key={index}>{t(el)}</th>)}</tr>
              </thead>
              <tbody>
                {
                  (filter ?
                    filterTrade.filter(el =>
                      new Date(el.time).getTime() >= oldDate.getTime() && new Date(el.time).getTime() <= (value !== null ? value.getTime() : currentDate.getTime())
                    ) :
                    filterTrade.slice(0, limit)).map((el, index) =>
                      el.pnL === 0 ? "" :
                        <tr key={index}>
                          <td>{el.instrument.toUpperCase()}</td>
                          <td>{el.volume}</td>
                          <td>{el.type === 'SELL' ? t('short') : t('long')}</td>
                          <td>x{el.leverage}</td>
                          <td>{`${new Date(el.time).getDate().toString().length === 1 ? `0${new Date(el.time).getDate()}` : new Date(el.time).getDate()}.${(new Date(el.time).getMonth() + 1).toString().length === 1 ? `0${new Date(el.time).getMonth() + 1}` : new Date(el.time).getMonth() + 1}.${new Date(el.time).getFullYear()}`} {timestampConvert(el.time, type === "main" ? "main" : "")}</td>
                          <td style={{
                            color: (el.pnL + el.commision) > 0 ? 'rgba(156, 255, 109, 1)' : 'rgba(255, 109, 109, 1)'
                          }}>{(el.pnL + el.commision).toFixed(8)}</td>
                        </tr>
                    )}
              </tbody>
            </table>
        }
      </div>
      <ToastContainer />
    </div>
  );
}







export default HistoryTrades
