import React from 'react'
//import correct from '../../assets/img/accept.png';

const BinanceSettingsCurrent = (props) => {
  const {t,apiCurrentdata,setStep} = props

  const activeKeys = apiCurrentdata.filter(el => el.value !== null)
    return (
        <div className="binance-connect current">
            {/*<div className="binance-connect-icon">!</div>*/}

            <div className="binance-connect-text">
            <h2>{t('avaiableHover')}</h2>
            {activeKeys.map((el,index) =>
              <div key={index} className="binance-api-data">
              <p className="p-bold">{t("choose") + " " + el.name}:
              {/*tradeType.toUpperCase() === el.name ? <img style={{width: 18}} src={correct} alt='correct'/>: ''*/}
              </p>
              <p style={{marginBottom: 20}} className="p-normal">{el.value.apiKey}</p>
              </div>)}
            </div>
            <div className="binance-connect-button current-button">
                <button onClick={()=>setStep('register')}><i className="fas fa-cog"></i>{t("settingsButton")}</button>
            </div>
        </div>
    );
}

export default BinanceSettingsCurrent
