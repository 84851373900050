import inst_1 from '../assets/EN/1.png'
import inst_2 from '../assets/EN/2.png'
import inst_3 from '../assets/EN/3.png'
import inst_4 from '../assets/EN/4.png'
import inst_5 from '../assets/EN/5.png'
import inst_6 from '../assets/EN/6.png'
import inst_7 from '../assets/EN/7.png'
import inst_8 from '../assets/EN/8.png'
import inst_9 from '../assets/EN/9.png'
import inst_10 from '../assets/EN/10.png'
import inst_11 from '../assets/EN/11.png'
import inst_12 from '../assets/EN/12.png'
import inst_13 from '../assets/EN/13.png'
import inst_14 from '../assets/EN/14.png'
import inst_15 from '../assets/EN/15.png'
import inst_16 from '../assets/EN/16.png'
import inst_17 from '../assets/EN/17.png'
import inst_18 from '../assets/EN/18.png'
import inst_19 from '../assets/EN/19.png'
import inst_20 from '../assets/EN/20.png'
import inst_21 from '../assets/EN/21.png'
import inst_22 from '../assets/EN/22.png'
import inst_1ru from '../assets/RU/1.png'
import inst_2ru from '../assets/RU/2.png'
import inst_3ru from '../assets/RU/3.png'
import inst_4ru from '../assets/RU/4.png'
import inst_5ru from '../assets/RU/5.png'
import inst_6ru from '../assets/RU/6.png'
import inst_7ru from '../assets/RU/7.png'
import inst_8ru from '../assets/RU/8.png'
import inst_9ru from '../assets/RU/9.png'
import inst_10ru from '../assets/RU/10.png'
import inst_11ru from '../assets/RU/11.png'
import inst_12ru from '../assets/RU/12.png'
import inst_13ru from '../assets/RU/13.png'
import inst_14ru from '../assets/RU/14.png'
import inst_15ru from '../assets/RU/15.png'
import inst_16ru from '../assets/RU/16.png'
import inst_17ru from '../assets/RU/17.png'
import inst_18ru from '../assets/RU/18.png'
import inst_19ru from '../assets/RU/19.png'
import inst_20ru from '../assets/RU/20.png'
import inst_21ru from '../assets/RU/21.png'
import inst_22ru from '../assets/RU/22.png'


export const instruction = {
    link:[
        'register_link',
        'verification_link',
        'create_api_link',
        'settings_link',
        'reqister_bitquant_link',
        'connect_bitquant_link',
        'monitoring_link'
    ],
    ru: [{
        consist: [
            "1. Зарегистрируйтесь по реферальной ссылке на бирже <a href='https://www.huobi.com/'>www.huobi.com</a>.",
            "2. Пройдите верификацию аккаунта на бирже <a href='https://www.huobi.com/'>www.huobi.com</a>.",
            "3. Создание API ключей на бирже <a href='https://www.huobi.com/'>www.huobi.com</a>, необходимых для подключения на нашем сервисе CryPPush.",
            "4. Настройка рабочего пространства на бирже <a href='https://www.huobi.com/'>www.huobi.com</a> - подготовительный этап перед запуском.",
            '5. Регистрация в сервисе CryPPush.',
            '6. Подключение счета на CryPPush.',
            '7. Мониторинг торговых действий с приложения Huobi на телефон.'
        ],
        content: [
            "<p class='register_link'>1.Откройте <a href='https://www.huobi.com/ru-ru/topic/double-reward/?invite_code=x9dpc'>www.huobi.com</a> и нажмите кнопку “Sign Up” чтобы создать новый аккаунт (если у вас уже имеется таковой, создавать новый нет необходимости)</p>" +
            '<br/>2. Кнопка “Sign Up” перекинет вас на начальную страницу где вам надо будет ввести свой email, пароль и реферальную ссылку (последнее не обязательно). После этого, вы проходите капчу и подтверждение email`а, которое придет на указанную вами при регистрации почту.' + 
            `<img style="width:100%; margin-top:30px" src=${inst_1} alt='instruction1'/>` +
            `<img style="width:100%" src=${inst_2} alt='instruction2'/>` + 
            `<img style="width:100%" src=${inst_3} alt='instruction3'/>` + 
            `<img style="width:100%" src=${inst_4} alt='instruction4'/>`,

            '</br>3.	Пополните счёт на бирже(вы можете это сделать как картой так и крипто кошельком).'+
            `<img style="width:100%;margin-top:30px" src=${inst_5} alt='instruction5'/>` + 
            '<p class="verification_link">4. Последующие шаги будут доступны лишь при условии прохождения верификации</p>' + 
            '</br>5.	Откройте настройки аккаунта на главной странице биржи и выберите вкладку “Identification”'+
            `<img style="width:100%;margin-top:30px" src=${inst_7} alt='instruction7'/>`,

            '</br>6.	После этого выберите “Personal Verification”'+
            `<img style="width:100%;margin-top: 30px" src=${inst_9} alt='instruction9'/>`+
            '</br>7.	На Появившейся странице выберите вкладку “Derivatives” и нажмите кнопку “Verify”'+
            `<img style="width:100%;margin-top:30px" src=${inst_10} alt='instruction10'/>`+
            `<img style="width:100%" src=${inst_11} alt='instruction11'/>`+
            '</br>8.	Предоставьте всю необходимую информацию и документы, требуемые биржей для прохождения верификации. В среднем процесс одобрения заявки на верификацию аккаунта занимает 5-10 минут.'+
            '</br>9.После успешного прохождения верификации, откройте настройки аккаунта и нажмите на “API Settings”.',
            `<img style="width:100%;margin-top:30px" src=${inst_13} alt='instruction13'/>`,

            '<br/>10.Единственная опция которую вам надо будет выбрать это “Trade”, поставьте возле него галочку. После этого нажмите на “Create”.'+
            `<img style="width:100%;margin-top:30px" src=${inst_14} alt='instruction14'/>`+
            '<br/>11.В появившемся окне поставьте две галочки так как это показано на экране. После этого вы получите письмо-подтверждение с кодом на почту.'+
            `<img style="width:100%;margin-top:30px" src=${inst_15} alt='instruction15'/>`+
            `<img style="width:100%;margin-top:30px" src=${inst_16} alt='instruction15'/>`+
            '<br/>12.Скопируйте “Access key” и “Secret key” себе в заметки (они понадобятся нам в будущем)'+
            `<img style="width:100%;margin-top:30px" src=${inst_17} alt='instruction15'/>`+
            '<br/>13.После создания API ключа и прохождения верификации вам надо создать Futures аккаунт.'+
            '<br/>14. В секции “Derivatives” выберите “USDT-margined Contracts”'+
            `<img style="width:100%;margin-top:30px" src=${inst_18} alt='instruction16'/>`+
            '<p class="reqister_bitquant_link">15. Вам надо будет выполнить следующие действия :<br/><br/>1. Выберите пару BTC/USDT.<br/>2. Нажмите на Cross<br/>3. В правом углу нажмите на красную кнопку и передвиньте ползунок на максимальное значение (200Х) после чего подтвердите действие нажав на “Confirm”.</br>5. Сравните то, что получилось у вас со скриншотом дабы убедиться что вы сделали все правильно</p>',

            `<img style="width:100%" src=${inst_19} alt='instruction16'/>`+
            '<br/>16.Повторите эти действия для пары ETH-USDT '+
            '<p>17.	Для того, чтобы Bitok bot смог начать торговлю, вам нужно пополнить свой futures аккаунт. Для этого, выполните следующие действия:<br/><br/>1. На главной странице откройте секцию “Balance” и нажмите на “Assets Overview”<br/>2. Нажмите на “Transfer”.<br/>3. После этого выберите счета на которых будет совершаться перевод.<br/>From: Spot account - to: USDT-margined Contracts Account.<br/>4.Введите сумму в соответствии с приобретенной вами стратегией.<br/>5. Нажмите на “Transfer”.</p>'+
            `<img style="width:100%" src=${inst_20} alt='instruction18'/>`,
            `<img style="width:100%" src=${inst_21} alt='instruction18'/>`+
            `<img style="width:100%" src=${inst_22} alt='instruction18'/>`+

            `<img style="width:100%" src=${inst_19} alt='instruction19'/>`+
            '<br/>18. Зайдите на cryppush.net и зарегестрируйтесь.'+
            `<br/>19. Заполните все соответствующие поля требуемой информацией.`+
            '<br/>20. Войдите в аккаунт. После этого у вас откроется личный кабинет.'+
            `<br/>21. Откройте секцию “Settings”.`+
            '<br/>22. Нажмите на “Configure API”. '+
            '<br/>23. Введите в соответствующие поля ранее скопированные API ключи (те что мы ранее создавали на www.huobi.com).'+
            '<br/>24. Нажмите на “Save” дабы перейти к следующему шагу. Вам надо будет подтвердить активацию API ключей паролем, который вы создали регистрируясь на cryppush.net. После этого, нажмите “Save”.'+
            '<br/>25. После этого, нажмите на “Launch Huobi”. '+
            '<br/>26. Вам надо будет повторно подтвердить действие используя пароль, который вы создали регистрируясь на cryppush.net.  Нажмите на “Confirm”, чтобы сделать это.'+
            '<br/>27. Вы получите уведомление о том что Huobi API активен. '+
            '<br/>28. Поздравляем! Теперь вы сможете наблюдать за работой бота на вкладке “Trade”.'
        ],
        video: "7YYGfVOMpko"
    }],
    en: [{
        consist: [
            "1. Registration via our referral link on the <a href='https://www.huobi.com/'>www.huobi.com</a>.",
            "2. Verification of the account on the <a href='https://www.huobi.com/'>www.huobi.com</a>.",
            "3. Creation of API keys on <a href='https://www.huobi.com/'>www.huobi.com</a>, necessary to connect to our service CryPPush.net.",
            "4. Setting up a workspace on <a href='https://www.huobi.com/'>www.huobi.com</a>. The preparatory stage before launching.",
            '5. Registration on CryPPush.online.',
            '6. Connecting an account on CryPPush.net.',
            '7. Monitoring trading activities from the Huobi app on your mobile device.'
        ],
        content: [
            "<p class='register_link'>1. Open up <a href='https://www.huobi.com/ru-ru/topic/double-reward/?invite_code=x9dpc'>www.huobi.com</a> and click the “Sign Up” button to set up a new account if you don't have one already.</p>"+
            '<br/>2. “Sign Up” button will take you to the start page, where you need to enter your email, password, and referral code. Then you need to pass the captcha, followed by an email confirmation.'+
            `<img style="width:100%" src=${inst_1} alt='instruction1'/>`+
            `<img style="width:100%" src=${inst_2} alt='instruction2'/>`+
            `<img style="width:100%" src=${inst_3} alt='instruction3'/>`+
            `<img style="width:100%" src=${inst_4} alt='instruction4'/>`,

            '<br/>3. Make a deposit (you can do it with a bank card or a cryptocurrency wallet).'+
            `<img style="width:100%" src=${inst_5} alt='instruction5'/>`+
            '<p class="verification_link">4. You need to be verified to proceed to the next stage of registration.</p>'+
            '<br/>5. Open account settings on the main page. Then you need to click “Identification”.'+
            `<img style="width:100%" src=${inst_7} alt='instruction7'/>`,

            '<br/>6. Select “Personal Verification”.'+
            `<img style="width:100%" src=${inst_9} alt='instruction9'/>`+
            '<br/>7. On the new page you need to select “Derivatives” and then click on the “Verify” button.'+
            `<img style="width:100%" src=${inst_10} alt='instruction10'/>`+
            `<img style="width:100%" src=${inst_11} alt='instruction11'/>`+
            '<br/>8. Provide contact information for verification and upload the required documents to confirm your identity. You will have to wait approximately 5-10 minutes to be verified but it may take a longer time if there is a lot of traffic coming to the exchange.'+
            '<br/>9. After being successfully verified, you need to open Account Settings and click on “API Management”.'+
            `<img style="width:100%" src=${inst_13} alt='instruction13'/>`,
            
            '<br/>10.  The only checkbox you need to enable is "Trade". Create a name in the “Notes” section. Then click the button “Create”.'+
            `<img style="width:100%" src=${inst_14} alt='instruction14'/>`+
            '<br/>11. Enable two checkboxes as it is shown on the screenshot below. You will receive a confirmation email with the code.'+
            `<img style="width:100%" src=${inst_15} alt='instruction15'/>`+
            `<img style="width:100%" src=${inst_16} alt='instruction15'/>`+
            '<br/>12. Сopy both the  “Access key” and “Secret key” to your notes.'+
            `<img style="width:100%" src=${inst_17} alt='instruction15'/>`+
            '<br/>13. You need to set up a futures account, so the Bitok bot will be able to do its best work. This action should be done after the verification and API creation.'+
            '<br/>14. In the “Derivatives” section, select “USDT-margined Contracts”.'+
            `<img style="width:100%" src=${inst_18} alt='instruction16'/>`+
            '<p class="reqister_bitquant_link">15. You need to follow the next steps:<br/><br/>1. Select BTC/USDT pair.<br/>2. Select “Cross”.<br/>3. At the right angle you need to click the red button and move the slider to its maximum value - 200X. Click the “Confirm” button.<br/>4. The pop-up will appear, you need to enable the checkbox and click the “Confirm” button.<br/>5. Check if everything looks exactly as it is shown on the screenshot below.</p>',
          
            `<img style="width:100%" src=${inst_19} alt='instruction16'/>`+
            '<br/>16. Repeat the same steps for ETH-USDT pair.'+
            '<p>17. You need to have a positive balance on your futures account for Bitok to start trading. Follow the next steps:<br/><br/>1. On the main page open section “Balances” and click on “Assets Overview”.<br/>2. Then click the “Transfer” button.<br/>3. You need to set up Transfer Direction.<br/>From: Spot account - to: USDT-margined Contracts Account.<br/>4.Enter the transfer amount for the strategy you chose.<br/>5.Click the “Transfer” button.</p>'+
            `<img style="width:100%" src=${inst_20} alt='instruction18'/>`+
            `<img style="width:100%" src=${inst_21} alt='instruction18'/>`+
            `<img style="width:100%" src=${inst_22} alt='instruction18'/>`,

            `<img style="width:100%" src=${inst_19} alt='instruction19'/>`+
            '<br/>18. Go to the bitok.pro and register your account.'+
            `<br/>19. Fill in the appropriate fields with your info. `+
            '<br/>20. Login and you will be redirected to your personal account.'+
            `<br/>21. Go to the “Settings” section.`+
            '<br/>22. Click "Configure API" in the specifications window that opens.'+
            '<br/>23. Enter into the relevant fields API keys, which you created in the Huobi exchange before. Copy-paste.'+
            '<br/>24. Then click "Save" to proceed to the next step. You need to confirm the activation of the API keys with the password you have specified during registration on our service CryPPush.net. Click on "Save" to finish.'+
            '<br/>25. Next, you need to click on the "Launch Huobi" button.'+
            '<br/>26. After that, you need to re-confirm the action with the password you specified when registering on our service bitok.pro. To do this, click on "Confirm".'+
            '<br/>27. You will receive a notification that the Huobi API is now Active.'+
            '<br/>28. Congratulations! Now you can watch your portfolio statistics on the “Trade” page.'
        ],
        video: "Kg1OVtDTWk8"
    }],
    ua: [{
        consist: [
            "1. Registration via our referral link on the <a href='https://www.huobi.com/'>www.huobi.com</a>.",
            "2. Verification of the account on the <a href='https://www.huobi.com/'>www.huobi.com</a>.",
            "3. Creation of API keys on <a href='https://www.huobi.com/'>www.huobi.com</a>, necessary to connect to our service CryPPush.net.",
            "4. Setting up a workspace on <a href='https://www.huobi.com/'>www.huobi.com</a>. The preparatory stage before launching.",
            '5. Registration on CryPPush.net.',
            '6. Connecting an account on CryPPush.net.',
            '7. Monitoring trading activities from the Huobi app on your mobile device.'
        ],
        content: [
            "<p class='register_link'>1. Відкрийте <a href='https://www.huobi.com/ru-ru/topic/double-reward/?invite_code=x9dpc'>www.huobi.com</a> і натисніть кнопку “Sign Up”, щоб створити новий обліковий запис (якщо у вас вже є такий, створювати новий немає необхідності).</p>"+
            '<br/>2. Кнопка “Sign Up” перекине вас на початкову сторінку, де вам потрібно буде ввести свій email, пароль та реферальне посилання (останнє не обов`язково).  Після цього, ви проходите капчу та підтвердження email`а, яке прийде на вказану вами при реєстрації пошту.'+
            `<img style="width:100%;margin-top:30px" src=${inst_1} alt='instruction1'/>`+
            `<img style="width:100%" src=${inst_2} alt='instruction2'/>`+
            `<img style="width:100%" src=${inst_3} alt='instruction3'/>`+
            `<img style="width:100%" src=${inst_4} alt='instruction4'/>`,

            '<br/>3. Поповніть рахунок на біржі (ви можете це зробити як карткою, так і крипто гаманцем).'+
            `<img style="width:100%" src=${inst_5} alt='instruction5'/>`+
            '<p class="verification_link">4. Наступні кроки будуть доступні лише за умови проходження верифікації</p>'+
            '<br/>5. Відкрийте налаштування облікового запису на головній сторінці біржі та виберіть вкладку “Identification”'+
            `<img style="width:100%" src=${inst_7} alt='instruction7'/>`,

            '<br/>6. Після цього виберіть “Personal Verification”'+
            `<img style="width:100%" src=${inst_9} alt='instruction9'/>`+
            '<br/>7. На сторінці, що з`явилася, виберіть вкладку “Derivatives” і натисніть кнопку “Verify”.'+
            `<img style="width:100%" src=${inst_10} alt='instruction10'/>`+
            `<img style="width:100%" src=${inst_11} alt='instruction11'/>`+
            '<br/>8. Надайте всю необхідну інформацію та документи, необхідні біржею для проходження верифікації.  У середньому процес схвалення заявки на верифікацію облікового запису займає 5-10 хвилин.'+
            '<br/>9. Після успішного проходження верифікації, відкрийте налаштування облікового запису та натисніть на “API Settings”'+
            `<img style="width:100%" src=${inst_13} alt='instruction13'/>`,
            
            '<br/>10. Єдина опція, яку вам потрібно буде вибрати це “Trade”, поставте біля нього галочку.  Після цього натисніть “Create”.'+
            `<img style="width:100%" src=${inst_14} alt='instruction14'/>`+
            '<br/>11. У вікні поставте дві галочки так, як це показано на екрані.  Після цього ви отримаєте лист-підтвердження із кодом на пошту.'+
            `<img style="width:100%" src=${inst_15} alt='instruction15'/>`+
            `<img style="width:100%" src=${inst_16} alt='instruction15'/>`+
            '<br/>12. Скопіюйте “Access key” та “Secret key” собі в нотатки (вони знадобляться нам у майбутньому).'+
            `<img style="width:100%" src=${inst_17} alt='instruction15'/>`+
            '<br/>13. Після створення API ключа та проходження верифікації вам потрібно створити Futures акаунт.'+
            '<br/>14. У розділі “Derivatives” виберіть “USDT-margined Contracts”.'+
            `<img style="width:100%" src=${inst_18} alt='instruction16'/>`+
            '<p class="reqister_bitquant_link">15. Вам потрібно буде виконати такі дії:<br/><br/>1. Виберіть пару BTC/USDT.<br/>2. Натисніть Cross.<br/>3. У правому куті натисніть на червону кнопку і посуньте повзунок на максимальне значення (200Х) після чого підтвердіть дію, натиснувши “Confirm”.<br/>4. Випливе нове вікно, на якому ви повторно підтверджуєте свої дії, поставивши галочку і натиснувши на “Confirm”.<br/>5. Порівняйте те, що вийшло у вас зі скріншотом, щоб переконатися, що ви зробили все правильно.</p>',
            
            `<img style="width:100%" src=${inst_19} alt='instruction16'/>`+
            '<br/>16. Повторіть ці дії для пари ETH-USDT.'+
            '<p>17. Для того, щоб Bitok bot зміг розпочати торгівлю, вам потрібно поповнити свій futures обліковий запис.  Для цього виконайте такі дії:<br/><br/>1. На головній сторінці відкрийте розділ “Balance” та натисніть на “Assets Overview”. <br/>2. Натисніть “Transfer”.<br/>3. Після цього виберіть рахунки, на яких буде здійснюватися переказ Від: Spot account - to: USDT-margined Contracts Account.<br/>4.Введіть суму відповідно до придбаної вами стратегії.<br/>5.Натисніть “Transfer”.</p>'+
            `<img style="width:100%" src=${inst_20} alt='instruction18'/>`+
            `<img style="width:100%" src=${inst_21} alt='instruction18'/>`+
            `<img style="width:100%" src=${inst_22} alt='instruction18'/>`,

            `<img style="width:100%" src=${inst_19} alt='instruction19'/>`+
            '<br/>18. Зайдіть на cryppush.net та зареєструйтесь.'+
            `<br/>19. Заповніть всі відповідні поля необхідною інформацією. `+
            '<br/>20. Увійдіть до облікового запису.  Після цього у вас відкриється особистий кабінет.'+
            `<br/>21. Відкрийте розділ “Settings”.`+
            '<br/>22. Натисніть “Configure API”.'+
            '<br/>23. Введіть у відповідні поля раніше скопійовані API ключі (що ми раніше створювали на www.huobi.com).'+
            '<br/>24. Натисніть “Save”, щоб перейти до наступного кроку.  Вам потрібно буде підтвердити активацію ключів API паролем, який ви створили реєструючись на cryppush.net.  Після цього натисніть “Save”.'+
            '<br/>25. Після цього натисніть на “Launch Huobi”.'+
            '<br/>26. Вам потрібно буде повторно підтвердити дію, використовуючи пароль, який ви створили реєструючись на cryppush.net.  Натисніть “Confirm”, щоб зробити це.'+
            '<br/>27. Ви отримаєте сповіщення про те, що Huobi API активний.'+
            '<br/>28. Вітаємо!  Тепер ви можете спостерігати за роботою бота на вкладці "Trade".'
        ],
        video: "Cwh8ZG2Hosc"
    }]
}