import React,{useState} from "react"
import Title from '../components/Title/Title'
import FaqItem from "../components/FaqItem/FaqItem"
import { useTranslation } from 'react-i18next'

const Faq = () => {
    const { i18n } = useTranslation();
    return(
        <div className="trade-data">
            <Title title={"FAQ"}/>
            <div className="setting_container faq_item">
                {i18n.language === 'en' ? <>
                <FaqItem description="Create an account on Cryppush.net<br/>
                (Open the main page and click the “Let's Get Started “ button)<br/>
                2. Choose the subscription plan that suits you best<br/>
                3. Follow “Tutorial” and set up all the settings" 
                title="How to start trading with Cryppush?"/>
                <FaqItem
                description="Our bot is developed by the Professional Traders Team and based on the best strategies and algorithms. It can generate profit up to 30% monthly."
                title="How much profit can Cryppush bot generate per month?"/>
                <FaqItem
                description="We provide trial subscription promo codes on our Instagram, Telegram, Twitter  and in Partners groups/sources. Follow our socials and keep in touch!" 
                title="How to claim trial subscription?"/>
                <FaqItem
                description="Here are 3 options on how to claim a free trial subscription.
                <br/>
                1. Promo codes in our Instagram/Telegram groups<br/>
                2. Partners channels/sources and collaborations<br/>
                3. Sign up for our webinar on the main page and be present<br/><br/>
                Follow our socials for more info. Stay tuned!"
                title="How to take a trial subscription?"/>
                <FaqItem
                description="The reason why we offer only $300, $500 and $1000 deposit options is that our bot gives the best results on these deposit amounts. 
                <br/><br/>
                You are free to create different Bitok accounts and connect different APIs from different exchange accounts."
                title="Why does Bitok have only 3 deposit optiBitokons?"/>
                <FaqItem 
                description="To connect API to the Bitok account - you need to follow all mentioned steps:
                <br/><br/>
                Open your Bitok Personal Account ( bitok.pro )<br/>
                Go to the “Tutorial” section<br/>
                Follow the guide and connect your API<br/>"
                title="How to connect API to the Bitok account?"/>
                <FaqItem 
                description="You can view your trades both on the exchange application and your account on Bitok"
                title="Where can You view all the trading processes?"/>
                <FaqItem 
                description="The approximate ROI of Bitok Bot is up to 30% monthly. Market conditions and stages may affect the final results. Our algorithmic system shows the most stable returns on investments during long periods."
                title="Does Bitok Bot guarantee stable monthly profits?"/>
                 <FaqItem 
                description="Bitok uses the same strategy for all the subscription plans. Options may vary for the client's suitability only in the aspects of subscription duration, trading budgets, and pricing."
                title="Is there any difference between Bitok Official subscriptions?"/>
                 <FaqItem 
                description="Bitok tech team spent a lot of time working on all the safety aspects of our Bot, so you can be sure that your account is safe with us. We have no permission to withdraw your funds, so in the unlikely event of a data breach, it’s still impossible for hackers to access your funds. The exchanges we work with are known to spend a lot of time and money on their safety, so your funds should be secure with them too.
                <br/><br/>
                If you want extra security enabled on your account, we suggest you activate two-factor authenBitoktication (2FA). This helps in securing your account."
                title="Is Bitok safe to use?"/>
                </> : i18n.language === 'ru'?
                <>
                  <FaqItem description="1. Создайте учетную запись на Bitok.net<br/>
                    (Откройте главную страницу и нажмите «Начать»)<br/>
                    2. Выберите наиболее подходящий план подписки<br/>
                    3. Следуйте руководству и установите все настройки<br/>" 
                    title="Как начать торговать с Bitok?"/>
                    <FaqItem 
                    description="Наш бот разработан командой профессиональных трейдеров и основан на лучших стратегиях и алгоритмах. Он может приносить прибыль до 30% в месяц." 
                    title="Какую прибыль может приносить бот Bitok в месяц?"/>
                    <FaqItem 
                    description="Мы предоставляем промокоды пробной подписки в наших Instagram, Telegram, Twitter и в группах/источниках наших партнеров. Подписывайтесь на наши соцсети и оставайтесь на связи!" 
                    title="Как получить пробную подписку?"/>
                    <FaqItem 
                    description="Есть 3 варианта получения бесплатной пробной подписки:<br/>
                    1. Промокоды в наших Instagram/Telegram группах<br/>
                    2. Каналы/источники наших партнеров<br/>
                    3. Записаться на наш вебинар на главной странице<br/>
                    Следите за нашими обновлениями в социальных сетях для получения дополнительной информации." 
                    title="Как запросить пробную подписку?"/>
                    <FaqItem 
                    description="
                    Причина, по которой мы предлагаем варианты депозита только на 300, 500 и 1000 долларов, заключается в том, что наш бот дает наилучшие результаты при этих суммах депозита.
                    <br/><br/>
                    Вы можете создавать разные учетные записи Bitok и подключать разные API из разных учетных записей биржи."
                    title="Почему у Bitok всего 3 варианта депозита?"/>
                    <FaqItem 
                    description="Иногда ваш провайдер электронной почты может фильтровать электронные письма Bitok в папки СПАМ или в промоакции. Пожалуйста, проверьте наличие письма в этих папках."
                    title="Не пришло электронное письмо с подтверждением"/>
                    <FaqItem 
                    description="Чтобы подключить API к учетной записи Bitok, вам необходимо выполнить все указанные шаги:<br/>
                    1. Откройте Личный кабинет Bitok ( bitok.pro )<br/>
                    2. Перейти в раздел «Обучение»<br/>
                    3. Следуйте инструкциям и подключите свой API" 
                    title="Как подключить API к аккаунту Bitok?"/>
                    <FaqItem 
                    description="Вы можете просматривать свои сделки как в приложении биржи, так и в личном кабинете на Bitok."
                    title="Где Вы можете просмотреть все торговые процессы?"/>
                    <FaqItem 
                    description="Примерный ROI Bitok Bot составляет до 30% в месяц. Рыночные условия и этапы могут повлиять на конечные результаты. Наша алгоритмическая система показывает наиболее стабильную доходность инвестиций на длительных периодах."
                    title="Гарантирует ли Bitok Bot стабильную ежемесячную прибыль?"/>
                    <FaqItem 
                    description="Bitok использует одну и ту же стратегию для всех планов подписки. Варианты могут различаться в зависимости от клиента только в аспектах продолжительности подписки, торговых бюджетов и ценообразования."
                    title="Есть ли разница между официальными подписками Bitok?"/>
                    <FaqItem 
                    description="Техническая команда Bitok уделила много времени, работая над всеми аспектами безопасности нашего бота, поэтому вы можете быть уверены, что ваша учетная запись в безопасности с нами. У нас нет разрешения на вывод ваших средств, поэтому в маловероятном случае утечки данных - хакеры все равно не смогут получить доступ к вашим средствам. Известно, что биржи, с которыми мы работаем, тратят много времени и денег на их безопасность, поэтому ваши средства также должны быть в безопасности с ними.
                    <br/><br/>
                    Если вы хотите, чтобы в вашей учетной записи была включена дополнительная безопасность, мы рекомендуем вам активировать двухфакторную аутентификацию (2FA). Это поможет защитить вашу учетную запись." 
                    title="Безопасно ли использовать Bitok?"/>
                </> : 
                <>
                <FaqItem description="1. Створіть обліковий запис на bitok.pro<br/>
                    (Відкрийте головну сторінку та натисніть «Почати»)<br/>
                    2. Виберіть найбільш цікавий для вас план підписки<br/>
                    3. Дотримуйтесь посібника та встановіть усі налаштування<br/>" 
                    title="Як почати торгувати з Bitok?"/>
                    <FaqItem 
                    description="Наш бот розроблений командою професійних трейдерів і заснований на найкращих стратегіях та алгоритмах.  Бот може давати в середньому прибуток до 30% на місяць." 
                    title=" Який прибуток може приносити бот Bitok на місяць?"/>
                    <FaqItem 
                    description="Ми надаємо промокоди на знижку в наших Instagram, Telegram, Twitter та групах/джерелах наших партнерів.  Підписуйтесь на наші соцмережі та залишайтеся на зв'язку!<br/>
                    <br/>Ви можете скористатися промокодом на знижку в розділі «Партнерська система»." 
                    title="Як використати промокод на підписку?"/>
                    <FaqItem 
                    description="Є 3 варіанти отримання промокоду:<br/>
                    1. Промокоди в наших Instagram/Telegram групах<br/>
                    2. Канали/джерела наших партнерів<br/>
                    3. Записатись на наш вебінар на головній сторінці<br/>
                    <br/>Слідкуйте за нашими оновленнями у соціальних мережах для отримання додаткової інформації." 
                    title="Де можна знайти промокоди на підписки Bitok.online  Bitok?"/>
                    <FaqItem 
                    description="Причина, через яку ми пропонуємо варіанти депозиту тільки на 300, 500 і 1000 доларів, полягає в тому, що наш бот дає найкращі результати за таких розмірів депозиту.
                    <br/><br/>
                    Ви можете створювати різні облікові записи Bitok та підключати різні API з різних облікових записів біржі."
                    title="Чому Bitok має лише 3 варіанти депозиту?"/>
                    <FaqItem 
                    description="Іноді ваш провайдер електронної пошти може фільтрувати електронні листи Bitok у папки СПАМ або в акції.  Будь ласка, перевірте наявність листа у цих папках."
                    title="Не прийшов електронний лист із підтвердженням"/>
                    <FaqItem 
                    description="Щоб підключити API до облікового запису Bitok, вам необхідно виконати всі ці кроки:
                    <br/>
                    - Відкрийте Особистий кабінет Bitok (bitok.pro)<br/>
                    - Перейдіть до розділу «Інструкція»<br/>
                    - Дотримуйтесь інструкцій та підключіть свій API<br/>" 
                    title=" Як підключити API до облікового запису Bitok?"/>
                    <FaqItem 
                    description="Ви можете переглядати результати торгівлі як у додатку біржі, так і в особистому кабінеті Bitok."
                    title="Де Ви можете переглянути всі торгові процеси?"/>
                    <FaqItem 
                    description="Орієнтовний ROI Bitok Bot складає до 30% на місяць. Ринкові умови та етапи можуть вплинути на кінцеві результати. Наша алгоритмічна система показує найбільш стабільну прибутковість інвестицій на тривалі періоди."
                    title=" Чи гарантує Bitok Bot стабільний щомісячний прибуток?"/>
                    <FaqItem 
                    description="Bitok використовує одну стратегію для всіх планів підписок. Варіанти можуть відрізнятися залежно від клієнта лише в аспектах тривалості торгівлі, торгових бюджетів та ціноутворення."
                    title="Чи є різниця між офіційними підписками Bitok?"/>
                    <FaqItem 
                    description="Технічна команда Bitok приділила багато часу, працюючи над усіма аспектами безпеки нашого бота, тому ви можете бути впевнені, що ваш обліковий запис у безпеці з нами.  Ми не маємо дозволу на виведення ваших коштів, тому є малоймовірним  випадок витоку даних - хакери все одно не зможуть отримати доступ до ваших коштів. Відомо, що біржі, з якими ми працюємо, витрачають багато часу та грошей на їхню безпеку, тому ваші кошти також мають бути у безпеці з ними.
                    <br/><br/>
                    Якщо ви хочете, щоб у вашому обліковому записі була ввімкнена додаткова безпека, ми рекомендуємо вам активувати двофакторну аутентифікацію (2FA).  Це допоможе захистити ваш обліковий запис." 
                    title=" Чи безпечно використовувати Bitok?"/>
                </>}
            </div>
        </div>
    )
}

export default Faq