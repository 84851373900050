import React from 'react'
import { useTranslation } from 'react-i18next'

const Title = (props) => {
  const {title,description,balance} = props
  const { t } = useTranslation();
  return(
    <div className="title_container_page">
      <h1>{title}</h1>
      {/*<p style={{
        marginBottom: balance? 10 : ''
      }}>description</p>*/}
      {balance? <p style={{fontSize:12,maxWidth:800}}>{t('nottBal')}</p>: null}
    </div>
  )
}

export default Title
