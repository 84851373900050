import React,{useState,useEffect} from 'react'
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import {getPromo,postPromo,delPromo} from '../../api/api.js'
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next'

const Promo = () => {
    const [value, setValue] = useState(Date.now());
    const [promo,setPromo] = useState([])
    const [rerender,setRerender] = useState(false)
    const [name,setName] = useState('');
    const [percent,setPercent] = useState('');
    const [count,setCount] = useState('');
    const userdata = JSON.parse(localStorage.getItem("userData"))
    const notifyError = (data) => toast.error(data);
    const notifyInf = (data) => toast.info(data);
    const { t } = useTranslation();

    useEffect(() => {
        getPromo(setPromo)
    },[rerender])

    const createPromo = () => {
        if(name.length !== 0 && 
            percent.length != 0 &&
            percent <= 100 && 
            percent > 0 && 
            count.length != 0){
                if(promo.filter(el => el.name == name).length > 0){
                    notifyError('Promo with this name exist')
                }else{
                    postPromo(userdata.email,userdata.session,name,percent,count,value,rerender,setRerender,notifyInf)
                    setCount('')
                    setPercent('')
                    setName('')
                }
            }else{
                notifyError('Type required and correct values')
            }
    }

    const deleteThisPromo = (id) => {
        delPromo(id,rerender,setRerender,notifyError,notifyInf)
    }

    return(
        <div className="setting_container">
            <div className='admin_profile'>
                <div>
                    <h3>{t("promo_create")}</h3>
                    <div className='promo_container_panel'>
                        <div className='promo_container_item'>
                            <p>{t("promo_name")}</p>
                            <input 
                            value={name}
                            onChange={(event) => setName(event.target.value)} 
                            className='simple_promo_input' 
                            type="text" />
                        </div>
                        <div className='promo_container_item'>
                            <p>{t("discount_percent")}</p>
                            <input
                            value={percent}
                            onChange={(event) => setPercent(event.target.value)} 
                            className='simple_promo_input' 
                            type="number" />
                        </div>
                        <div className='promo_container_item'>
                            <p>{t("using_amount")}</p>
                            <input 
                            value={count}
                            onChange={(event) => setCount(event.target.value)}
                            className='simple_promo_input' 
                            type="text" />
                        </div>
                        <div className='promo_container_item'>
                        <p></p>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            value={value}
                            minDate={Date.now()}
                            onChange={(newValue) => {
                                setValue(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        </div>
                    </div>
                    <button onClick={() => createPromo()} className='button_promocode'>Создать промокод</button>

                    <div className='history-promo-container'>
                        <h2>{t("promo_list")}</h2>
                        <table>
                            <thead>
                                <tr>
                                    <td>№</td>
                                    <td>{t("promo_onec_name")}</td>
                                    <td>{t("active_to")}</td>
                                    <td>{t("percent")}</td>
                                    <td>{t("amont")}</td>
                                </tr>
                            </thead>
                            <tbody>
                                {promo.map((el,key) => 
                                    <tr key={key}>
                                        <td>{key+1}</td>
                                        <td>{el.name}</td>
                                        <td>{el.date}</td>
                                        <td>-{el.percent}%</td>
                                        <td>{el.count}</td>
                                        <td><button className='delete_proxy_button' onClick={() => deleteThisPromo(el._id)}>Удалить</button></td>
                                    </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </div>
    )
}

export default Promo