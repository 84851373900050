import React from 'react';
import {connect} from 'react-redux'
import HistoryTrades from "../components/HistoryTrades";

const History = (props) => {
  const {tradeType,filterTrade} = props
    return (
        <div className="history-trades-wrap">
            <HistoryTrades
            type={'main'}
            filterTrade={filterTrade}
            tradeType={tradeType}/>
        </div>
    );
}
const mapDispatchToProps = {
    //createPost, showAlert
}

const mapStateToProps = state => ({
    //balance: state.binary.balance,
})

export default connect(mapStateToProps, mapDispatchToProps)(History)
