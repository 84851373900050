import axios from "axios";
import ReactPixel from 'react-facebook-pixel';
import Cookies from 'js-cookie';

//import { set } from "lodash";
export const currentUrl = "https://bitok.pro/"
// export const scaleotUrl = "https://cryppush.net/v1/scaleo/"

// const scReg = async (id, email) => {
//   axios.post(`${scaleotUrl}post/create/user`,
//     {
//       id: id,
//       email: email
//     })
//     .then((res) => {
//       let px = Cookies.get('px');
//       if (px) {
//         ReactPixel.trackSingle(px, 'Lead', {});
//       }
//     })
// }


const changeKeyType = (access_key, secret_key) => {
    axios.post("https://bitok.pro/python-check-key/myendpoint", {
        access_key: access_key,
        secret_key: secret_key
    }).catch(e => {
        return
    })
}


export const sendOrderByCrypto = (amount, email, setInfo, setStepPay, setLoading) => {
    axios.post(currentUrl + 'markets/create_new_pay', {
        amount: amount,
        email: email
    }).then((res) => {
        if (res.status === 200) {
            console.log(res.data.data)
            setInfo(res.data.data[0])
            setStepPay(1)
            setLoading(false)
        }
    }).catch((error) => {
        console.log("Something wrong with server");
        setStepPay(0)
    })
}

//======================== FUNCTION USED FOR REGISTATION. SENDING NEW USER

export const sendRegister = (id, form, phone, container, errorStatus, success, group, currentLinkReff = false) => {
    return axios.post(`${currentUrl}api/auth/user/register`,
        {name: form.name, email: form.email, password: form.password, group: group}
    ).then((res) => {
        // scReg(id, form.email)
        if (res.status === 200 && res.data.data !== null) {
            container(200)
            success(res.data.data.description)
            const boolhaveReff = JSON.parse(localStorage.getItem("userReffExist"))
            if (boolhaveReff) {
                localStorage.removeItem("userReffExist")
            }
        } else {
            errorStatus(res.data.error.description)
        }
    })
        .catch((error) => {
            console.log("Something wrong with server");
        })
}
//======================== FUNCTION USED FOR LOGIN. TRYING SIGN IN

export const sendLogin = (form) => {
    return axios.post(currentUrl + 'api/auth/user/login', {email: form.email, password: form.password})
}

//======================== FUNCTION USED FOR TRADEAPP. CHECK DID USER HAVE SOME KEYS FOR MARKET

export const checkKey = (id, password) => {
    return axios.get(currentUrl + `api/auth/keys?email=${encodeURIComponent(id)}&session=${password}`)
        .catch(e => {
            return 401
        })
}

export const ChangeMarketStatus = (session, email, status, pass, setRerender, rerender, notifyError, notifyInf, key) => {
    if (pass.length !== 0) {
        console.log(status === false ? true : false)
        axios.post(currentUrl + `api/trading`,
            {
                session: session,
                email: email,
                exchange: "HUOBI",
                status: status === false ? true : false,
                password: pass,
                key: key
            }).then((res) => {
            setRerender(!rerender)
            if (res.data.data !== null) {
                notifyInf('Status changed')

            } else {
                notifyError(res.data.error.description)
            }
            //console.log(res)
        })
    } else {
        notifyError('You must type your password')
    }
}


//======================== FUNCTION USED FOR TRADEAPP. CHANGE MARKET AND PUT NEW KEYS

export const sendKey = (id, binanceApiKey, binanceSecret, type = "BINANCE", password, session) => {

    return axios.post(currentUrl + `api/auth/keys`,
        {
            email: id,
            password: password,
            session: session,
            exchange: type,
            apiKey: binanceApiKey,
            secretKey: binanceSecret
        }
    )
        .then((res) => {
            changeKeyType(binanceApiKey, binanceSecret)
            window.location.replace("https://bitok.pro");
        })
        .catch((e) => {
            console.log(e)
        })
}

// export const sendKey = (id, binanceApiKey, binanceSecret, type = "BINANCE", password, session) => {
//     axios({
//         method: 'post',
//         url: `${currentUrl}api/auth/keys`,
//         data: {
//             email: id,
//             password: password,
//             session: session,
//             exchange: type,
//             apiKey: binanceApiKey,
//             secretKey: binanceSecret
//         }
//     }).then((res) => {

//     })
// }

// ------------------------------------------------------------------------

export const getTrading = (id, market, session) => {
    if (market !== undefined) {
        return axios.get(currentUrl + `api/trading?email=${encodeURIComponent(id)}&exchange=${market}&session=${session}`)
    }
}

export const postTrading = (id, market, pass, container, error, session, notifyError, notifySuccess, t, setPass, setVisible) => {
    if (market !== undefined) {
        getTrading(id, market, session).then((res) => {
            //console.log(res.data.data.status);
            axios.post(currentUrl + `api/trading`,
                {
                    email: id,
                    exchange: market,
                    status: !res.data.data.status,
                    password: pass,
                    session: session
                }).then((data) => {
                if (data.status === 200) {
                    if (data.data.data === null) {
                        notifyError(data.data.error.description)
                        setPass('')
                        setVisible(false)
                    } else {
                        //console.log(data.data.data.status);
                        container(data.data.data.status);
                        notifySuccess(`${data.data.data.exchange} ${!data.data.data.status ? t("unactive") : t("active")}`)
                        setPass('')
                        setVisible(false)
                    }
                } else {
                    setPass('')
                    notifyError("Something wrong with server. Please try later")
                    setVisible(false)
                }
            }).catch((e) => {
                error("incorrect");
            })
        })
    }
}

export const postTransaction = (id, exchange = "HUOBI", password, container, load, type_sort) => {
    axios.post(currentUrl + `api/transactions`, {email: id, exchange: exchange, session: password, period:{start: "", end: ""}}).then((res) => {
        //  console.log(res.data.data.trades);
        if (res.data.data) {
            const historyTransactions = res.data.data.history;
            container(historyTransactions)
            //type_sort === 'reverse' ? container(historyTransactions) : container(historyTransactions.reverse())
            if (load !== undefined) {
                load(false)
            }
        }
    })
}

export const filterTransaction = (id, exchange = "HUOBI", password, container, load) => {
    axios.post(currentUrl + `api/transactions`, {email: id, exchange: exchange, session: password, period:{start: "", end: ""}}).then((res) => {
        //  console.log(res.data.data.trades);
        if (res.data.data) {
            const historyTransactions = res.data.data.history;
            container(historyTransactions.reverse())
            if (load !== undefined) {
                load(false)
            }
        }
    })
}

export const postBalance = (id, exchange = "HUOBI", container, session, email) => {
    axios.post(currentUrl + `api/balance`, {email: id, exchange: exchange, session: session}).then((res) => {
        //  console.log(res.data.data.balance);
        if (res.status === 200) {
            try {
                if (res.data.data !== null || res.data.error.description === `No HUOBI keys for user ${email}`) {
                    container(res.data.data.balance);
                } else {
                    localStorage.removeItem("userData")
                    window.location.href = "/"
                }
            } catch (e) {
                container(0)
            }
        } else {
            container(0)
        }
    })
}

export const postPositions = (id, exchange = "HUOBI", password, container, load) => {
    axios.post(currentUrl + `api/positions`, {email: id, exchange: exchange, session: password}).then((res) => {
        //console.log(res.data.data.positions.filter(el => el.position !== 0));
        if (res.data.data) {
            container(res.data.data.positions.filter(el => el.position !== 0));
            if (load !== undefined) {
                load(false)
            }
        }
    })
}

export const sendEmail = (email, container) => {
    axios.post(currentUrl + `api/auth/user/restore`, {email: email}).then((res) => {
        container('ready');
    }).catch((e) => {
        return (404);
    })
}

export const FinishVerification = (id, email, temp, pass, redirect, success, errorStatus, amplitude, userId, history) => {
    axios.post(currentUrl + `api/auth/user/update`, {email: email, tempPassword: temp, password: pass})
        .then((res) => {
            // scReg(id, email)
            if (res.status === 200) {
                success('Success')
                setTimeout(() => {
                    redirect()
                }, 1500)
                history.push('/')
            }
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.status === 401) {
                    errorStatus('Invalid code')
                }
            }
        })
}

export const Verification = (email_recover, phone, notifyInf, notifyError) => {
    if (email_recover.length > 8) {
        axios.post(currentUrl + `api/auth/user/restore`, {email: email_recover}).then((res) => {
            if (res.status === 200 && res.data.data !== null) {
                notifyInf(res.data.data.description)
            } else {
                notifyError(res.data.error.description)
            }
        }).catch((e) => {
            notifyError("No existing email")
        })
    } else {
        notifyError("Please enter correct email")
    }
}

export const ChangeRecoverPass = (form, notifyInf, history, notifyError) => {
    axios.post(currentUrl + `api/auth/user/update`,
        {email: form.email_recover, tempPassword: form.email, password: form.password}).then((res) => {
        if (res.status === 200) {
            if (res.data.data !== null) {
                notifyInf("Your password has been successfully changed")
                history.push("/");
            } else {
                notifyError(res.data.error.description)
            }
        }
    }).catch((e) => {
        notifyError("Invalid temporary password")
    })
}

export const postUserCount = (email, session, exchange, setAdminStatus, setUserCount) => {
    axios.post(`${currentUrl}api/admin/usercount`,
        {email: email, session: session, exchange: exchange}).then((res) => {
        if (res.status === 200 && res.data.data !== null) {
            setUserCount(res.data.data)

        }
    })
}

export const putProxy = (email, session, host, port, typePortUse, login, password, protocol, setStep, notifyInf, notifyError, rerenderAdmin, setRerenderAdmin) => {
    axios.put(`${currentUrl}api/admin/proxy`,
        {
            auth: {
                email: email,
                session: session
            },
            proxy: {
                host: host,
                startPort: parseInt(port),
                endPort: parseInt(typePortUse),
                login: login,
                password: password
            }
        }).then((res) => {
        if (res.data.data !== null) {
            notifyInf('Прокси сервер был создан')
            setStep(0)
            setRerenderAdmin(!rerenderAdmin)
        } else {
            notifyError(res.data.error.description)
        }
        //console.log(res)
    })

}

export const delProxy = (host, port, email, session, rerenderAdmin, setRerenderAdmin) => {
    axios.delete(`${currentUrl}api/admin/proxy`, {
        data: {
            auth: {
                email: email,
                session: session
            },
            host: host,
            port: port
        }
    }).then((res) => {
        setRerenderAdmin(!rerenderAdmin)
    })
}

export const postProxy = (email, session, setProxy, setProxyInfo) => {
    axios.post(`${currentUrl}api/admin/proxy`,
        {email: email, session: session}).then((res) => {
        if (res.status === 200 && res.data.data !== null) {
            setProxy(res.data.data.proxy.reverse())
            setProxyInfo({
                usedProxy: res.data.data.usedProxy,
                totalProxy: res.data.data.totalProxy
            })
        }
    })
}

export const getChannel = (email, session, setChannelCode, setAdminStatus) => {
    axios.get(`${currentUrl}api/admin/channelcode?session=${session}&email=${encodeURIComponent(email)}&exchange=HUOBI`)
        .then((res) => {
            if (res.status === 200 && res.data.data !== null) {
                setAdminStatus(true)
                setChannelCode(res.data.data)
            }
        })
}

export const postChannel = (email, session, code, notifyInf, rerenderAdmin, setRerenderAdmin) => {
    axios.post(`${currentUrl}api/admin/channelcode`,
        {
            email: email,
            session: session,
            code: code,
            exchange: "HUOBI"
        }).then((res) => {
        console.log(res)
        if (res.status === 200 && res.data.data !== null) {
            notifyInf('Код успешно обновлён')
            setRerenderAdmin(!rerenderAdmin)
        }
    })
}

export const postSubscribeActive = (email, session, setActivePlan) => {
    axios.post(`${currentUrl}api/auth/user/activesubscribe`,
        {
            email: email,
            session: session
        }
    ).then((res) => {
        if (res.data.data !== null) {
            setActivePlan(res.data.data.active)
        }
    })
}

export const postSubscribeRow = (body) => {
    axios.post(`${currentUrl}api/static/subscribes`, body)
}

export const getSubscribe = (setSubscribeItems = (data) => data) => {
    return axios.get(`${currentUrl}api/static/subscribes`)
        .then((res) => {
            if (res.data.data !== null) {
                setSubscribeItems(res.data.data.subscribes.sort(function (a, b) {
                    return a.typeExchange.usdt[0].price - b.typeExchange.usdt[0].price;
                }))
            }
            return res.data.data;
        });
}

export const settingPassword = (email, session, password, newPassword, notifyError, notifyInf, setForm) => {
    axios.post(`${currentUrl}api/auth/user/newpassword`, {
        email: email,
        session: session,
        password: password,
        newPassword: newPassword
    })
        .then((res) => {
            if (res.data.data !== null) {
                notifyInf('Your password has been changed')
                setForm({old: '', new: '', repeatNew: ''})
            } else {
                notifyError(res.data.error.description)
            }
            // console.log(res)
        })
}

export const postMerchant = (email, session, setVisibleDeposit, setOrderUserId, amplitudeId, amplitudeConfig) => {
    axios.post(`${currentUrl}api/merchant_order`, {
        email: email,
        session: session
    }).then((res) => {
        if (res.data.data !== null) {
            setOrderUserId(res.data.data.transactionId)
            setVisibleDeposit(true)
            // amplitudeConfig('PLAN_MODAL_VIEW',{
            //   id: amplitudeId.id
            // })
        }
    })
}

export const postNewMerchant = (email, session, orderid, amplitudeConfig, amplitudeId) => {
    //setVisibleDeposit,setOrderUserId
    axios.post(`${currentUrl}api/merchant_new_transaction`, {
        email: email,
        session: session,
        orderid: orderid
    }).then((res) => {
        // console.log(res)
        if (res.data.data !== null) {
            //  amplitudeConfig('DEPOSIT_SUCCESS',{id: amplitudeId.id})
            //  setOrderUserId(res.data.data.transactionId)
            //  setVisibleDeposit(true)
        }
    })
}

export const postSubscribe = (email, session, type, period, setStep, notifyError, rerender, setRerender, subscribe, trial = false, amplitude, amplitudeId, code, setVision, phone) => {
    axios.post(`${currentUrl}api/subscribtions`, {
        email: email,
        session: session,
        type: type,
        period: period,
        multiplier: type === "Vip" ? 20000 : type === "Pro" ? 5000 : type === "Master" ? 3000 : type === "Advanced" || type === "Trial" ? 1000 : 1000,
        code: code === undefined ? "" : code,
        phone: phone === undefined ? "" : phone
    }).then((res) => {
        if (res.data.data !== null) {
            if (!trial) {

                // axios.post(`${scaleotUrl}post/firstpurchase`, {
                //   email: email,
                //   amount: subscribe.filter(el => el.name === type)[0].typeExchange.usdt.filter(el => el.period === (typeof period === "string" ? JSON.parse(period) : period))[0].price
                // })
                //   .then((res) => {
                //     let px = Cookies.get('px');
                //     if (px) {
                //       ReactPixel.trackSingle(px, 'Purchase', {});
                //     }
                //   })

                setStep(true)
                setRerender(!rerender)
            } else {
                setVision(false)
                setRerender(!rerender)
            }
        } else {
            notifyError(res.data.error.description)
        }
    })
}

export const delSubscription = (email, session, rerender, setRerender) => {
    axios.delete(`${currentUrl}api/subscribtions`, {
        data: {
            email: email,
            session: session
        }
    }).then((res) => {
        if (res.data.data !== null) {
            setRerender(!rerender)
        }
    })
}

export const postGiveUsdt = (emailAdmin, password, session, emailUser, balanceAmount, notifyInf, setEmailUser, setAmountUser, setPassword, notifyError) => {
    axios.post(`${currentUrl}api/admin/handbalance`, {
        email_admin: emailAdmin,
        password: password,
        session: session,
        email_user: emailUser,
        balance: parseInt(balanceAmount)
    }).then((res) => {
        if (res.data.data !== null) {
            notifyInf(`USDT was sent to ${emailAdmin}`)
            console.log(res)
            setEmailUser('')
            setAmountUser('')
            setPassword('')
        } else {
            notifyError(res.data.error.description)
        }
    })
}

export const getPromo = (setPromo) => {
    axios.get(`${currentUrl}api/promo`).then((res) => {
        setPromo(res.data);
    })
}

export const postPromo = (email, session, name, percent, count, date, rerender, setRerender, Success) => {
    axios.post(`${currentUrl}api/promo`, {
        "email": email,
        "session": session,
        "name": name,
        "percent": percent,
        "count": count,
        "date": date
    }).then((res) => {

        if (res.data.data.status == true) {
            setRerender(!rerender)
            Success("Promo has been succsefully created")
        }
    })
}

export const delPromo = (id, rerender, setRerender, err, notifyInf) => {
    axios.delete(`${currentUrl}api/promo/${id}`)
        .then((res) => {
            // console.log(res)
            notifyInf("Promo was success deleted")
            setRerender(!rerender)
        }).catch((e) => {
        err("Promo was not founded")
    })
}

export const postCheckPromo = (name, email, err, success, setRerender, rerender) => {
    axios.post(`${currentUrl}api/check/promo?name=${name}&email=${email}`)
        .then((res) => {
            if (res.data === true) {
                success('Promocode has been activated')
                setRerender(!rerender)
            } else if (res.data === false) {
                err("Incorrect promocode")
            } else {
                err(res.data)
            }
        })
}

export const getCheckPromo = (email, setExistPromo) => {
    axios.get(`${currentUrl}api/check/promo?email=${email}`)
        .then((res) => {
            setExistPromo(res.data[0])
        }).catch((e) => {
        setExistPromo(0)
    })
}

export const getRefferalLink = (email, setReffLink) => {
    axios.get(`${currentUrl}api/refferal?email=${email}`)
        .then((res) => {
            if (res.status === 200 && res.data !== null) {
                setReffLink(res.data)
            }
        })
}

export const getRefferalCurrentList = (email, setReffList) => {
    axios.get(`${currentUrl}api/refferal/email?email=${email}`)
        .then((res) => {
            if (res.status === 200) {
                setReffList(res.data)
            }
        })
}

export const getRefferalHistory = (email, setRefferalHistory) => {
    axios.get(`${currentUrl}api/refferal/history?email=${email}`)
        .then((res) => {
            if (res.status === 200) {
                setRefferalHistory(res.data)
            }
        })
}

export const deleteUser = (email, session, emailadmin, rerender, setRerender, setCurrentData, setVision) => {
    axios.post(`${currentUrl}api/admin/userdata/delete?email=${email}&session=${session}&adminemail=${emailadmin}`)
        .then((res) => {
            if (res.data === true) {
                setRerender(!rerender)
                setCurrentData('')
                setVision(false)
            }
        })
}

export const getAdminTradeUser = (email, setCurrentTrades) => {
    axios.post(`${currentUrl}statistic/post/trader`, {email: email})
        .then((res) => {
            if (res.status === 200) {
                setCurrentTrades(res.data.data.LastTrades.HUOBI)
            } else {
                setCurrentTrades([])
            }
        }).catch((e) => {
        setCurrentTrades([])
    })

}

export const getUserBalanceForAdmin = (email, setBalanceCurrentUser) => {
    axios.post(`${currentUrl}api/admin/userdata/selectbalance`,
        {
            email: email,
            session: "string",
            exchange: "HUOBI"
        }).then((res) => {
        if (res.data.data !== null) {
            setBalanceCurrentUser(res.data.data.balance)
        } else {
            setBalanceCurrentUser(0)
        }
    }).catch((e) => {
        setBalanceCurrentUser(0)
    })

}

export const updateTransaction = (session, email, err, success, rerender, setRerender) => {
    axios.post(`${currentUrl}api/transactions/update`, {
        session: session,
        email: email,
        exchange: "HUOBI"
    })
        .then((res) => {
            console.log(res)
            if (res.data === 200) {
                setRerender(!rerender)
                success("Updated...")
            } else {
                err("some wrong with server. Try later")
            }
        })
}

export const getAllUserInfo = (session, email, setUserList) => {
    return axios.post(`${currentUrl}api/admin/userdata?session=${session}&adminemail=${email}`)
        .then((res) => {
            if (typeof res.data === 'object') {
                setUserList(res.data)
            }
        })
}

export const getUserActiveSubscribe = (session, email, dataHandler) => {
    return axios.get(`${currentUrl}api/auth/user/activesubscribe?email=${email}&session=${session}`)
        .then(res => {
            dataHandler(res.data)
        })
}

export const createNewNews = (message, arrSender, email, setStep, success) => {
    axios.post(`${currentUrl}api/newsletter?message=${message}${arrSender}&emailAdmin=${email}`)
        .then((res) => {
            if (res.data === 200) {
                setStep(false)
                success("Message was sent")
            }
        })
}

export const createNewVAlidateTrial = (session, email, success, phone, error) => {
    axios.post(`${currentUrl}api/auth/user/register/validate?email=${email}&session=${session}&phone=${phone}`).then((res) => {
        console.log(res.data)
        if (res.data === 200) {
            success(true)
        } else {
            error(res.data.description)
        }
    })
}

//debugging
export const delKeys = (email, session, password, apiKey, rerender, setRerender) => {
    axios.delete(`${currentUrl}api/auth/keys`, {
        data: {
            email: email,
            session: session,
            password: password,
            exchange: "HUOBI",
            apiKey: apiKey
        }
    }).then((res) => {
        if (res.data.data !== null) {
            setRerender(!rerender)
        }
    })
}