import React, { useState } from 'react'
import Title from '../components/Title/Title'
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Profile from '../components/Settings/Profile/Profile'
import Trafic from '../components/Settings/Trafic/Trafic'
import ApiSett from '../components/Settings/ApiSett/ApiSett'
import Footer from '../components/Footer/Footer'
import { useTranslation } from 'react-i18next'
import { delSubscription } from '../api/api.js'

const Settings = (props) => {
  const { keys, activePlan, subscribeItems, rerender, setRerender, balance, amplitudeId } = props
  const { t } = useTranslation();
  const [value, setValue] = useState('1')
  const [vision, setVision] = useState(false)
  const handleChange = (event, newValue) => { setValue(newValue) };
  const userdata = JSON.parse(localStorage.getItem("userData"))


  const deleteCurrentSubscribtion = () => {
    delSubscription(userdata.email, userdata.session, rerender, setRerender)
    setVision(false)
  }

  return (
    <>
      <div style={{
        alignItems: 'center',
        display: vision ? 'flex' : 'none',
        justifyContent: 'center'
      }} className='template_close_container'>
        <div className='container_template_close_modal'>
          <p className='hover_container_template_close_modal'>{t("cancelAlertHover")}</p>
          <div className='subcontainer_attention_needed modal_chooser_cancel'>
            <div style={{ padding: 20, textAlign: "center" }} className='container_template_close_modal_item'>
              <p style={{ fontSize: 16, color: "white", marginBottom: 20, marginTop: 0 }}>{t("cancelAlertDesc")}</p>
              <button onClick={() => deleteCurrentSubscribtion()} className='acc_chooser_butt'>{t('cancelAlertFirstButt')}</button>
              <button onClick={() => setVision(false)} className='cancel_chooser_butt'>{t('cancelAlertLastButt')}</button>
            </div>
          </div>
        </div>
      </div>
      <div className="trade-data setting_data">
        <Title title={t('setting')} />
        <TabContext value={value}>
          <TabList
            TabIndicatorProps={{
              style: { display: 'none' }
            }}
            onChange={handleChange} aria-label="setting">
            <Tab label={t('profile')} value="1" />
            {/* <Tab label={t('subscribe')} value="2" /> */}
            <Tab label="API" value="3" />
          </TabList>
          <TabPanel value="1">
            <Profile
              keys={keys}
              balance={balance}
              activePlan={activePlan}
              rerender={rerender}
              setRerender={setRerender}
              t={t} />
          </TabPanel>
          {/* <TabPanel value="2">
            <Trafic
              t={t}
              vision={vision}
              setVision={setVision}
              rerender={rerender}
              setRerender={setRerender}
              subscribeItems={subscribeItems}
              activePlan={activePlan} />
          </TabPanel> */}
          <TabPanel value="3">
            <ApiSett
              amplitudeId={amplitudeId}
              rerender={rerender}
              setRerender={setRerender}
              t={t}
              keys={keys} />
          </TabPanel>
        </TabContext>
      </div>
    </>
  )
}

export default Settings
