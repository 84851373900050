import React, { useState, useEffect } from 'react'
import storage from '../../../storage/storage.js'
import pass from '../../../assets/svg/Password.svg'
//import FA from '../../2FA/FA'
import { ChangeMarketStatus, getTrading } from '../../../api/api.js'
import { settingPassword } from '../../../api/api.js'
import { ToastContainer, toast } from 'react-toastify';
import eye from '../../../assets/imgredesign/eye/eye.svg'
import eyeOff from '../../../assets/imgredesign/eye/eye-off.svg'

const Profile = (props) => {
  const { t, profile, setRerender, rerender, keys, balance, activePlan } = props
  const changeHandler = event => { setForm({ ...form, [event.target.name]: event.target.value }) }
  const [form, setForm] = useState({ old: '', new: '', repeatNew: '' })
  const [activeKeySelected, setActiveKeySelect] = useState('')
  const [oldVis, setOldVis] = useState(false)
  const [newVis, setNewVis] = useState(false)
  const [repeatNewVis, setRepeatNewVis] = useState(false)
  //const [twofactor, setTwoFactor] = useState('')
  const authTwo = false
  const [password, setPass] = useState('')
  const [trade, setTrade] = useState(null)
  const userdata = JSON.parse(localStorage.getItem("userData"))
  const notifyError = (data) => toast.error(data);
  const notifyInf = (data) => toast.info(data);

  useEffect(() => {
    getTrading(userdata.email, "HUOBI", userdata.session)
      .then((res) => {
        if (res.data.data !== null) {
          setTrade(res.data.data.status)
        }
      })
  }, [rerender])

  const createNewPassword = () => {
    if (
      form.old.length === 0 ||
      form.new.length === 0 ||
      form.repeatNew.length === 0
    ) { notifyError('Type required value') }
    if (form.new !== form.repeatNew) { notifyError('Passwords do not match') }

    if (
      form.old.length !== 0 &&
      form.new.length !== 0 &&
      form.repeatNew.length !== 0 &&
      form.new === form.repeatNew
    ) {
      settingPassword(userdata.email, userdata.session, form.old, form.new, notifyError, notifyInf, setForm)
    }
  }

  let activeKey = []
  if (keys.length !== 0 && keys.filter(el => el.name === 'HUOBI')[0].value !== null) {
    activeKey.push(keys.filter(el => el.name === 'HUOBI')[0].value
      .filter(res => res.active === true)[0])
  }

  const createNewStatusTrading = () => {
    if (activeKeySelected.length !== 0 || (activeKey.length !== 0 && activeKey[0] !== undefined)) {
      if (password.length !== 0) {
        const autoChoose = activeKey.length !== 0 && activeKey[0] !== undefined ? activeKey[0].apiKey : activeKeySelected
        console.log(autoChoose)
        ChangeMarketStatus(userdata.session, userdata.email, trade, password, setRerender, rerender, notifyError, notifyInf, autoChoose)
      } else {
        notifyError(t('noPassVeriff'))
      }
    } else {
      notifyError(t('noSelectKeyHave'))
    }
  }

  return (
    <div style={{ marginTop: 10 }} className="setting_container">
      <p className='own_info_hover'>{t('self_info')}</p>
      <p style={{ fontSize: 18 }} className="description_settings">{t('yourEmail')}</p>
      <div className="settings_container_email">
        <p>{userdata.email}</p>
      </div>

      {/* {keys.filter(el => el.name === 'HUOBI').length !== 0 ?
        keys.length !== 0 && (keys.filter(el => el.name === 'HUOBI')[0].value === null || keys.filter(el => el.name === 'HUOBI')[0].value.length !== 0) && activePlan !== null ? */}
          <div style={{ marginTop: 40 }}>
            <p className="title_status_trade">{t('panelSettingHover')}</p>
            <input value={password}
              onChange={(event) => setPass(event.target.value)}
              className='profile_settings_password_checker'
              type="password" placeholder='Type password for verification' />
            <br />

            {activeKey.length !== 0 && activeKey[0] === undefined ?
              <p style={{ marginTop: 15 }} className="description_settings">{t('panelSettingSubHover')}</p>
              : null}
            {activeKey.length !== 0 && activeKey[0] === undefined ?
              keys.filter(el => el.name === 'HUOBI').length !== 0 ?
                keys.filter(el => el.name === 'HUOBI')[0].value.map((el, key) =>
                  <div
                    style={{
                      background: activeKeySelected !== el.apiKey ? '' : "#00A171"
                    }}
                    onClick={() => setActiveKeySelect(el.apiKey)} key={key} className='key_available_item'>
                    <p>{el.apiKey}</p>
                  </div>)
                : null : null}
            <button
              onClick={() => createNewStatusTrading()}
              className={`${trade ? 'stop_trading_now' : "start_trading_now"} cancel_active_butt`}
              style={{ maxWidth: 221, marginTop: 10, width: "100%" }}
            >{trade ? t('stopTrade') : t('startTrade')}
            </button>
          </div>
           {/* : null : null} */}

      <div className="container_change_password">
        <p className="change_pass_title">{t('pass')}</p>
        {storage.password_change_sett.map((el, key) =>
          <div key={key}>
            <p className="description_settings">{t(el.name)}</p>
            <div className="input">
              <img onClick={eval(el.setVision)} src={eval(el.vision) ? eye : eyeOff} alt="-" />
              <input type={eval(el.vision) ? "text" : "password"} name={el.nameInput} value={eval(el.value)} onChange={(event) => changeHandler(event)} />
            </div>
          </div>)}
        <div style={{ textAlign: 'center' }}>
          <button onClick={() => createNewPassword()}>{t('change')}</button>
        </div>
      </div>
      {/*<FA 
        t={t}
        authTwo={authTwo}
        twofactor={twofactor}
        setTwoFactor={setTwoFactor}/>*/}
      <ToastContainer />
    </div>
  )
}

export default Profile
