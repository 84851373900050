import React, { useState, useEffect } from 'react'
import Logo from "../assets/imgredesign/logo.svg"
import { ToastContainer, toast } from 'react-toastify';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import 'react-toastify/dist/ReactToastify.css';
import storage from '../storage/storage.js'
import { useTranslation } from 'react-i18next'
import { NavLink } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import { Verification, ChangeRecoverPass } from '../api/api.js';
import backgroundCry from '../assets/crypush/gif/CRYPUSH_BG_1920x1080.mp4'
import backgroundCryTablet from '../assets/crypush/gif/CRYPUSH_BG_768x1024.mp4'
import backgroundCryMobile from '../assets/crypush/gif/CRYPUSH_BG_320x524.mp4'

const Recovery = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [form, setForm] = useState({ email_recover: '', email: '', tempPassword: '', password: '' })
  const [loading] = useState(false)
  const [phone, setPhone] = useState()
  const [seconds, setSeconds] = useState(0);
  const [nextButton, toggleNextButton] = useState('')
  const [loginText] = useState(t('send'))
  const changeHandler = event => { setForm({ ...form, [event.target.name]: event.target.value }) }
  const notifyError = (data) => toast.error(data);
  const notifyInf = (data) => toast.info(data);

  const urlParams = new URLSearchParams(window.location.search);
  const cid = urlParams.get('cid');

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
      toggleNextButton(true);
    } else {
      toggleNextButton(false);
    }
  });

  const registrationHandler = async () => {
    try {
      if (form.email_recover.length !== 0) {
        setSeconds(60)
        Verification(form.email_recover, phone, notifyInf, notifyError)
      } else {
        notifyError(t('noRecValues'))
      }
    } catch {
      console.log("whats wrong with sending")
    }
  }


  const changePassword = () => {
    if (form.password !== form.tempPassword) { notifyError(t('noRepeat')) }
    if (form.password.length === 0) { notifyError(t('incorrectPass')) }
    if (form.tempPassword.length === 0) { notifyError(t('incorrectRepeatPass')) }
    if (form.email.length === 0) { notifyError(t('codeNo')) }
    if (! /^[a-zA-Z0-9]+$/.test(form.password)) {
      notifyError(t('noArifmetic'))
    }
    if (form.password === form.tempPassword &&
      form.password.length !== 0 &&
      form.tempPassword.length !== 0 &&
      form.email.length !== 0 &&
      /^[a-zA-Z0-9]+$/.test(form.password)
    ) {
      ChangeRecoverPass(form, notifyInf, history, notifyError)
    }
  }


  return (
    <div className="auth-wrap">
      <div className='video_container'>
        <video
          className="video-1920 background-video"
          playsInline muted loop autoPlay src={backgroundCry} />
        <video
          className="video-1024 background-video"
          playsInline muted loop autoPlay src={backgroundCryTablet} />
        <video
          className="video-320 background-video"
          playsInline muted loop autoPlay src={backgroundCryMobile} />
      </div>

      <div className="login-wrap">
        <a href="https://cryppush.com/"><img src={Logo} alt="Logotype" /></a>
        <div className='form-login'>
          <h2 style={{ marginBottom: 20, color: '#263755' }}>{t('recoverHover')}</h2>
          {storage.form_recovery_start.map((el, key) =>
            el.type === 'phone' ?
              <div key={key} style={{ width: '100%', position: "relative" }}>
                <p style={{
                  textAlign: 'inherit',
                  marginTop: 2,
                  marginBottom: 8,
                  fontSize: 12,
                  color: 'rgba(88, 97, 124, 1)'
                }}>{t('phoneRegPlace')}</p>
                <PhoneInput
                  placeholder={t('phoneReg')}
                  value={phone}
                  onChange={setPhone} />
              </div>
              :
              <div key={el.name} style={{ width: '100%', position: 'relative' }}>
                <p style={{
                  textAlign: 'inherit',
                  marginBottom: 8,
                  fontSize: 12,
                  color: 'rgba(88, 97, 124, 1)'
                }}>{t(el.holder)}</p>
                <div style={{ marginBottom: 10 }} className="input">
                  <i className={el.ico}></i>
                  <input type={el.type} placeholder={t(el.holder)} name={el.name} value={eval(el.value)} onChange={(event) => changeHandler(event)} />
                </div>
                <button style={{
                  display: key !== 0 ? 'none' : "block",
                  pointerEvents: nextButton ? 'none' : '',
                  background: nextButton ? 'rgba(149, 167, 183, 0.3)' : 'rgba(47, 128, 237, 0.3)'
                }}
                  className="button_send_email" onClick={() => registrationHandler()}>
                  {nextButton ? `${seconds} ${t('formSec')}` : t('formCode')}</button>
              </div>
          )}

          <div style={{ display: 'flex', marginBottom: 32 }}>
            {storage.form_recovery_end.map((el, key) =>
              <div key={key} style={{ width: '100%', marginRight: key == 0 ? 16 : 0 }}>
                <p style={{
                  textAlign: 'inherit',
                  marginTop: 10,
                  marginBottom: 8,
                  fontSize: 12,
                  color: 'rgba(88, 97, 124, 1)'
                }}>{t(el.holder)}</p>
                <div className="input">
                  <i className={el.ico}></i>
                  <input type={el.type} placeholder={t(el.holder)} name={el.name} value={eval(el.value)} onChange={(event) => changeHandler(event)} />
                </div>
              </div>)}
          </div>
          <button className="login-btn" onClick={() => changePassword()} disabled={loading}>
            {loading ? <div className="fa-1x"><i className="fas fa-spinner fa-spin"></i></div> : ""}
            {loginText}
          </button>
          <NavLink
            style={{ color: 'rgba(88, 97, 124, 1)', textAlign: 'center' }}
            to={{ 
              pathname: "/",
              search: `?cid=${cid}`
            }}
          >
            {t('backForm')}
          </NavLink>
          <ToastContainer />
        </div>
      </div>
    </div>
  )
}

export default Recovery;
