import React,{useState,useEffect} from 'react'
import logo from "../../assets/img/huobi.png"
import {ChangeMarketStatus,getTrading,postGiveUsdt} from '../../api/api.js'
import { ToastContainer, toast } from 'react-toastify';

const Statistics = (props) => {
    const {userCount,t} = props
    const [trade,setTrade] = useState(null)
    const [rerender, setRerender] = useState(false)
    const [pass,setPass] = useState('')
    const userdata = JSON.parse(localStorage.getItem("userData"))
    const notifyError = (data) => toast.error(data);
    const notifyInf = (data) => toast.info(data);
    const [emailUser,setEmailUser] = useState('')
    const [amountUser,setAmountUser] = useState('')
    const [password, setPassword] = useState('')

    useEffect(() => {
        getTrading(userdata.email,"HUOBI",userdata.session)
        .then((res) => {
            if(res.data.data !== null){
                setTrade(res.data.data.status)
            }
        })
    },[rerender])

    const sendUsdt = () => {
        if(emailUser.length !== 0 && amountUser.length !== 0 && password.length !== 0){
            postGiveUsdt(
                userdata.email,
                password,
                userdata.session,
                emailUser,
                amountUser,
                notifyInf,
                setEmailUser,
                setAmountUser,
                setPassword,
                notifyError)
        }else{notifyError(t("requiredParams"))}
    }

    return(
        <div className="setting_container">
        <div className='admin_profile'>
        <h3>{t('statistics')}</h3>
        <div className='admin_statistics'>
            <img src={logo} alt="huobi logo"/>
            <p>{`${userCount.totalUsers} ${t('users')}`} </p>    
        </div>
        <p>{t('online')}: {userCount.activeUsers} {t('users')}</p>
        <p>{t('withKey')}: {userCount.totalUsersWithKeys} {t('users')}</p>
        </div>

        <div style={{
            marginTop: 30,
            background: 'linear-gradient(90deg, rgba(47, 128, 237, 0.2) 0%, rgba(45, 156, 219, 0.2) 100%)',
            padding: 20,
            borderRadius:10,
            }} className='admin_profile'>
             <h3>{t('handTitle')}</h3>
             <div className='proxy_new_form'>
             <p className='proxy_new_form_title'>{t('handUserTitle')}</p>
                <input 
                value={emailUser} 
                onChange={(event) => setEmailUser(event.target.value)} 
                className='input_proxy' 
                type="text" 
                placeholder="Type email user which you wanna deposit"/>
            <br/>
            <p className='proxy_new_form_title'>{t('handAmount')}</p>
                <input 
                value={amountUser} 
                onChange={(event) => setAmountUser(event.target.value)} 
                className='input_proxy' 
                type="text" 
                placeholder="Type amount usdt"/>
            <br/>
            <p className='proxy_new_form_title'>{t('handPass')}</p>
                <input 
                value={password} 
                onChange={(event) => setPassword(event.target.value)} 
                className='input_proxy' 
                type="text" 
                placeholder="Type password for verification"/>
            <br/>
            <button onClick={() => sendUsdt()}>{t('handButton')}</button>
             </div>
        </div>
        {/*trade !== null?
        <div className='change_status_container_trade'>
            <input 
            type="password" 
            value={pass}
            placeholder="Type here password"
            onChange={(event) => setPass(event.target.value)}/>
            <button
            style={{background: !trade?"green":"red"}}
            onClick={() => ChangeMarketStatus(userdata.session,userdata.email,!trade,pass,setRerender,rerender,notifyError,notifyInf)}   
            >{trade? 'Остановить торговлю':'Начать торговлю'}
            </button>
        </div>
    : trade*/}
        <ToastContainer />
    </div>
    )
}

export default Statistics