import { Line } from 'react-chartjs-2';

const Chart = (props) => {
  const {session,current_arr,arrFilter,dateArr,arrFilterDate} = props

  const num = [],
        num_filter = [];
  for (var i = 0; i < current_arr.length; i++) {num.push(i)}
  if (arrFilter.length !== 0) {
    for (var i = 0; i < arrFilterDate.length; i++) {
      num_filter.push(`${new Date(arrFilterDate[i]).getDate().toString().length === 1 ? `0${new Date(arrFilterDate[i]).getDate()}` : new Date(arrFilterDate[i]).getDate()}.${(new Date(arrFilterDate[i]).getMonth()+1).toString().length === 1 ? `0${new Date(arrFilterDate[i]).getMonth()+1}` : new Date(arrFilterDate[i]).getMonth()+1}.${new Date(arrFilterDate[i]).getFullYear()}`)
    }
  }

    const dataOption =(canvas)=> {
        const ctx = canvas.getContext("2d")
        const {width: graphWidth} = ctx.canvas;
        const gradient2 = ctx.createLinearGradient(0, 0, graphWidth * 2, 0);
        gradient2.addColorStop(1, "rgba(163, 184, 224, 1)");

        return {
            labels: session === 'current' ?
            arrFilter.length !== 0 ? num_filter :
            dateArr.slice(dateArr.length-250,dateArr.length) : dateArr.slice(num.length - 32,num.length),
            //session === 'current' ? num : num.slice(num.length - 32,num.length)
            datasets: [{

                    pointBorderWidth: 0,
                    pointBorderColor: 'rgba(245, 245, 245, 1)',
                    label: 'Profit',
                    data: session === 'current' ?
                    arrFilter.length !== 0 ?
                    arrFilter : current_arr.slice(current_arr.length-250, current_arr.length) : [],
                    fill: false,
                    backgroundColor: gradient2,
                    borderColor: "rgba(245, 245, 245, 1)",
                    pointRadius: 0
                },
            ],
        }
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        animation: { duration: 0 },
        scales: {
            y: {
                display: true,
                position: "left",
                title: {
                    display: false,
                    text: 'Value'
                },
                grid: {
                    drawBorder: false,
                    borderDash: [8, 5],
                    color: 'rgba(159,162,180, 0.56)',
                    //borderDash: [10, 10],
                    lineWidth: 1,
                },
                ticks: {
                    color:"rgba(163, 185, 224, 1)",
                    callback: function(label, index, labels) {
                        return label.toFixed(2)+'$';
                    }
                }
            },
            x: {
                grid: {
                    display: true,
                    borderDash: [8, 5],
                    drawBorder: false,
                    color: '#58617C',
                    lineWidth: 1,
                },
                ticks: {
                    color:"rgba(163, 185, 224, 1)",
                }
            }
        },
        plugins: {
            legend: {
                position: 'top',
                display: false,
            },
        }
        
    };
    return (
            <div style={{padding:24,paddingTop:0}} className="chart-canvas">
                <Line width={'600px'} height={'300'} data={dataOption} options={options}/>
            </div>
    );
}



export default Chart
