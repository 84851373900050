import React, { useEffect, useState } from "react"
import { NavLink } from "react-router-dom"
import close from '../../assets/imgredesign/close.svg'
import { useTranslation } from 'react-i18next'
import { ToastContainer, toast } from 'react-toastify';
import { sendKey } from "../../api/api.js"


const CheckExistNeeded = (props) => {
  const { balance, activePlan, keyExistBool, widthVal, ref } = props
  const { t, i18n } = useTranslation();
  const [startKeyApi, setStartKeyApi] = useState(false)
  const [terms, setTerms] = useState(false)
  const [apikey, setApiKey] = useState('')
  const [secretkey, setSecretKey] = useState('')
  const [password, setPass] = useState('')
  const [disabled, setDisabled] = useState(!keyExistBool)
  const notifyError = (data) => toast.error(data);
  const termsHandler = (event) => { setTerms(!terms) }
  const userdata = JSON.parse(localStorage.getItem("userData"))

  const createNewKey = () => {
    if (apikey.length === 0) { notifyError(t("huobiErrorKey")) }
    if (secretkey.length === 0) { notifyError(t("huobiErrorSecret")) }
    if (password.length === 0) { notifyError(t("incorrectPass")) }
    if (!terms) { notifyError(t("noTerms")) }
    if (apikey.length !== 0 && secretkey.length !== 0 && terms && password.length !== 0) {
      sendKey(userdata.email, apikey, secretkey, "HUOBI", password, userdata.session).then(data => {
        if (data.data.data !== null) {
          setStartKeyApi(false)
          setApiKey('')
          setSecretKey('')
        } else {
          notifyError(data.data.error.description)
        }
      })
      setPass('')
    }
  }

  return (
    <div style={{ width: `${widthVal}px` }} className='template_close_container'>
      {!startKeyApi ?
        <div style={{ maxWidth: 555 }} className='container_template_close_modal'>
          <p className='hover_container_template_close_modal'>{t("tradeStopHover")}</p>
          <div className='subcontainer_attention_needed'>
            <div className='container_template_close_modal_item'>
              <div className='container_template_close_modal_subitem'>
                <div style={!keyExistBool ? {
                  color: "rgba(156, 255, 109, 1)",
                  border: "4px solid rgba(156, 255, 109, 1)"
                } : {}} className='container_template_close_modal_subitem_counter'><p>1</p></div>
                <div className='container_template_close_modal_subitem_description'><p>{t("tradeStopFirstHover")}</p></div>
              </div>
              <div className='container_template_current_panel'>
                <button
                  className="container_template_current_panel_key_api"
                  onClick={() => disabled ? null : setStartKeyApi(true)}
                  disabled={disabled}
                >
                  {t('tradeStopButt')}
                </button>
              </div>
            </div>
            {/* <div className='container_template_close_modal_item'>
              <div className='container_template_close_modal_subitem'>
                <div style={
                  balance && balance > 300 ? {
                    color: "rgba(156, 255, 109, 1)",
                    border: "4px solid rgba(156, 255, 109, 1)"
                  } : {}} className='container_template_close_modal_subitem_counter'><p>2</p></div>
                <div className='container_template_close_modal_subitem_description'><p>{t("tradeStopSecondHover")}</p></div>
              </div>
              <div className='container_template_current_balance'><p>{t('yourBal')}: {balance && balance > 0 ? balance.toFixed(2) : 0} USDT</p></div>
            </div> */}
            {/* <div className='container_template_close_modal_item'>
              <div className='container_template_close_modal_subitem'>
                <div style={activePlan !== null ? {
                  color: "rgba(156, 255, 109, 1)",
                  border: "4px solid rgba(156, 255, 109, 1)"
                } : {}} className='container_template_close_modal_subitem_counter'><p>3</p></div>
                <div className='container_template_close_modal_subitem_description'><p>{t("tradeStopThirdHover")}</p></div>
              </div>
              <div className='container_template_current_panel'>
                <NavLink to="/subscribes"><button className="container_template_current_panel_butt">{t('tradeStopThirdHoverFirstButt')}</button></NavLink>
                <NavLink to="/subscribes"><button className="container_template_current_panel_butt_last" style={{ margin: 0 }}>{t('tradeStopThirdHoverLastButt')}</button></NavLink>
              </div>
            </div> */}

          </div>
        </div>
        :
        <div style={{ alignItems: 'center', display: 'flex' }} className='container_template_close_modal'>
          <div>
            <p className='hover_container_template_close_modal'>{t('connectStopTradeHover')}</p>
            <div className='subcontainer_attention_needed'>
              <div className='container_template_close_modal_item'>
                <div style={{ display: "block" }} className='container_template_close_modal_subitem'>
                  <div style={{ textAlign: 'right', width: '100%' }}>
                    <img onClick={() => setStartKeyApi(false)} style={{ cursor: "pointer" }} src={close} alt="-" />
                  </div>
                  <p className="input_api_container_description">{t('apiModalHoverApi')}</p>
                  <input
                    autoComplete="new-password"
                    value={apikey}
                    onChange={(event) => setApiKey(event.target.value)}
                    placeholder="********_********_********_******"
                    className="input_api_container" />
                  <p className="input_api_container_description">{t('apiModalHoverSecret')}</p>
                  <input
                    autoComplete="new-password"
                    value={secretkey}
                    onChange={(event) => setSecretKey(event.target.value)}
                    placeholder="********_********_********_******"
                    className="input_api_container" />
                  <p className="input_api_container_description">{t('apiModalHoverPass')}</p>
                  <input
                    autoComplete="new-password"
                    type="password"
                    value={password}
                    onChange={(event) => setPass(event.target.value)}
                    placeholder="******"
                    className="input_api_container" />
                  <div className="user_agreements_container_">
                    <input
                      value={terms}
                      onChange={() => termsHandler()}
                      type="checkbox" />
                    <p style={{ marginLeft: 15 }}>{t('agreementApiModalStart')}<a href={`https://cryppush.com/user_agreements_${i18n.language}.pdf`}>{t('agreementApiModalEnd')}</a></p>
                  </div>
                  <div style={{ textAlign: 'center', marginTop: 25 }}>
                    <button className="start_key_button" onClick={() => createNewKey()}>{t('modalApiButt')}</button>
                  </div>
                  <div className="container_how_start">
                    <NavLink className="link_take_api" to="/instructions">{t("modalApiQuess")}</NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
      <ToastContainer />
    </div>
  )
}

export default CheckExistNeeded