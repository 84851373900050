import React,{useState} from 'react'
import {postChannel} from '../../api/api.js'

const Channels = (props) => {
    const {notifyError,notifyInf,rerenderAdmin,setRerenderAdmin,channelCode,t} = props
    const [channel,setChannel] = useState('')
    const userdata = JSON.parse(localStorage.getItem("userData")) 


    const createChannel = () => {
        if(channel.length !== 0 && channel.length > 3){
            postChannel(userdata.email,userdata.session,channel,notifyInf,rerenderAdmin,setRerenderAdmin)
        }else{notifyError('Код не должен быть меньше длиной 3') }
        setChannel('')
    }
    return(
        <div className="setting_container">
        <div className='admin_profile'>
               <h3>{t('channel')}</h3>
               <p>{t('currentChannel')}: {channelCode.channelCode}</p>
               <div className='channel_form'>
                   <p>{t('changeChannel')}</p>
                   <input type="text" value={channel} onChange={(event) => setChannel(event.target.value)} placeholder={t('typeChannel')}/>
                   <br/>
                   <button onClick={() => createChannel()}>{t('changeCode')}</button>       
               </div>
           </div>
       </div>
    )
}

export default Channels