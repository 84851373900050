import React from 'react';
import ReactDOM from 'react-dom';
import {compose, createStore, applyMiddleware} from 'redux'
import {Provider} from 'react-redux'
import thunk from 'redux-thunk'
import {rootReducer} from './redux/rootReducer'
import './assets/scss/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'react-phone-number-input/style.css'
import {ThemeProvider} from "@mui/material";
import {muiTheme} from "./theme/muiTheme";

const store = createStore(rootReducer, compose(
    applyMiddleware(
        thunk
    ),
    //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
));

const app = (
    <Provider store={store}>
        <ThemeProvider theme={muiTheme}>
            <App />
        </ThemeProvider>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));


// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

reportWebVitals();
