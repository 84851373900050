import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import Logo from "../assets/imgredesign/logo.svg"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toggleLogin } from "../redux/actions";
import storage from '../storage/storage.js'
import { sendLogin } from '../api/api.js'
import { useTranslation } from 'react-i18next'
import ReCAPTCHA from "react-google-recaptcha";
import { NavLink } from "react-router-dom";
import amplitude from 'amplitude-js';
import { Vertify } from '@alex_xu/react-slider-vertify';
import captcha from "../assets/img/captcha.jpg"
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

const Login = (props) => {
  const { serCurrentLinkReff } = props
  const { t } = useTranslation()
  const [form, setForm] = useState({ email: '', password: '' })
  const [loading, setLoading] = useState(false)
  const [captcha, setCaptcha] = useState(false)
  const changeHandler = event => { setForm({ ...form, [event.target.name]: event.target.value }) }
  // const onChangeCaptcha = (value) => {setCaptcha(value)}
  const [visible, setVisible] = useState(false);
  const sendBrew = () => {
    //   amplitude.getInstance().init("8c7495d8d96aadb1c97f5aee9ca0602e") 
    //    amplitude.getInstance().logEvent('Юзер перешёл на регистрацию')
    props.toggleLogin()
  }

  const { search } = useLocation();


  const changeCaptcha = () => {
    setVisible(false)
    setCaptcha(true)
    setLoading(false)
  }

  const urlParams = new URLSearchParams(window.location.search);
  const cid = urlParams.get('cid');

  useEffect(() => {
    if (captcha) {
      loginHandler()
    }
  }, [captcha])

  useEffect(() => {

    // let px = Cookies.get('px');

    // if (!px) {
    //   const params = new URLSearchParams(search);
    //   const px = params.get('px');
    //   Cookies.set('px', px);
    // }

    if (window.location.pathname.split('/')[1] === 'register') {
      serCurrentLinkReff(window.location.pathname.split('/')[2])
      localStorage.setItem("userReffExist", JSON.stringify({
        "reff": window.location.pathname.split('/')[2]
      }))
      props.toggleLogin()
    }
  }, [])

  const loginHandler = async () => {
    setLoading(true)
    if (!form.email) {
      notifyError(t("incorrectEmail"))
      setLoading(false)
    }
    if (!form.password) {
      notifyError(t('incorrectPass'))
      setLoading(false)
    }
    if (!captcha) {
      setVisible(true)
    }
    if (form.email && form.password && captcha) {
      sendLogin(form).catch((error) => {
        notifyError(t('someProblem'))
        setLoading(false)
      }).then((data) => {
        try {
          //  console.log(data.data.data.userData);
          if (data.status === 200) {
            setLoading(false)
            localStorage.setItem("userData", JSON.stringify({
              "id": data.data.data.userData.id,
              "email": data.data.data.userData.email,
              "session": data.data.data.session,
              "statusTrade": false
            }))
            window.location.href = "/trade-app"
          }
        } catch (e) {
          //console.log(e);
          setLoading(false)
          setCaptcha(false)
          notifyError(t('incorrectSign'))
        }
      })
    }
  }
  useEffect(() => {
    if (window.location.href.split('/')[3] === 'demo') {
      setForm({ email: 'demo@bitok.pro', password: 'demo' })
    }
  }, [])

  const notifyError = (data) => toast.error(data);
  const notifyInf = (data) => toast.info(data);
  return (
    <div style={{ maxWidth: 360 }} className="login-wrap">
      {/*<a href="https://bitok.pro/"><img src={Logo} alt="Logotype" /></a>*/}
      <form action={() => loginHandler()} className='form-login'>
        <p className='login_text_hover'>{t('signHover')}</p>
        {storage.form_login.map(el =>
          <div key={el.name} style={{ width: '100%' }}>
            <p style={{
              textAlign: 'inherit',
              marginTop: 10,
              marginBottom: 8,
              fontSize: 12,
              color: 'rgba(88, 97, 124, 1)'
            }}>{t(el.holder)}</p>
            <div className="input">
              <i className={el.ico}></i>
              <input
                type={el.type}
                placeholder={t(el.holder)}
                name={el.name}
                value={eval(el.value)}
                onChange={(event) => changeHandler(event)} />
            </div>
          </div>)}

        <div style={{ marginTop: 20 }}>

          {/*<ReCAPTCHA
                sitekey="6LegOSAgAAAAACTpkOdu4kRdRh1FmvTDQNXU6Klp"
            onChange={onChangeCaptcha}/>*/}
        </div>

        <button className="login-btn" onClick={() => loginHandler()} disabled={loading}>
          {loading ? <div className="fa-1x"><i className="fas fa-spinner fa-spin"></i></div> : ""}
          {t('signIn')}
        </button>
        <div style={{ marginBottom: 20 }}>
          <Vertify
            width={320}
            imgUrl={captcha}
            height={160}
            visible={visible}
            onSuccess={() => changeCaptcha()}
          />
        </div>
        {/*<p style={{ fontWeight: 'bold' }}>{t('noacc')}? <span style={{ color: 'rgb(39, 168, 226)' }} onClick={() => sendBrew()}>{t('registerNow')}</span></p>*/}
        {/* <NavLink to={{
          pathname: '/recovery',
          search: `?cid=${cid}`
        }}>
          <h1 style={{ textDecoration: 'underline', color: 'rgb(39, 168, 226)' }} onClick={() => notifyInf(t('dev'))}>{t('recover')}</h1>
        </NavLink> */}
        <ToastContainer />
      </form>
    </div>
  );
}
const mapDispatchToProps = {
  //createPost, showAlert
  toggleLogin
}

const mapStateToProps = state => ({
  //balance: state.binary.balance,
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
