
//!SBI/changed from cryppush.net
const socket = new WebSocket('wss://bitok.pro/ws');


export const connextSocket = (email, session,newTradeHook,newTradeArr,newPositionHook,newPositionArr,setFilterTrade,filterTrade,setBalance) => {
  socket.onopen = function(event) {
    const connectData = JSON.stringify([{
      Type:"CONNECT",
      Data:{Email: email, SessionId: session}
    }])
    socket.send(connectData);
  };
  socket.onmessage = function(event) {
    const pingData = JSON.stringify([{
      Type: 'PING',
      Data: {}
    }])

    const type = JSON.parse(event.data);

      for (var i = 0; i < type.length; i++) {
        if (type[i].Type  === 'PING'){
          socket.send(pingData)
        } else if (type[i].Type === 'NEWTRADE') {
          console.log("yay trade");
          newTradeHook([...newTradeArr, {
            time: type[i].Data.Trade.Time,
            instrument: type[i].Data.Trade.Instrument,
            pnL: type[i].Data.Trade.PnL,
            commision: type[i].Data.Trade.Commision,
            type: type[i].Data.Trade.Type,
            volume: type[i].Data.Trade.Volume
          }])
          const newTradeFilter = [{
            time: type[i].Data.Trade.Time,
            instrument: type[i].Data.Trade.Instrument,
            pnL: type[i].Data.Trade.PnL,
            commision: type[i].Data.Trade.Commision,
            type: type[i].Data.Trade.Type,
            volume: type[i].Data.Trade.Volume
          }]
          for (var count_trade = 0; count_trade < filterTrade.length; count_trade++) {
            newTradeFilter.push(filterTrade[count_trade])
          }
          setFilterTrade(newTradeFilter)
        }else if (type[i].Type === 'NEWPOSITION') {

          const newPosition = {
              position: type[i].Data.Position.Position,
              instrument: type[i].Data.Position.Instrument
            }
          const noFilterArrTrade = newPositionArr.filter(el => el.instrument !== newPosition.instrument);
          newPositionHook([...noFilterArrTrade, newPosition])
        }else if (type[i].Type === 'BALANCE') {
          setBalance(type[i].Data.Balance)
        }
      }


};
}
