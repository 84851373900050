import React from 'react'
import { Line } from 'react-chartjs-2';

const ProfitChart = (props) => {
    const { current_arr, dateArr, type } = props
    const num = [];
    const debug_arr = [0]
    let dataDay = Date.now()
    let tradingPerDay = type === 1 ? (current_arr === undefined || current_arr === null || current_arr.length === 0 ? [] : 
    current_arr.filter(el => Date.parse(el.time) > (dataDay - 86400000)).length) : 14

    for (var i = 0; i < current_arr.length; i++) { num.push(i) }

    if (current_arr.length > 0) {
        for (var i = 0; i < current_arr.filter(el => el.type !== null).length; i++) {
            debug_arr.push(debug_arr[i] + current_arr[i].commision + current_arr[i].pnL);
        }
    }

    const dataOption = (canvas) => {
        const ctx = canvas.getContext("2d")
        const { width: graphWidth } = ctx.canvas;
        const gradient2 = ctx.createLinearGradient(0, 0, graphWidth * 2, 0);
        gradient2.addColorStop(1, "rgba(163, 184, 224, 1)");
        gradient2.addColorStop(0, "rgba(33, 37, 48, 1)");
        return {
            labels: dateArr.slice(dateArr.length - tradingPerDay, dateArr.length),

            datasets: [{
                pointBorderWidth: 1,
                pointBorderColor: 'rgba(163, 184, 224, 1)',
                label: 'Profit',
                data: debug_arr.slice(debug_arr.length - tradingPerDay, debug_arr.length),
                fill: true,
                backgroundColor: gradient2,
                borderColor: "rgba(163, 184, 224, 1)",
            },
            ],
        }
    };

    const options = {
        responsive: true,
        maintainAspectRatio: true,
        animation: { duration: 0 },
        scales: {
            y: {
                display: false,
                position: "right",
                title: {
                    display: false,
                    text: 'Value'
                },
                grid: {
                    drawBorder: false,
                },
            },
            x: {

                display: false
            }
        },
        plugins: {
            legend: {
                position: 'top',
                display: false,
            },
        }
    };

    return (
        <div className="chart-canvas">
            <Line width={600} height={200} data={dataOption} options={options} />
        </div>
    )
}

export default ProfitChart
