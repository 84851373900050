import React from 'react'
import alert from '../../../assets/svg/Alert.svg'
import { useHistory } from "react-router-dom";
import {delSubscription} from '../../../api/api.js'

const ModalAlert = (props) => {
  const {vision,setVision,stack,t,title,alertPayBool,alertPayUrl,rerender,setRerender} = props
  const history = useHistory();
  const userdata = JSON.parse(localStorage.getItem("userData"))
  const backToTheHome = () => {
    if(alertPayBool){
      history.push(alertPayUrl)
      setVision(false)
    }else{
      setVision(false)
    }
  }

  const deleteCurrentSubscribtion = () => {
    delSubscription(userdata.email,userdata.session,rerender,setRerender)
    setVision(false)
  }

  return(
    <div style={{alignItems:'center',display: vision ? 'flex' :'none',zIndex:2,justifyContent:'center'}} className='template_close_container'>
    <div className="Modal_Body body_mobile_container">
      <div className="body_modal_relative">
        <img src={alert}/>
        <p className="alert_modal_title">{title}</p>
        {stack ?
            <p className="alert_modal_description">{t('cancelDescriptionStack')}</p>
          : null}
        <div className="panel_alert_modal">
          <button style={alertPayBool ? {marginRight:0}
            :{}} onClick={() => backToTheHome()}>{t('comeBack')}</button>
          {alertPayBool? 
            null:<button onClick={() => deleteCurrentSubscribtion()}>{t('cancel')}</button>}
        </div>
        </div>
    </div>
</div>
  )
}

export default ModalAlert
