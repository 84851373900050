import React from 'react'
import alert from '../../../assets/svg/Alert.svg'

const ModalClearTrade = (props) => {
    const {title,vision,setVision,t,ClearTradeView} = props
    return(
        <div style={{
            alignItems:'center',
            display: vision ? 'flex' :'none',
            zIndex:2,
            justifyContent:'center',
            }} className='template_close_container'>
        <div style={{background:"rgba(33, 37, 48, 1)"}} className="Modal_Body body_mobile_container">
          <div className="body_modal_relative">
            <img src={alert}/>
            <p style={{color:"#97BDFF"}} className="alert_modal_title">{title}</p>
            <div className="panel_alert_modal">
              <button onClick={() => setVision(false)}>{t('clearModalButtFirst')}</button>
              <button onClick={() => ClearTradeView()}>{t('clearModalButtLast')}</button>
            </div>
            </div>
        </div>
    </div>
    )
}

export default ModalClearTrade