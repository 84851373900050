import React,{useState} from 'react'
import choose from '../../assets/imgredesign/faq_choose.svg'

const FaqItem = (props) => {
    const {title,description} = props
    const [active,setActive] = useState(false)
    return(
        <div className="faq-item">
            <div className='faq_container'>
                    <div onClick={() => setActive(!active)} className="faq-item-head">
                    <p>{title}</p>
                    <p><img style={{transition: '0.5s',transform: !active ? "rotate(0deg)" : "rotate(180deg)",}} src={choose}/></p>
                </div>
                <div style={{
                    display: active ? "block":"none"
                }} className="faq-item-body">
                    <p dangerouslySetInnerHTML={{ __html: description }}/>
                </div>
            </div>
    </div>
    )
}

export default FaqItem