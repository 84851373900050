import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Logo from "../assets/imgredesign/logo.svg";
import { toggleLogin } from "../redux/actions";
import { toast, ToastContainer } from "react-toastify";
import { sendRegister, FinishVerification } from '../api/api.js'
import storage from '../storage/storage.js'
import { useTranslation } from 'react-i18next'
import { NavLink, useHistory } from "react-router-dom";
import { Vertify } from '@alex_xu/react-slider-vertify';
//import SlideCaptcha from 'react-slide-captcha';


const Registration = (props) => {
  const { amplitude, amplitudeId, currentLinkReff } = props
  const history = useHistory();
  const [form, setForm] = useState({ emailRegister: '', password: '', name: "", repassword: "", code: '' })
  const [phone, setPhone] = useState()
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false)
  const [regText, setRegText] = useState(t('reg'))
  const [validate, setValidate] = useState([]);
  const changeHandler = event => { setForm({ ...form, [event.target.name]: event.target.value }) }

  const notifySuccess = (data) => toast.success(data);
  const notifyError = (data) => toast.error(data);

  const [seconds, setSeconds] = useState(0);
  const [nextButton, toggleNextButton] = useState('')

  const urlParams = new URLSearchParams(window.location.search);
  const cid = urlParams.get('cid');

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
      toggleNextButton(true);
    } else {
      toggleNextButton(false);
    }
  });

  const registrationHandler = async () => {
    try {

      setLoading(true)
      setRegText(t('wait'))
      //Validate For REGISTRATION
      if (!form.name) { notifyError(t('incorrectName')) }
      if (!form.email) { notifyError(t('incorrectEmail')) }
      // if(!phone) { notifyError("You have not entered Phone") }
      //if (/[*+\/-]/.test(form.email)) {notifyError(t('incorrectEmailNoArifmetic'))}
      if (form.name.length !== 0 && form.email.length !== 0) {
        setSeconds(60)
        sendRegister(cid, form, phone, setValidate, notifyError, notifySuccess, "user", currentLinkReff)
        setLoading(false)
        setRegText(t('reg'))

        /*    if(data){
                notifySuccess(t('regReady'))
                setLoading(false)
                setRegText(t('reg'))
                setTimeout(()=>{
                    props.toggleLogin()
                }, 1500)
            } */
      }
      else {
        setLoading(false)
        setRegText(t('reg'))
      }
    } catch (e) {
      notifyError("Something wrong with server")
      setLoading(false)
      setRegText(t('reg'))
    }
  }

  const Verification = () => {
    if (!form.password) { notifyError(t('incorrectPass')) }
    if (form.password && !form.repassword) { notifyError(t('incorrectRepeatPass')) }
    if (form.password && form.repassword && form.password !== form.repassword) { notifyError(t('noRepeat')) }
    if (! /^[a-zA-Z0-9]+$/.test(form.password)) { notifyError(t('noArifmetic')) }
    if (form.repassword && form.password && form.repassword === form.password && /^[a-zA-Z0-9]+$/.test(form.password)) {
      FinishVerification(cid, form.email, form.code, form.password, props.toggleLogin, notifySuccess, notifyError, amplitude, amplitudeId, history)
    }
  }

  return (
    <div className="login-wrap">
      {/*<a href="https://cryppush.com/"><img src={Logo} alt="Logotype" /></a>*/}
      <div className='form-login'>
        <h2 style={{ color: 'rgb(38, 55, 85)' }}>{t('letsHover')}</h2>
        <p>{t('regHover')}</p>
        {storage.form_register.map((el, key) =>
          el.type === 'phone' ?
            <div key={key} style={{ width: '100%', position: "relative" }}>
              <p style={{
                textAlign: 'inherit',
                marginTop: 2,
                marginBottom: 8,
                fontSize: 12,
                color: 'rgba(88, 97, 124, 1)'
              }}>{t('phoneReg')}</p>
              <PhoneInput
                placeholder={t('phoneRegPlace')}
                value={phone}
                onChange={setPhone} />
            </div>
            :
            <div key={key} style={{ width: '100%', position: "relative" }}>
              <p style={{
                textAlign: 'inherit',
                marginTop: 2,
                marginBottom: 8,
                fontSize: 12,
                color: 'rgba(88, 97, 124, 1)'
              }}>{t(el.holder)}</p>
              <div style={{ marginBottom: 10 }} className="input">
                <i className={el.ico}></i>
                <input type={el.type} placeholder={t(el.holder)} name={el.name} value={eval(el.value)} onChange={(event) => changeHandler(event)} />
              </div>
              <button style={{
                display: key !== 1 ? 'none' : "block",
                pointerEvents: nextButton ? 'none' : '',
                background: nextButton ? 'rgba(149, 167, 183, 0.3)' : 'rgba(47, 128, 237, 0.3)'
              }}
                className="button_send_email" onClick={() => registrationHandler()}>
                {nextButton ? `${seconds} ${t('formSec')}` : t('formCode')}</button>
            </div>
        )}

        <div style={{ display: 'flex', width: '100%' }}>
          {storage.form_register_end.map((el, key) =>
            <div key={key} style={{ width: '100%', marginRight: key === 0 ? 20 : 0 }}>
              <p style={{
                textAlign: 'inherit',
                marginTop: 2,
                marginBottom: 8,
                fontSize: 12,
                color: 'rgba(88, 97, 124, 1)'
              }}>{t(el.holder)}</p>
              <div style={{ marginBottom: 0 }} className="input" key={el.name}>
                <i className={el.ico} />
                <input type={el.type} placeholder={t(el.holder)} name={el.name} value={eval(el.value)} onChange={(event) => changeHandler(event)} />
              </div>
            </div>
          )}
        </div>

        <div style={{ marginTop: 20 }}>
          <p style={{ maxWidth: 350, display: 'revert', textAlign: 'inherit' }} className='accept_term_container'>{t('newStartTerms')}
            <a style={{ marginLeft: 4, color: 'rgb(39, 168, 226)' }} target="_blank" href={`https://cryppush.com/user_agreements_${i18n.language}.pdf`}>{t('accEnd')}</a></p>
        </div>
        <button className="login-btn" onClick={(event) => Verification()} disabled={loading}>
          {loading ? <div className="fa-1x"><i className="fas fa-spinner fa-spin"></i></div> : ""}
          {t('registerNow')}
        </button>
        <p>{t('haveAcc')}? <span style={{ color: 'rgb(39, 168, 226)' }} onClick={() => props.toggleLogin()}>{t('signIn')}</span></p>
        <ToastContainer />
      </div>
    </div>
  );
}
const mapDispatchToProps = {
  //createPost, showAlert
  toggleLogin
}

const mapStateToProps = state => ({
  //balance: state.binary.balance,
})

export default connect(mapStateToProps, mapDispatchToProps)(Registration)
