import React,{useState} from 'react'
import binance from "../../../assets/img/huobi.png";
import { toast } from 'react-toastify';

const BinanceForms = (props) => {
  const {t,sendKey,setRerender,rerender,setStep} = props
  const [off, setOff] = useState(false)
  const [saveText, setSaveText] = useState(t("save"))
  const [step,setStepVeriff] = useState(0)
  //This hooks used for base handling values of inputs
  const [apiKey, setApiKey] = useState('')
  const [secret, setSecret] = useState('')
  const [password, setPassword] = useState('')
  const notifyError = (data) => toast.error(data);

  // Handlers Functions for inputs
  const apiKeyHandler = (event) => { setApiKey(event.target.value) }
  const passwordHandler = (event) => { setPassword(event.target.value) }
  const secretHandler = (event) => { setSecret(event.target.value) }

  const userdata = JSON.parse(localStorage.getItem("userData"))

  //Validate for Binance Keys
  const clickhandlerBinance = () => {
      setOff(true)
      setSaveText(t("validation"))
      if(apiKey.length === 0 ){ notifyError(t("huobiErrorKey")) }
      if(secret.length === 0 ){ notifyError(t("huobiErrorSecret")) }
      if(apiKey && secret){setStepVeriff(1)}
      setOff(false)
      setSaveText(t("save"))
  }


  const senderHandler = () => {
    if(password.length !== 0){
    sendKey(userdata.email, apiKey, secret, "HUOBI",password,userdata.session)
    .then(data => {
      if (data.data.data !== null) {
        setRerender(!rerender)
        setStep('result')
        setStepVeriff(0)
      }else {
        notifyError(data.data.error.description)
      }
    })
  }else{notifyError(t("typePass"))}
    setOff(false)
    setSaveText(t("save"))
  }

  return(
    <>
    {step === 0 ?
    <div className="binance-connect">
    <img style={{width:42}} src={binance} alt="Huobi coin logo"/>
        <div className="binance-connect-text">
            <div className="binance-api-data">
                <div className="input">
                    <i className="far fa-user"></i>
                    <input type="text" className="" placeholder="Huobi Api Key" autoComplete={0} value={apiKey} onChange={(event)=>apiKeyHandler(event)}/>
                </div>
                <div className="input">
                    <i className="far fa-key"></i>
                    <input type="text" className="" placeholder="Huobi Secret Key" autoComplete={0} value={secret} onChange={(event)=>secretHandler(event)}/>
                </div>
            </div>
        </div>
        <div className="binance-connect-button">
            <button className="binance-connect-save-settings" onClick={()=>clickhandlerBinance()} disabled={off}>
                {off?<div className="fa-1x"><i className="fas fa-spinner fa-spin"></i></div>:<i className="fas fa-cog"></i>}
                {saveText}
            </button>
        </div>
        </div>
        :
        <div className="binance-connect">
        <img style={{width:42}} src={binance} alt="Huobi coin logo"/>
            <div className="binance-connect-text">
                <div className="binance-api-data">
                    <div className="input">
                        <i className="far fa-lock-alt"></i>
                        <input type="password" placeholder="Type your account password" autoComplete={0} value={password} onChange={(event)=>passwordHandler(event)}/>
                    </div>
                </div>
            </div>
            <div className="binance-connect-button">
                <button className="binance-connect-save-settings" onClick={()=>senderHandler()} disabled={off}>
                    {off?<div className="fa-1x"><i className="fas fa-spinner fa-spin"></i></div>:<i className="fas fa-cog"></i>}
                    {saveText}
                </button>
            </div>
            </div>}
        </>
  )
}

export default BinanceForms
